//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  GetMarketDataSource,
} from "@/lib/indicators/indicators-common-functions";

export const moneyFlowIndex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Money flow index",
    metainfo: {
      _metainfoVersion: 51,
      id: "money-flow-index@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Money flow index",
      shortDescription: "[SF] MFI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "middleBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "mfiPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "overboughtPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oversoldPlot",
          type: StudyPlotType.Line,
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "overboughtPlot",
          objBId: "oversoldPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {},
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0.1)",
            visible: true,
            transparency: 10,
          },
        },
        styles: {
          middleBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          mfiPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          overboughtPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
          oversoldPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          sourceInput: "Volume",
          lengthInput: 100,
          overboughtInput: 80,
          middleBandInput: 50,
          oversoldInput: 20,
        },
      },
      styles: {
        middleBandPlot: {
          title: "Middle band",
          histogramBase: 0,
        },
        mfiPlot: {
          title: "MFI",
          histogramBase: 0,
        },
        overboughtPlot: {
          title: "Overbought",
          histogramBase: 0,
        },
        oversoldPlot: {
          title: "Oversold",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: ["Volume", "CVD", "Open interest"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "overboughtInput",
          name: "Overbought",
          defval: 80,
          min: 0,
          max: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "middleBandInput",
          name: "Middle band",
          defval: 50,
          min: 0,
          max: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "oversoldInput",
          name: "Oversold",
          defval: 20,
          min: 0,
          max: 100,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);

        const volumeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, period);

        const openInterestSymbol: string = `${baseSymbol}#SF_OPEN_INTEREST`;
        this._context.new_sym(openInterestSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [
          sourceInput,
          lengthInput,
          overboughtInput,
          middleBandInput,
          oversoldInput,
        ]: [string, number, number, number, number] = Array.from(
          { length: 5 },
          (_, i) => this._input(i + 1),
        );

        // Market data parsing
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const priceTime: any = this._context.new_var(this._context.symbol.time);
        const priceArray: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        const volumeArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(0);
        const price: number = priceArray.adopt(priceTime, mainSymbolTime, 0);
        const priceSeries: any = this._context.new_var(price);

        const dataSource: number = GetMarketDataSource(
          PineJS,
          sourceInput,
          this._context,
        );

        // Calculations
        const change: number = PineJS.Std.change(priceSeries) * dataSource;
        const posChange: number = change <= 0 ? 0 : change;
        const posChangeSeries: any = this._context.new_var(posChange);
        const upper: number = PineJS.Std.sum(
          posChangeSeries,
          lengthInput,
          this._context,
        );
        const negChange: number = change >= 0 ? 0 : -change;
        const negChangeSeries: any = this._context.new_var(negChange);
        const lower: number = PineJS.Std.sum(
          negChangeSeries,
          lengthInput,
          this._context,
        );
        const mfi: number = 100 - 100 / (1 + upper / lower);

        // Returns
        return [middleBandInput, mfi, overboughtInput, oversoldInput];
      };
    },
  };
};
