import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ESFTableType } from "@/domain/enums/dashboard.enum.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import {
  PatternPredictFlat,
  usePatternPredict,
} from "@/hooks/usePatternPredict";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config";
import { MarketRatio } from "../shared/marketRatio";

const columnHelper = createColumnHelper<PatternPredictFlat>();

const PATTERN_PREDICT_MAP: Record<string, string> = {
  TWO_CROWS_DOWN: "Two Crows",
  THREE_BLACK_CROWS_UP: "Three Black Crows",
  THREE_BLACK_CROWS_DOWN: "Three Black Crows",
  THREE_INSIDE_UP: "Three Inside Up",
  THREE_INSIDE_DOWN: "Three Inside Down",
  THREE_LINE_STRIKE_UP: "Three Line Strike",
  THREE_LINE_STRIKE_DOWN: "Three Line Strike",
  THREE_OUTSIDE_UP: "Three Outside Up",
  THREE_OUTSIDE_DOWN: "Three Outside Down",
  THREE_STARS_IN_SOUTH_UP: "Three Stars In The South",
  THREE_WHITE_SOLDIERS_UP: "Three White Soldiers",
  ADVANCE_BLOCK_DOWN: "Advance Block",
  BELT_HOLD_UP: "Belt Hold",
  BELT_HOLD_DOWN: "Belt Hold",
  BREAK_AWAY_UP: "Breakaway",
  BREAK_AWAY_DOWN: "Breakaway",
  CLOSING_MARUBOZU_UP: "Closing Marubozu",
  CLOSING_MARUBOZU_DOWN: "Closing Marubozu",
  CONCEAL_BABY_SWALL_UP: "Conceal Baby Swallow",
  COUNTER_ATTACK_UP: "Counter Attack",
  COUNTER_ATTACK_DOWN: "Counter Attack",
  DOJI_UP: "Doji",
  DOJI_STAR_UP: "Doji Star",
  DOJI_STAR_DOWN: "Doji Star",
  DRAGON_FLY_DOJI_UP: "Dragonfly Doji",
  ENGULFING_UP: "Engulfing",
  GAP_SIDE_SIDE_WHITE_UP: "Gap Side-side White",
  GAP_SIDE_SIDE_WHITE_DOWN: "Gap Side-side White",
  GRAVESTONE_DOJI_UP: "Gravestone Doji",
  HAMMER_UP: "Hammer",
  HANGING_MAN_DOWN: "Hanging Man",
  HARAMI_UP: "Harami",
  HARAMI_DOWN: "Harami",
  HARAMI_CROSS_UP: "Harami Cross",
  HARAMI_CROSS_DOWN: "Harami Cross",
  HIGH_WAVE_UP: "High Wave",
  HIGH_WAVE_DOWN: "High Wave",
  HIKKAKE_UP: "Hikkake",
  HIKKAKE_DOWN: "Hikkake",
  HIKKAKE_MOD_UP: "Hikkake Mod",
  HIKKAKE_MOD_DOWN: "Hikkake Mod",
  HOMING_PIGEON_UP: "Homing Pigeon",
  IDENTICAL_THREE_CROWS_DOWN: "Identical Three Crows",
  INNECK_DOWN: "In-Neck",
  INVERTED_HAMMER_UP: "Inverted Hammer",
  KICKING_UP: "Kicking",
  KICKING_DOWN: "Kicking",
  KICKING_BY_LENGTH_UP: "Kicking By Length",
  KICKING_BY_LENGTH_DOWN: "Kicking By Length",
  LADDER_BOTTOM_UP: "Ladder Bottom",
  LONG_LEGGED_DOJI_UP: "Long Legged Doji",
  LONG_LINE_UP: "Long Line",
  LONG_LINE_DOWN: "Long Line",
  MARUBOZU_UP: "Marubozu",
  MARUBOZU_DOWN: "Marubozu",
  MATCHING_LOW_UP: "Matching Low",
  ONNECK_UP: "On-Neck",
  PIERCING_UP: "Piercing",
  RICK_SHAW_MAN_UP: "Rickshaw Man",
  RISE_FALL_THREE_METHODS_UP: "Rising/Falling Three Methods",
  RISE_FALL_THREE_METHODS_DOWN: "Rising/Falling Three Methods",
  SEPARATING_LINES_UP: "Separating Lines",
  SEPARATING_LINES_DOWN: "Separating Lines",
  SHOOTING_STAR_UP: "Shooting Star",
  SHOOTING_STAR_DOWN: "Shooting Star",
  SHORT_LINE_UP: "Short Line",
  SHORT_LINE_DOWN: "Short Line",
  SPINNING_TOP_UP: "Spinning Top",
  STALLS_UP: "Stalls",
  STALLS_DOWN: "Stalls",
  SPINNING_TOP_DOWN: "Spinning Top",
  STALLED_PATTERN_DOWN: "Stalled Pattern",
  STICK_SANDWICH_UP: "Stick Sandwich",
  TAKURI_UP: "Takuri",
  TASUKI_GAP_UP: "Tasuki Gap",
  TASUKI_GAP_DOWN: "Tasuki Gap",
  THRUSTING_DOWN: "Thrusting",
  TRI_STAR_UP: "Tri-star",
  TRI_STAR_DOWN: "Tri-star",
  UNIQUE_THREE_RIVER_UP: "Unique Three River",
  UPSIDE_GAP_TWO_CROWS_DOWN: "Upside Gap Two Crows",
  X_SIDE_GAP_THREE_METHODS_UP: "X-side Gap Three Methods",
  X_SIDE_GAP_THREE_METHODS_DOWN: "X-side Gap Three Methods",
  ABANDONED_BABY_UP: "Abandoned Baby",
  ABANDONED_BABY_DOWN: "Abandoned Baby",
  DARK_CLOUD_COVER_DOWN: "Dark Cloud Cover",
  EVENING_DOJI_STAR_DOWN: "Evening Doji Star",
  EVENING_STAR_DOWN: "Evening Star",
  MAT_HOLD_UP: "Mat Hold",
  MORNING_DOJI_STAR_UP: "Morning Doji Star",
  MORNING_STAR_UP: "Morning Star",
};

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("size", {
    header: "Size",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("name", {
    header: "Name",
    cell: (row) => {
      const value = row.getValue();
      const bgColor = value.includes("DOWN") ? "bg-red-800" : "bg-green-800";
      return (
        <div
          className={cn(
            "font-semibold h-full flex items-center justify-end px-2",
            bgColor,
          )}
        >
          {PATTERN_PREDICT_MAP[value] || value}
        </div>
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("totalPatterns", {
    header: "Patterns",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("UD", {
    header: "UD",
    cell: (info) => {
      const ud = info.row.original.UD;
      const dd = info.row.original.DD;
      const ud_data = {
        min: ud.min,
        max: ud.max,
        value: ud.upperWhisker,
      };
      const dd_data = {
        min: dd.min,
        max: dd.max,
        value: -dd.upperWhisker,
      };
      return (
        <div>
          <MarketRatio data={ud_data} height={10} />
          <MarketRatio data={dd_data} height={10} />
        </div>
      );
    },
    size: 120,
    minSize: 120,
  }),
];

const oldValues: IRow[] = [];
const highlightTimestamps: number[] = [];

function rowClassCb(
  row: ITechDesk,
  idx: number,
  table: Table<IRow>,
  sortingState: SortingState,
) {
  if (idx > 4) {
    return "";
  }

  const meta = table.options.meta as { tableType: ESFTableType };
  const isHighlightable =
    meta?.tableType.includes(ESFTableType.unpinned) &&
    !meta?.tableType.includes(ESFTableType.chart);

  if (!isHighlightable) {
    return "";
  }

  const oldRow = oldValues[idx];
  const isPositionChanged = oldRow?.symbol !== row.symbol;
  const now = Date.now();
  const isSortedDesc = sortingState.some((sort) => sort.desc);
  const color = isSortedDesc ? "bg-green-600/20" : "bg-red-600/20";

  if (isPositionChanged) {
    oldValues[idx] = row;
    highlightTimestamps[idx] = now;
    return cn(color, "animate-pulse");
  } else if (
    highlightTimestamps[idx] &&
    now - highlightTimestamps[idx] < 1000
  ) {
    return cn(color, "animate-pulse");
  } else {
    return "";
  }
}

export function PatternPredictPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const interval = BINANCE_RESOLUTION_MAP[config.interval];
  const { data: patternPredict, isLoading } = usePatternPredict(interval);

  const preparedData = useMemo(() => {
    if (!patternPredict?.data) {
      return [];
    }
    let data = Object.entries(patternPredict.data).flatMap(
      ([symbol, patterns]) =>
        patterns.map((pattern) => ({
          symbol,
          timestamp: pattern.timestamp,
          size: pattern.size,
          name: pattern.name,
          totalPatterns: pattern.statistic.totalPatterns,
          UD: pattern.statistic.UD,
          DD: pattern.statistic.DD,
        })),
    );

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<PatternPredictFlat>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, patternPredict?.data, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={(...args) => rowClassCb(...args, config.sortingState)}
      />
    </>
  );
}
