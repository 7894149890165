import { SfChartFullFunctional } from "@/components/core/charts/sfChartFullFunctional.tsx";
import { ControlPanel } from "@/components/widgets/controlPanel/controlPanel.tsx";
import { ManipulationMonitorPaneBody } from "@/components/widgets/manipulationMonitorPaneBody.tsx";
import { SpotFuturesSpreadPaneBody } from "@/components/widgets/spotFuturesSpreadPaneBody.tsx";
import { StopKillerPaneBody } from "@/components/widgets/stopKillerPaneBody.tsx";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { memo } from "react";
import { PaneContext } from "./paneContext";
import { VolumeScreenerPaneBody } from "@/components/widgets/volumeScreenerPaneBody.tsx";
import { OiScreenerPaneBody } from "@/components/widgets/oiScreenerPaneBody.tsx";
import { PriceScreenerPaneBody } from "@/components/widgets/priceScreenerPaneBody.tsx";
import { BuySellDeltaScreenerPaneBody } from "@/components/widgets/buySellDeltaScreenerPaneBody.tsx";
import { LiquidationsScannerPaneBody } from "@/components/widgets/liquidationsScannerPaneBody.tsx";
import { PairsScannerPaneBody } from "@/components/widgets/pairsScannerPaneBody.tsx";
import { CascadeLevelsPaneBody } from "@/components/widgets/cascadeLevelsPaneBody.tsx";
import { PredictLiquidationPaneBody } from "@/components/widgets/predictLiquidationPaneBody.tsx";
import { AdtvScreenerPaneBody } from "@/components/widgets/adtvScreenerPaneBody.tsx";
import { ActivityDetectorScreenerPaneBody } from "@/components/widgets/activityDetectorScreenerPaneBody.tsx";
import { CryptoDriftScreenerPaneBody } from "@/components/widgets/cryptoDriftScreenerPaneBody.tsx";
import { MarketRatioScreenerPaneBody } from "@/components/widgets/marketRatioScreenerPaneBody.tsx";
import { SpreadDominationPaneBody } from "@/components/widgets/spreadDominationPaneBody.tsx";
import { StrategiesPaneBody } from "@/components/widgets/strategiesPaneBody.tsx";
import { PowerTradesPaneBody } from "../widgets/powerTradesPaneBody";
import { HTFActivityScreenerPaneBody } from "../widgets/hftActivityPaneBody";
import { ActivityDetectorTdScreenerPaneBody } from "../widgets/activityDetectorTdScreenerPaneBody";
import { TrendAssessmentPaneBody } from "../widgets/trendAssessmentPaneBody";
import { SizeHighlighterPaneBody } from "../widgets/sizeHighlighterPaneBody";
import { SpoofLayerPaneBody } from "../widgets/spoofLayerPaneBody";
import { FormationsPaneBody } from "../widgets/formationsPaneBody";
import { PatternPredictPaneBody } from "../widgets/patternPredictPaneBody";
import { CandlePatternPaneBody } from "../widgets/candlePatternsPaneBody";
import { MarketPowerPaneBody } from "../widgets/marketPowerPaneBody";
import { RetailPowerPaneBody } from "../widgets/retailPowerPaneBody";
import { SmartHiLowPaneBody } from "../widgets/smartHiLowPaneBody";
import { ClassicScreenerPaneBody } from "../widgets/classicScreenerPaneBody";
import { ADFPaneBody } from "../widgets/adfPaneBody";

function getInner(type: EWidgetType) {
  // TODO move to widgets config constant, to keep everything in one place
  switch (type) {
    case EWidgetType.manipulationMonitor:
      return <ManipulationMonitorPaneBody />;
    case EWidgetType.strategies:
      return <StrategiesPaneBody />;
    case EWidgetType.stopKiller:
      return <StopKillerPaneBody />;
    case EWidgetType.spotFuturesSpread:
      return <SpotFuturesSpreadPaneBody />;
    case EWidgetType.chart:
      return <SfChartFullFunctional />;
    case EWidgetType.controlPanel:
      return <ControlPanel />;
    case EWidgetType.deltaScreener:
      return <BuySellDeltaScreenerPaneBody />;
    case EWidgetType.priceScreener:
      return <PriceScreenerPaneBody />;
    case EWidgetType.oiScreener:
      return <OiScreenerPaneBody />;
    case EWidgetType.volumeScreener:
      return <VolumeScreenerPaneBody />;
    case EWidgetType.liquidationsScanner:
      return <LiquidationsScannerPaneBody />;
    case EWidgetType.pairsScanner:
      return <PairsScannerPaneBody />;
    case EWidgetType.predictLiquidation:
      return <PredictLiquidationPaneBody />;
    case EWidgetType.cascadeLevels:
      return <CascadeLevelsPaneBody />;
    case EWidgetType.adtvScreener:
      return <AdtvScreenerPaneBody />;
    case EWidgetType.activityDetectorScreener:
      return <ActivityDetectorScreenerPaneBody />;
    case EWidgetType.cryptoDriftScreener:
      return <CryptoDriftScreenerPaneBody />;
    case EWidgetType.marketRatioScreener:
      return <MarketRatioScreenerPaneBody />;
    case EWidgetType.spreadDominationScreener:
      return <SpreadDominationPaneBody />;
    case EWidgetType.powerTradesScreener:
      return <PowerTradesPaneBody />;
    case EWidgetType.htfActivityScreener:
      return <HTFActivityScreenerPaneBody />;
    case EWidgetType.activityDetectorTdScreener:
      return <ActivityDetectorTdScreenerPaneBody />;
    case EWidgetType.trendAssessmentScreener:
      return <TrendAssessmentPaneBody />;
    case EWidgetType.sizeHighlighter:
      return <SizeHighlighterPaneBody />;
    case EWidgetType.spoofLayer:
      return <SpoofLayerPaneBody />;
    case EWidgetType.formations:
      return <FormationsPaneBody />;
    case EWidgetType.patternPredict:
      return <PatternPredictPaneBody />;
    case EWidgetType.candlePattern:
      return <CandlePatternPaneBody />;
    case EWidgetType.marketPower:
      return <MarketPowerPaneBody />;
    case EWidgetType.retailPower:
      return <RetailPowerPaneBody />;
    case EWidgetType.smartHiLow:
      return <SmartHiLowPaneBody />;
    case EWidgetType.classicSma:
      return <ClassicScreenerPaneBody name={"sma"} />;
    case EWidgetType.classicRsi:
      return <ClassicScreenerPaneBody name={"rsi"} />;
    case EWidgetType.classicAdx:
      return <ClassicScreenerPaneBody name={"adx"} />;
    case EWidgetType.classicPpo:
      return <ClassicScreenerPaneBody name={"ppo"} />;
    case EWidgetType.classicNatr:
      return <ClassicScreenerPaneBody name={"natr"} />;
    case EWidgetType.classicMacd:
      return <ClassicScreenerPaneBody name={"macdNorm"} />;
    case EWidgetType.classicCci:
      return <ClassicScreenerPaneBody name={"cci"} />;
    case EWidgetType.classicCmo:
      return <ClassicScreenerPaneBody name={"cmo"} />;
    case EWidgetType.classicMfi:
      return <ClassicScreenerPaneBody name={"mfi"} />;
    case EWidgetType.classicRoc:
      return <ClassicScreenerPaneBody name={"roc"} />;
    case EWidgetType.classicKco:
      return <ClassicScreenerPaneBody name={"kco"} />;
    case EWidgetType.classicWilliamsPrcR:
      return <ClassicScreenerPaneBody name={"williamsPrcR"} />;
    case EWidgetType.classicBollingerPrc:
      return <ClassicScreenerPaneBody name={"bollingerPrc"} />;
    case EWidgetType.classicStochastic:
      return <ClassicScreenerPaneBody name={"stochastic"} />;
    case EWidgetType.adf:
      return <ADFPaneBody />;
    default:
      return <div>default component</div>;
  }
}

const PaneComponent = ({ nodeId }: { nodeId: string }) => {
  const { node } = useGrid(nodeId);

  return (
    <PaneContext.Provider value={nodeId}>
      {node ? getInner(node.getComponent() as EWidgetType) : "loading"}
    </PaneContext.Provider>
  );
};
PaneComponent.displayName = "Pane";
export const Pane = memo(PaneComponent);
