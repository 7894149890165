import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { X, Plus } from "lucide-react";
import { useGrid } from "@/hooks/useGrid";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useDashboard } from "@/lib/store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { dynamicColumns } from "@/domain/constants";
import {
  EFilterType,
  ENumberComparisonType,
} from "@/domain/enums/dashboard.enum";
import { Label } from "@/components/ui/label";

export function GlobalFilters() {
  const nodeId = usePaneContext();
  const { node } = useGrid(nodeId);
  const { globalFilters, updateGlobalFilter, updateDashboardState } =
    useDashboard();

  const height = node?.getRect()?.height ?? 0;

  const handleFilterChange = (index: number, value: string) => {
    updateGlobalFilter(index, value);
  };

  const clearFilter = (index: number) => {
    updateDashboardState((state) => {
      state.globalFilters.splice(index, 1);
    });
  };

  const handleAddFilter = (key: string, title: string, timeFrame?: string) => {
    updateDashboardState((state) => {
      state.globalFilters.push({
        type: EFilterType.number,
        title: timeFrame ? `${title} ${timeFrame}` : title,
        global: true,
        filterByField: timeFrame ? `${key}.${timeFrame}` : key,
        numberComparisonType: ENumberComparisonType.greater,
        value: "",
      });
    });
  };

  const groupedColumns = Object.entries(dynamicColumns).reduce(
    (acc, [key, col]) => {
      const group = col.group || "Other";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({ key, col });
      return acc;
    },
    {} as Record<
      string,
      {
        key: string;
        col: (typeof dynamicColumns)[keyof typeof dynamicColumns];
      }[]
    >,
  );

  return (
    <Card className="bg-background pt-6" style={{ height: height - 50 }}>
      <CardContent>
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-sm font-medium">Global Filters</h3>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="sm" variant="ghost">
                  <Plus className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Add Filter</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {Object.entries(groupedColumns).map(([group, columns]) => (
                  <DropdownMenuSub key={group}>
                    <DropdownMenuSubTrigger>
                      <span>{group}</span>
                    </DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent>
                        {columns.map(({ key, col }) =>
                          col.availableTimeFrames ? (
                            <DropdownMenuSub key={key}>
                              <DropdownMenuSubTrigger>
                                <span>{col.title}</span>
                              </DropdownMenuSubTrigger>
                              <DropdownMenuPortal>
                                <DropdownMenuSubContent>
                                  {col.availableTimeFrames.map((tf) => (
                                    <DropdownMenuItem
                                      key={`${key}.${tf}`}
                                      onClick={() =>
                                        handleAddFilter(key, col.title, tf)
                                      }
                                    >
                                      {tf}
                                    </DropdownMenuItem>
                                  ))}
                                </DropdownMenuSubContent>
                              </DropdownMenuPortal>
                            </DropdownMenuSub>
                          ) : (
                            <DropdownMenuItem
                              key={key}
                              onClick={() => handleAddFilter(key, col.title)}
                            >
                              {col.title}
                            </DropdownMenuItem>
                          ),
                        )}
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {globalFilters.map((filter, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="w-full">
                  <Label htmlFor={`filter-${index}`}>
                    {filter.title?.toLowerCase()}
                  </Label>
                  <div className="relative">
                    <Input
                      id={`filter-${index}`}
                      type="number"
                      placeholder={`Enter ${filter.title?.toLowerCase()}`}
                      value={filter.value}
                      onChange={(e) =>
                        handleFilterChange(index, e.target.value)
                      }
                      className="pr-8 w-full"
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      className="absolute right-1 top-1/2 -translate-y-1/2"
                      onClick={() => clearFilter(index)}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
