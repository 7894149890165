//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const manipulationMonitor = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Manipulation monitor",
    metainfo: {
      _metainfoVersion: 51,
      id: "manipulation-monitor@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Manipulation monitor",
      shortDescription: "[SFM] Manipulation monitor",

      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "priceManipulationPlotBorder",
          type: StudyPlotType.Line,
        },
        {
          id: "priceManipulationPlotBackground",
          target: "filledAreaId1",
          type: StudyPlotType.Data,
          targetField: "topValue",
        },
        {
          id: "volumeManipulationPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
        {
          id: "topline",
          type: StudyPlotType.Line,
        },
        {
          id: "filterPricePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "filterVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "avgPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "low activity" },
            { name: "medium activity" },
            { name: "high activity" },
            { name: "culmination activity" },
          ],
          valToIndex: {
            100: 0,
            200: 1,
            300: 2,
            400: 3,
          },
        },
      },
      filledAreas: [
        {
          id: "filledAreaId1",
          objAId: "zeroline",
          objBId: "priceManipulationPlotBorder",
          title: "Histogram gradient",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(13, 157, 219, 0.2)" },
              { color: "rgba(60, 166, 75, 0.2)" },
              { color: "rgba(255, 160, 40, 0.2)" },
              { color: "rgba(178, 24, 44, 0.2)" },
            ],
          },
        },
        filledAreasStyle: {
          filledAreaId1: {
            fillType: "gradient",
            topValue: 100,
            bottomValue: 0,
            topColor: "rgb(255, 160, 40)",
            bottomColor: "rgba(255, 160, 40, 0.4)",
            visible: true,
            transparency: 100,
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          priceManipulationPlotBorder: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 160, 40, 0.2)",
            transparency: 0,
          },
          volumeManipulationPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255,0.5)",
            transparency: 0,
          },
          topline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255, 255, 255, 0)",
          },
          filterPricePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(13, 157, 219)",
            transparency: 0,
          },
          filterVolumePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          avgPlot: {
            linestyle: 0,
            visible: false,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          highlightInput: "Price activity",
          avgSourceInput: "Price activity",
          filterInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        priceManipulationPlotBorder: {
          title: "Price manipulation",
          histogramBase: 0,
        },
        priceManipulationPlotBackground: {
          title: "Price manipulation",
          histogramBase: 0,
        },
        volumeManipulationPlot: {
          title: "Manipulation volume",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
        topline: {
          title: "Topline",
          histogramBase: 0,
          joinPoints: false,
          isHidden: true,
          zorder: 0,
        },
        filterPricePlot: {
          title: "Price filter",
          histogramBase: 0,
        },
        filterVolumePlot: {
          title: "Volume filter",
          histogramBase: 0,
        },
        avgPlot: {
          title: "Average day manipulations",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "highlightInput",
          name: "Highlight",
          defval: "Price activity",
          options: ["Price activity", "Volume activity"],
          type: StudyInputType.Text,
        },
        {
          id: "avgSourceInput",
          name: "Avg source",
          defval: "Price activity",
          options: ["Price activity", "Volume activity"],
          type: StudyInputType.Text,
        },
        {
          id: "filterInput",
          name: "Filter",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      const CONSTANTS = {
        SF_MANIPULATION_MONITOR_SUFFIX: "#SF_MANIPULATION_MONITOR",
        FILTER_SYMBOL: "DEFI#SF_MANIPULATION_MONITOR",
        FILTER_THRESHOLD: 6.66,
        ACTIVITY_THRESHOLDS: {
          MIN: 0.5,
          LOW: 5,
          MEDIUM: 50,
          HIGH: 95,
        },
        COLORS: {
          LOW: 100,
          MEDIUM: 200,
          HIGH: 300,
          VERY_HIGH: 400,
        },
      } as const;

      // GetActivityColor - function for getting activity color of Manipulation monitor
      const GetActivityColor = (value: number): number => {
        if (value < CONSTANTS.ACTIVITY_THRESHOLDS.MIN) {
          return NaN;
        }
        if (value <= CONSTANTS.ACTIVITY_THRESHOLDS.LOW) {
          return CONSTANTS.COLORS.LOW;
        }
        if (value <= CONSTANTS.ACTIVITY_THRESHOLDS.MEDIUM) {
          return CONSTANTS.COLORS.MEDIUM;
        }
        if (value <= CONSTANTS.ACTIVITY_THRESHOLDS.HIGH) {
          return CONSTANTS.COLORS.HIGH;
        }
        return CONSTANTS.COLORS.VERY_HIGH;
      };

      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const period: number = PineJS.Std.period(this._context);

        const baseSymbol: string = ParseSymbol(symbol);
        const manipulationMonitorSymbol: string = `${baseSymbol}${CONSTANTS.SF_MANIPULATION_MONITOR_SUFFIX}`;
        this._context.new_sym(manipulationMonitorSymbol, period);

        const filterSymbol: string = `${baseSymbol}${CONSTANTS.FILTER_SYMBOL}`;
        this._context.new_sym(filterSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [highlightInput, avgSourceInput, filterInput]: [
          string,
          string,
          boolean,
        ] = Array.from({ length: 3 }, (_, i) => this._input(i + 1));

        // Getting market data
        this._context.select_sym(1);
        const marketData = {
          time: this._context.new_var(this._context.symbol.time),
          volume: this._context.new_var(PineJS.Std.close(this._context)),
          price: this._context.new_var(PineJS.Std.open(this._context)),
        };
        this._context.select_sym(2);
        const indexData = {
          time: this._context.new_var(this._context.symbol.time),
          price: this._context.new_var(PineJS.Std.open(this._context)),
          volume: this._context.new_var(PineJS.Std.close(this._context)),
        };
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        let activity = {
          volume: marketData.volume.adopt(marketData.time, mainSymbolTime, 0),
          price: marketData.price.adopt(marketData.time, mainSymbolTime, 0),
        };
        const indexActivity = {
          price: indexData.price.adopt(indexData.time, mainSymbolTime, 0),
          volume: indexData.volume.adopt(indexData.time, mainSymbolTime, 0),
        };

        // Filtering noise
        if (filterInput) {
          activity = {
            price:
              activity.price - indexActivity.price < CONSTANTS.FILTER_THRESHOLD
                ? 0
                : activity.price,
            volume:
              activity.volume - indexActivity.volume <
              CONSTANTS.FILTER_THRESHOLD
                ? 0
                : activity.volume,
          };
        }

        // Calculation of average value
        const length: number = 1440 / Number(PineJS.Std.period(this._context));
        const avgMm: number =
          avgSourceInput === "Price activity"
            ? PineJS.Std.sum(
                this._context.new_var(activity.price),
                length,
                this._context,
              ) / length
            : PineJS.Std.sum(
                this._context.new_var(activity.volume),
                length,
                this._context,
              ) / length;

        // Determining the color
        const color: number = GetActivityColor(
          highlightInput === "Price activity"
            ? activity.price
            : activity.volume,
        );

        // Return values
        return [
          0,
          activity.price,
          activity.price,
          activity.volume,
          color,
          100,
          indexActivity.price,
          indexActivity.volume,
          avgMm,
        ];
      };
    },
  };
};
