//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  ChannelBounds,
} from "@/lib/indicators/indicators-common-functions";

export const cumulativeVolumeDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Cumulative volume delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "cumulative-volume-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Cumulative volume delta",
      shortDescription: "[SF] CVD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "cvdOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cvdUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cvdLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CVD" },
            1: { name: "Growing CVD" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CVD" },
            1: { name: "Growing CVD" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CVD" },
            1: { name: "Growing CVD" },
          },
        },
      },
      ohlcPlots: {
        cvdOhlcPlotcandle: {
          title: "CVD plot candle",
        },
      },
      defaults: {
        styles: {
          cvdMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cvdUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cvdLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          cvdOhlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "USD",
          calculationMethodInput: "Cum",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
        },
      },
      styles: {
        cvdMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        cvdUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        cvdLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "calculationMethodInput",
          name: "Calculation method",
          defval: "Cum",
          options: ["Cum", "Sum"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const volumeSymbol: string = `${ParseSymbol(symbol)}${"#SF_VOLUME"}`;
        this._context.new_sym(volumeSymbol, period);
      };

      this.main = function (context, inputCallback) {
        const MINUTES_IN_DAY: number = 1440;
        const RISING_COLOR: number = 1;
        const FALLING_COLOR: number = 0;

        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [
          _symbol,
          measureInput,
          calculationMethodInput,
          maType,
          length,
          channelType,
          metricsBoolInput,
        ]: [string, string, string, string, number, string, boolean] =
          Array.from({ length: 7 }, (_, i) => this._input(i));

        // Get market data
        const GetSymbolData = (symIndex: number): any[] => {
          this._context.select_sym(symIndex);
          return [
            this._context.new_var(this._context.symbol.time),
            ...(symIndex === 1
              ? [
                  this._context.new_var(PineJS.Std.close(this._context)),
                  this._context.new_var(PineJS.Std.volume(this._context)),
                ]
              : symIndex === 2
                ? [this._context.new_var(PineJS.Std.low(this._context))]
                : []),
          ];
        };

        const [closeAndVolumeTimeSeries, closeArray, volumeArray]: any[] =
          GetSymbolData(1);
        const [buyVolumeTimeArray, buyVolumeArray]: any[] = GetSymbolData(2);
        const [mainSymbolTimeSeries]: any[] = GetSymbolData(0);

        // Calculations
        const close: number = closeArray.adopt(
          closeAndVolumeTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        const volume: number = volumeArray.adopt(
          closeAndVolumeTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        const buyVolume: number = buyVolumeArray.adopt(
          buyVolumeTimeArray,
          mainSymbolTimeSeries,
          0,
        );

        const volumeDelta: number =
          (2 * buyVolume - volume) * (measureInput === "USD" ? close : 1);
        const volumeDeltaSeries: any = this._context.new_var(volumeDelta);

        const volumeDeltaLength: number =
          MINUTES_IN_DAY / Number(PineJS.Std.period(this._context));
        const cvdClose: number =
          calculationMethodInput === "Sum"
            ? PineJS.Std.sum(
                volumeDeltaSeries,
                volumeDeltaLength,
                this._context,
              )
            : PineJS.Std.cum(volumeDelta, this._context);

        const cvdCloseSeries: any = this._context.new_var(cvdClose);
        const cvdOpen: number = cvdCloseSeries.get(1);
        const cvdOpenSeries: any = this._context.new_var(cvdOpen);
        const cvdHigh: number = PineJS.Std.max(cvdOpen, cvdClose);
        const cvdHighSeries: any = this._context.new_var(cvdHigh);
        const cvdLow: number = PineJS.Std.min(cvdOpen, cvdClose);
        const cvdLowSeries: any = this._context.new_var(cvdLow);
        const cvdColor: number =
          cvdClose > cvdOpen ? RISING_COLOR : FALLING_COLOR;

        // Metrics calculations
        const [cvdMa, cvdUpperBand, cvdLowerBand]: [number, number, number] =
          metricsBoolInput
            ? ChannelBounds(
                PineJS,
                cvdLowSeries,
                cvdHighSeries,
                cvdCloseSeries,
                channelType,
                maType,
                length,
                this._context,
              )
            : [NaN, NaN, NaN];

        // Return values
        return [
          cvdOpen,
          cvdHigh,
          cvdLow,
          cvdClose,
          cvdColor,
          cvdColor,
          cvdColor,
          cvdMa,
          cvdUpperBand,
          cvdLowerBand,
        ];
      };
    },
  };
};
