import { useQuery } from "@tanstack/react-query";

const PRICE_API_URL =
  "https://api-v3.raydium.io/mint/price?mints=3tNPxTMS1ETgXeLFTjZTbt6KVbMnXU3Qtz9dwWLWtks5";

interface PriceResponse {
  id: string;
  success: boolean;
  data: {
    [key: string]: string;
  };
}

export const usePrice = () => {
  return useQuery<PriceResponse>({
    queryKey: ["price"],
    queryFn: async () => {
      const response = await fetch(PRICE_API_URL);
      if (!response.ok) {
        throw new Error("Failed to fetch price data");
      }
      return response.json();
    },
    refetchInterval: 5000, // Refetch every 5 seconds
  });
};
