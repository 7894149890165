//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const manipulationMonitorHighlighter = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Manipulation monitor highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "manipulation-monitor-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Manipulation monitor highlighter",
      shortDescription: "[SF] MMH",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 0,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "low activity" },
            { name: "medium activity" },
            { name: "high activity" },
            { name: "culmination activity" },
          ],
          valToIndex: {
            100: 0,
            200: 1,
            300: 2,
            400: 3,
          },
        },
      },
      filledAreas: [],
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(0, 165, 207, 0.2)" },
              { color: "rgba(18, 213, 18, 0.2)" },
              { color: "rgba(245, 187, 0, 0.2)" },
              { color: "rgba(186, 45, 11, 0.2)" },
            ],
          },
        },
        filledAreasStyle: {},
        styles: {},
        precision: 2,
        inputs: {
          sourceInput: "Price activity",
          minvalInput: 0,
          filterInput: false,
        },
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Price activity",
          options: ["Price activity", "Volume activity"],
          type: StudyInputType.Text,
        },
        {
          id: "minvalInput",
          name: "Minimal value",
          defval: 0,
          min: 0,
          max: 100,
          type: StudyInputType.Float,
        },
        {
          id: "filterInput",
          name: "Filter",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      const CONSTANTS = {
        SF_MANIPULATION_MONITOR_SUFFIX: "#SF_MANIPULATION_MONITOR",
        FILTER_SYMBOL: "DEFI#SF_MANIPULATION_MONITOR",
        FILTER_THRESHOLD: 6.66,
        ACTIVITY_THRESHOLDS: {
          MIN: 0.5,
          LOW: 5,
          MEDIUM: 50,
          HIGH: 95,
        },
        COLORS: {
          LOW: 100,
          MEDIUM: 200,
          HIGH: 300,
          VERY_HIGH: 400,
        },
      } as const;

      // GetActivityColor - function for getting activity color of Manipulation monitor
      const GetActivityColor = (value: number): number => {
        if (value < CONSTANTS.ACTIVITY_THRESHOLDS.MIN) {
          return NaN;
        }
        if (value <= CONSTANTS.ACTIVITY_THRESHOLDS.LOW) {
          return CONSTANTS.COLORS.LOW;
        }
        if (value <= CONSTANTS.ACTIVITY_THRESHOLDS.MEDIUM) {
          return CONSTANTS.COLORS.MEDIUM;
        }
        if (value <= CONSTANTS.ACTIVITY_THRESHOLDS.HIGH) {
          return CONSTANTS.COLORS.HIGH;
        }
        return CONSTANTS.COLORS.VERY_HIGH;
      };

      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._context.symbol.info.name;
        const period: number = PineJS.Std.period(this._context);

        const baseSymbol: string = ParseSymbol(symbol);
        const manipulationMonitorSymbol: string = `${baseSymbol}${CONSTANTS.SF_MANIPULATION_MONITOR_SUFFIX}`;
        this._context.new_sym(manipulationMonitorSymbol, period);

        const filterSymbol: string = `${baseSymbol}${CONSTANTS.FILTER_SYMBOL}`;
        this._context.new_sym(filterSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, minvalInput, filterInput]: [
          string,
          number,
          boolean,
        ] = Array.from({ length: 3 }, (_, i) => this._input(i));

        // Getting market data
        this._context.select_sym(1);
        const marketData = {
          time: this._context.new_var(this._context.symbol.time),
          volume: this._context.new_var(PineJS.Std.close(this._context)),
          price: this._context.new_var(PineJS.Std.open(this._context)),
        };
        this._context.select_sym(2);
        const indexData = {
          time: this._context.new_var(this._context.symbol.time),
          price: this._context.new_var(PineJS.Std.open(this._context)),
          volume: this._context.new_var(PineJS.Std.close(this._context)),
        };
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        let activity = {
          volume: marketData.volume.adopt(marketData.time, mainSymbolTime, 0),
          price: marketData.price.adopt(marketData.time, mainSymbolTime, 0),
        };
        const indexActivity = {
          price: indexData.price.adopt(indexData.time, mainSymbolTime, 0),
          volume: indexData.volume.adopt(indexData.time, mainSymbolTime, 0),
        };

        // Filtering noise
        if (filterInput) {
          activity = {
            price:
              activity.price - indexActivity.price < CONSTANTS.FILTER_THRESHOLD
                ? 0
                : activity.price,
            volume:
              activity.volume - indexActivity.volume <
              CONSTANTS.FILTER_THRESHOLD
                ? 0
                : activity.volume,
          };
        }

        // Calculations
        const color: number = GetActivityColor(
          sourceInput === "Price activity" ? activity.price : activity.volume,
        );

        // Return
        return [color];
      };
    },
  };
};
