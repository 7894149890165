import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ESFTableType } from "@/domain/enums/dashboard.enum.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import { IADF, useADF } from "@/hooks/useADF";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config";

const columnHelper = createColumnHelper<IADF>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("adf", {
    header: "ADF",
    cell: (row) => {
      const value = row.getValue();
      const crit = row.row.original.crit;
      const bgClass = value < crit ? "bg-[#3CA64B]" : "bg-[#B2182C]";
      return (
        <div className={bgClass + " font-semibold"}>{value.toFixed(2)}</div>
      );
    },
    size: 120,
    minSize: 120,
  }),
];

const oldValues: IRow[] = [];
const highlightTimestamps: number[] = [];

function rowClassCb(
  row: IADF,
  idx: number,
  table: Table<IRow>,
  sortingState: SortingState,
) {
  if (idx > 4) {
    return "";
  }

  const meta = table.options.meta as { tableType: ESFTableType };
  const isHighlightable =
    meta?.tableType.includes(ESFTableType.unpinned) &&
    !meta?.tableType.includes(ESFTableType.chart);

  if (!isHighlightable) {
    return "";
  }

  const oldRow = oldValues[idx];
  const isPositionChanged = oldRow?.symbol !== row.symbol;
  const now = Date.now();
  const isSortedDesc = sortingState.some((sort) => sort.desc);
  const color = isSortedDesc ? "bg-green-600/20" : "bg-red-600/20";

  if (isPositionChanged) {
    oldValues[idx] = row;
    highlightTimestamps[idx] = now;
    return cn(color, "animate-pulse");
  } else if (
    highlightTimestamps[idx] &&
    now - highlightTimestamps[idx] < 1000
  ) {
    return cn(color, "animate-pulse");
  } else {
    return "";
  }
}

export function ADFPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const interval = BINANCE_RESOLUTION_MAP[config.interval] || config.interval;

  const { data: adf, isLoading } = useADF(interval);

  const preparedData = useMemo(() => {
    if (!adf?.data) {
      return [];
    }

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        adf.data = adf.data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IADF>(adf.data, config.filters, config.filterValues);
    }

    return adf.data;
  }, [config, adf, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={(...args) => rowClassCb(...args, config.sortingState)}
      />
    </>
  );
}
