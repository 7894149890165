//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import {
  GetPriceSeries,
  ADFTest,
} from "@/lib/indicators/indicators-common-functions";

export const augmentedDickeyFullerTest = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Augmented Dickey–Fuller test",
    metainfo: {
      _metainfoVersion: 51,
      id: "augmented-dickey-fuller-test@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Augmented Dickey–Fuller test",
      shortDescription: "[SF] ADF test",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "criticalPlot", type: StudyPlotType.Line },
        { id: "tauadfPlot", type: StudyPlotType.Line },
        {
          id: "tauadfColorer",
          type: StudyPlotType.Colorer,
          target: "tauadfPlot",
          palette: "metricPalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Not stationary" },
            1: { name: "Stationary" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          criticalPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
          tauadfPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
          tauadfColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          lookbackInput: 240,
          nlagInput: 0,
          confidenceInput: "90%",
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        criticalPlot: {
          title: "Critical value",
          histogramBase: 0,
        },
        tauadfPlot: {
          title: "Test statistic",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "lookbackInput",
          name: "Lookback",
          defval: 240,
          min: 2,
          type: StudyInputType.Integer,
        },
        {
          id: "nlagInput",
          name: "Maximum lag",
          defval: 0,
          min: 0,
          type: StudyInputType.Integer,
        },
        {
          id: "confidenceInput",
          name: "Confidence level",
          defval: "90%",
          options: ["90%", "95%", "99%"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const [sourceInput, lookbackInput, nlagInput, confidenceInput]: [
          string,
          number,
          number,
          string,
        ] = Array.from({ length: 4 }, (_, i) => this._input(i));

        //Market data parsing
        const sourceSeries: any = GetPriceSeries(
          PineJS,
          sourceInput,
          this._context,
        );

        //Calculations
        const a: number[] = [];
        for (let i: number = 0; i < lookbackInput; i++) {
          a.push(sourceSeries.get(i));
        }
        const [tauADF, crit] = ADFTest(a, nlagInput, confidenceInput);
        const tauColor: number = tauADF > crit ? 0 : 1;

        //Returns
        return [0, crit, tauADF, tauColor, tauColor];
      };
    },
  };
};
