import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export interface IFormationResponse {
  [timeFrame: string]: IFormation[];
}

export interface IFormation {
  startTime: number;
  symbol: string;
  endTime: number;
  a: IFormationPoint;
  b: IFormationPoint;
  c: IFormationPoint;
  d: IFormationPoint;
  e: IFormationPoint;
  back: IFormationPoint;
  end: IFormationPoint;
  extA: IFormationPoint;
  extB: IFormationPoint;
  duration: number;
  type: string;
}

export interface IFormationPoint {
  timestamp: number;
  price: number;
}

export function useFormations() {
  const handler = useCallback(
    (oldData: IFormationResponse, newData: IFormationResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<IFormationResponse, IFormationResponse>(
    SocketTopics.formations,
    handler,
  );
}
