//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const spotPerpetualsFundingRate = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Funding rate",
    metainfo: {
      _metainfoVersion: 51,
      id: "funding-rate@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Funding rate",
      shortDescription: "[SF] Funding",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "fundingRatePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "fundingRateColorer",
          type: StudyPlotType.Colorer,
          target: "fundingRatePlot",
          palette: "fundingRatePalette",
        },
        {
          id: "ohlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "ohlcPlotcandle",
        },
        {
          id: "predictFundingRatePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "spreadPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "defaultValuePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "upperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "lowerBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      ohlcPlots: {
        ohlcPlotcandle: {
          title: "Funding rate candle",
        },
      },
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Background of the default range",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        fundingRatePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(120, 123, 134, 0.05)",
            visible: true,
            transparency: 5,
          },
        },
        palettes: {
          fundingRatePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          fundingRatePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          fundingRateColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          predictFundingRatePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgba(255, 160, 40, 0.5)",
          },
          spreadPlot: {
            linestyle: 0,
            visible: false,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgba(13, 157, 219, 0.5)",
          },
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          defaultValuePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          upperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          lowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
        },
        ohlcPlots: {
          ohlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          fundingRateMaxValueInput: 2.5,
          payoutFrequencyInput: 8,
          defaultValueInput: 0.01,
          upperValueInput: 0.06,
          lowerValueInput: -0.04,
        },
      },
      styles: {
        fundingRatePlot: {
          title: "Funding rate",
          histogramBase: 0,
        },
        predictFundingRatePlot: {
          title: "Predict funding rate",
          histogramBase: 0,
        },

        spreadPlot: {
          title: "Spot perp spread",
          histogramBase: 0,
        },
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        defaultValuePlot: {
          title: "Default value",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
        backgroundColorer: {
          title: "Background highlighter",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "fundingRateMaxValueInput",
          name: "Max funding rate value",
          defval: 2.5,
          min: 0,
          type: StudyInputType.Float,
          group: "Main settings",
        },
        {
          id: "payoutFrequencyInput",
          name: "Payout frequency",
          defval: 8,
          min: 1,
          max: 24,
          type: StudyInputType.Integer,
          group: "Main settings",
        },
        {
          id: "defaultValueInput",
          name: "Default funding rate value",
          defval: 0.01,
          type: StudyInputType.Float,
          group: "Additional settings",
        },
        {
          id: "upperValueInput",
          name: "Upper band value",
          defval: 0.06,
          type: StudyInputType.Float,
          group: "Additional settings",
        },
        {
          id: "lowerValueInput",
          name: "Lower band value",
          defval: -0.04,
          type: StudyInputType.Float,
          group: "Additional settings",
        },
      ],
    },

    constructor: function () {
      const PAYOUT_FREQUENCY_DIVIDER: number = 8;
      const MINUTES_IN_DAY: number = 480;
      const PI_ADJUSTMENT_VALUE: number = 0.01;
      const PI_CLAMP_MIN: number = -0.05;
      const PI_CLAMP_MAX: number = 0.05;
      const FUNDING_RATE_MULTIPLIER: number = 100;

      // Clamp function
      const Clamp = (value: number, min: number, max: number): number =>
        Math.min(Math.max(value, min), max);

      const Iwma = (source: number, length: number, context: any): number => {
        const sourceSeries: any = context.new_var(source);
        const weightedSum: number = Array.from(
          { length: length - 1 },
          (_, i) => sourceSeries.get(length - 1) * (i + 1),
        ).reduce((sum, val) => sum + val, 0);

        return weightedSum / ((length * (length + 1)) / 2);
      };

      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const symbols: string[] = [
          `${baseSymbol}#FUNDINGRATE`,
          `${baseSymbol}#SF_SPREAD`,
        ];

        symbols.forEach((sym) => {
          this._context.new_sym(sym, period);
        });
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [
          ,
          fundingRateMaxValue,
          payoutFrequencyInput,
          defaultValue,
          upperValue,
          lowerValue,
        ]: number[] = Array.from({ length: 6 }, (_, i) => this._input(i));

        // Get market data
        const marketData: any = this.GetMarketData();

        // Calculate funding rate
        const fundingRate: number = this.CalculateFundingRate(marketData);

        // Calculate predicted rate
        const predictedRate: number = this.CalculatePredictedRate(
          marketData,
          fundingRateMaxValue,
          payoutFrequencyInput,
        );

        // Determine colors
        const colors: any = this.DetermineColors(
          fundingRate,
          upperValue,
          lowerValue,
        );

        // Return output
        return this.FormatOutput(
          fundingRate,
          predictedRate,
          marketData.spotFuturesSpread,
          defaultValue,
          upperValue,
          lowerValue,
          colors,
        );
      };

      // Helper methods
      this.GetMarketData = function (): any {
        this._context.select_sym(1);
        const fundingData: any = {
          time: this._context.new_var(this._context.symbol.time),
          rate: this._context.new_var(PineJS.Std.open(this._context)),
        };

        this._context.select_sym(2);
        const spreadData: any = {
          time: this._context.new_var(this._context.symbol.time),
          value: this._context.new_var(PineJS.Std.open(this._context)),
        };

        this._context.select_sym(0);
        const mainTime: any = this._context.new_var(this._context.symbol.time);

        return { fundingData, spreadData, mainTime };
      };

      this.CalculateFundingRate = function (marketData: any): number {
        const { fundingData, mainTime } = marketData;
        return (
          fundingData.rate.adopt(fundingData.time, mainTime, 0) *
          FUNDING_RATE_MULTIPLIER
        );
      };

      this.CalculatePredictedRate = function (
        marketData: any,
        maxValue: number,
        payoutFrequency: number,
      ): number {
        const normalizedFrequency: number =
          payoutFrequency / PAYOUT_FREQUENCY_DIVIDER;
        const length: number =
          (MINUTES_IN_DAY / Number(PineJS.Std.period(this._context))) *
          normalizedFrequency;

        const piAvg: number = this.CalculatePiAverage(marketData, length);
        const baseRate: number =
          piAvg +
          Clamp(PI_ADJUSTMENT_VALUE - piAvg, PI_CLAMP_MIN, PI_CLAMP_MAX);

        return Clamp(baseRate, -maxValue, maxValue) * normalizedFrequency;
      };

      this.DetermineColors = function (
        fundingRate: number,
        upperValue: number,
        lowerValue: number,
      ): any {
        return {
          fundingColor: Number(fundingRate > 0),
          background:
            fundingRate > upperValue ? 1 : fundingRate < lowerValue ? 0 : NaN,
        };
      };

      this.CalculatePiAverage = function (
        marketData: any,
        length: number,
      ): number {
        const { spreadData, mainTime } = marketData;
        const spotFuturesSpread: number = -spreadData.value.adopt(
          spreadData.time,
          mainTime,
          0,
        );

        const spreadSeries: any = this._context.new_var(spotFuturesSpread);
        const piOpen: number = spreadSeries.get(1);
        const piClose: number = spotFuturesSpread;

        const piOpenAvg: number = Iwma(piOpen, length, this._context);
        const piCloseAvg: number = Iwma(piClose, length, this._context);

        return (piOpenAvg + piCloseAvg) / 2;
      };

      this.FormatOutput = function (
        fundingRate: number,
        predictedRate: number,
        spotFuturesSpread: number,
        defaultValue: number,
        upperValue: number,
        lowerValue: number,
        colors: any,
      ): number[] {
        const fundingRateOpen: number = this._context
          .new_var(fundingRate)
          .get(1);
        const fundingRateHigh: number = Math.max(fundingRateOpen, fundingRate);
        const fundingRateLow: number = Math.min(fundingRateOpen, fundingRate);
        const fundingRateOhlcColor: number = Number(
          fundingRate >= fundingRateOpen,
        );

        return [
          fundingRate,
          colors.fundingColor,
          fundingRateOpen,
          fundingRateHigh,
          fundingRateOpen,
          fundingRate,
          fundingRateOhlcColor,
          fundingRateOhlcColor,
          fundingRateOhlcColor,
          predictedRate,
          spotFuturesSpread,
          0,
          defaultValue,
          upperValue,
          lowerValue,
          colors.background,
        ];
      };
    },
  };
};
