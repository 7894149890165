import { auth } from "@/lib/firebase.ts";
import { useUserStore } from "@/lib/stores/userStore";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  User,
} from "@firebase/auth";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "./useUser";

export function useAuth() {
  //  TODO rewrite to zustand store and subscribe to user from firestrore
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const provider = new GoogleAuthProvider();
  const login = () =>
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("remove", token);
        if (user) {
          setUser(user);
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  const logout = () => {
    auth
      .signOut()
      .then(() => {
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const { data: user } = useQuery<User>({
    queryKey: ["user"],
    enabled: false,
  });

  const { setUser: setUserStore } = useUserStore();
  const { user: sfUser } = useUser(user?.uid);

  const setUser = useCallback(
    (user: User) => {
      queryClient.setQueryData<User>(["user"], user);
      setUserStore(sfUser);
    },
    [queryClient, setUserStore],
  );

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsLoading(false);
      if (user && user.email) {
        // if (
        //   !allowedEmails.includes(user.email.toLowerCase()) &&
        //   !user.email.toLowerCase().includes("@spreadfighter.com")
        // ) {
        //   window.location.href = "https://www.spreadfighter.com";
        // }
        setUser(user);
      }
    });
  }, [setUser]);
  return { user, setUser, login, logout, isLoading };
}

const allowedEmails = [
  "ivan.skiridomov@gmail.com",
  "forzomilia@gmail.com",
  "noflacky@gmail.com",
  "mmavsys@gmail.com",
  "e.kuznetski@gmail.com",
  "nikita.semoff@gmail.com",
  "djglebbass@gmail.com", // viking
  "7641109@gmail.com", // viking
  "ramizkt@gmail.com", // viking
  "vkamensky@gmail.com", // viking
  "2944000@gmail.com", // ERV
  "haperonego@gmail.com", // ERV
  "alexander.m.orlov@gmail.com", //Саша копирайт
  "intergross@gmail.com", // Дмитрий
  "aeaeae.tk@gmail.com", //Sasha E дизайн шрифтов
  "ilyazhukov216@gmail.com",
  "kuzbas1348@gmail.com",
  "burilom7@gmail.com",
  "alxobogdanov@gmail.com",
  "alexander.m.orlov@gmail.com",
  "gb.trading.coins@gmail.com",
  "salez2014@gmail.com",
  "roman_dmitrijev@hotmail.com",
  "iskystvo.g@gmail.com",
  "craven1986@gmail.com",
  "marketing.beu@gmail.com",
  "sssprogmer@gmail.com",
  "alenazhuk176@gmail.com",
  "annastulen11@gmail.com",
  "dmitry.toloknov@gmail.com",
  "shestakov.nikita.s@gmail.com",
  "julianichka@gmail.com",
  "letchikartem@gmail.com",
  "victor.dzer@gmail.com",
  "vasyacullin@gmail.com",
  "betep333@hotmail.com",
  "stranger82@gmail.com",
  "georgechickart7@gmail.com",
  "ilyatereshkin@gmail.com",
  "g.a.polezhaev@gmail.com",
  "ahmadsy002@gmail.com",
  "mariymy1975@gmail.com",
  "korbachevaf@gmail.com",
  "tema4kat1@gmail.com",
  "zarzahar36@gmail.com",
  "maksymchursin.mc@gmail.com",
  "inskricion@gmail.com",
  "lev.dodalin@gmail.com",
  "igorpro4545@gmail.com",
  "drinkincola@gmail.com",
  "lex@spreadfighter.com",
  "otdel13@gmail.com",
  "macxone1990@gmail.com",
  "n1nj4z33@gmail.com",
];
