//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  ChannelBounds,
} from "@/lib/indicators/indicators-common-functions";

export const netOpenInterest = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Net open interest",
    metainfo: {
      _metainfoVersion: 51,
      id: "net-open-interest@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Net open interest",
      shortDescription: "[SF] Net OI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "netOiOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "netOiUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "netOiLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling net OI" },
            1: { name: "Growing net OI" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling net OI" },
            1: { name: "Growing net OI" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling net OI" },
            1: { name: "Growing net OI" },
          },
        },
      },
      ohlcPlots: {
        netOiOhlcPlotcandle: {
          title: "Net OI plot candle",
        },
      },
      defaults: {
        styles: {
          netOiMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          netOiUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          netOiLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          netOiOhlcPlotcandle: {
            borderColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "rgb(149, 152, 161)",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          displayModeInput: "Taker net long",
          calculationMethodInput: "Cum",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
        },
      },
      styles: {
        netOiMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        netOiUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        netOiLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "displayModeInput",
          name: "Display mode",
          defval: "Taker net long",
          options: [
            "Taker net long",
            "Taker net short",
            "Maker net long",
            "Maker net short",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "calculationMethodInput",
          name: "Calculation method",
          defval: "Cum",
          options: ["Cum", "Sum"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: true,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const netOiSymbol: string = `${ParseSymbol(symbol)}#NETOIDELTA`;
        this._context.new_sym(netOiSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [
          displayMode,
          calcMethod,
          measure,
          maType,
          length,
          channelType,
          showMetrics,
        ]: [string, string, string, number, string, boolean] = Array.from(
          { length: 7 },
          (_, i) => this._input(i + 1),
        );

        // Market data parsing
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const priceTime: any = this._context.new_var(this._context.symbol.time);
        const priceArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(2);
        const oiTime: any = this._context.new_var(this._context.symbol.time);
        const [takerLongDelta, takerShortDelta]: number[] = [
          this._context
            .new_var(PineJS.Std.open(this._context))
            .adopt(oiTime, mainSymbolTime, 1),
          this._context
            .new_var(PineJS.Std.high(this._context))
            .adopt(oiTime, mainSymbolTime, 1),
        ];

        this._context.select_sym(0);

        // Calculations
        let netOiDelta: number =
          displayMode === "Taker net long" || displayMode === "Maker net short"
            ? takerLongDelta
            : takerShortDelta;
        netOiDelta = Number.isNaN(netOiDelta) ? 0 : netOiDelta;

        if (measure === "USD") {
          netOiDelta *= priceArray.adopt(priceTime, mainSymbolTime, 1);
        }

        const netOiDeltaSeries: any = this._context.new_var(netOiDelta);
        const netOiClose: number =
          calcMethod === "Sum"
            ? PineJS.Std.sum(
                netOiDeltaSeries,
                1440 / Number(PineJS.Std.period(this._context)),
                this._context,
              )
            : PineJS.Std.cum(netOiDelta, this._context);

        const netOiCloseSeries: any = this._context.new_var(netOiClose);
        const netOiOpen: number = netOiCloseSeries.get(1);
        const [netOiHigh, netOiLow]: number[] = [
          PineJS.Std.max(netOiOpen, netOiClose),
          PineJS.Std.min(netOiOpen, netOiClose),
        ];
        const netOiHighSeries: any = this._context.new_var(netOiHigh);
        const netOiLowSeries: any = this._context.new_var(netOiLow);

        // Metrics calculations
        const [netOiMa, netOiUpperBand, netOiLowerBand]: [
          number,
          number,
          number,
        ] = showMetrics
          ? ChannelBounds(
              PineJS,
              netOiLowSeries,
              netOiHighSeries,
              netOiCloseSeries,
              channelType,
              maType,
              length,
              this._context,
            )
          : [NaN, NaN, NaN];

        // Return values
        return [
          netOiOpen,
          netOiHigh,
          netOiLow,
          netOiClose,
          netOiClose > netOiOpen ? 1 : 0,
          netOiClose > netOiOpen ? 1 : 0,
          netOiClose > netOiOpen ? 1 : 0,
          netOiMa,
          netOiUpperBand,
          netOiLowerBand,
        ];
      };
    },
  };
};
