// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  LineStudyPlotStyle,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const takeProfitAndStopLoss = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Take profit and stop loss",
    metainfo: {
      _metainfoVersion: 51,
      id: "take-profit-and-stop-loss@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFTA] Take profit and stop loss",
      shortDescription: "[SF] SL & TP",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "sl1Plot", type: StudyPlotType.Line },
        { id: "sl2Plot", type: StudyPlotType.Line },
        { id: "tp1Plot", type: StudyPlotType.Line },
        { id: "tp2Plot", type: StudyPlotType.Line },
      ],
      filledAreas: [
        {
          id: "slBackground",
          objAId: "sl1Plot",
          objBId: "sl2Plot",
          title: "Stop Loss",
          type: FilledAreaType.TypePlots,
        },
        {
          id: "tpBackground",
          objAId: "tp1Plot",
          objBId: "tp2Plot",
          title: "Take Profit",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        styles: {
          sl1Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(178, 24, 44)",
          },
          sl2Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(178, 24, 44)",
          },
          tp1Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(60, 166, 75)",
          },
          tp2Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(60, 166, 75)",
          },
        },
        filledAreasStyle: {
          slBackground: {
            color: "rgba(178, 24, 44, 0.5)",
            visible: true,
            transparency: 50,
          },
          tpBackground: {
            color: "rgba(60, 166, 75, 0.5)",
            visible: true,
            transparency: 50,
          },
        },
        inputs: {
          sideInput: "Long",
          slRangeStartInput: 3,
          slRangeEndInput: 5,
          tpRangeStartInput: 6,
          tpRangeEndInput: 9,
        },
      },
      styles: {
        sl1Plot: {
          title: "Stop loss start range",
        },
        sl2Plot: {
          title: "Stop loss end range",
        },
        tp1Plot: {
          title: "Take profit start range",
        },
        tp2Plot: {
          title: "Take profit end range",
        },
        slBackground: {
          title: "Stop loss",
        },
        tpBackground: {
          title: "Take profit",
        },
      },
      inputs: [
        {
          id: "sideInput",
          name: "Side",
          defval: "Long",
          type: StudyInputType.Text,
          options: ["Long", "Short"],
        },
        {
          id: "slRangeStartInput",
          name: "Stop-Loss range start",
          defval: 3,
          type: StudyInputType.Integer,
          min: 0,
        },
        {
          id: "slRangeEndInput",
          name: "Stop-Loss range end",
          defval: 5,
          type: StudyInputType.Integer,
          min: 0,
        },
        {
          id: "tpRangeStartInput",
          name: "Take-Profit range start",
          defval: 6,
          type: StudyInputType.Integer,
          min: 0,
        },
        {
          id: "tpRangeEndInput",
          name: "Take-Profit range end",
          defval: 9,
          type: StudyInputType.Integer,
          min: 0,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Inputs
        const [
          sideInput,
          slRangeStartInput,
          slRangeEndInput,
          tpRangeStartInput,
          tpRangeEndInput,
        ]: [string, number, number, number, number] = Array.from(
          { length: 5 },
          (_, i) => this._input(i),
        );

        // Market data
        const close: number = PineJS.Std.close(this._context);

        // Calculations
        let slRangeStart: number,
          slRangeEnd: number,
          tpRangeStart: number,
          tpRangeEnd: number;

        if (sideInput === "Long") {
          slRangeStart = close - (close / 100) * slRangeStartInput;
          slRangeEnd = close - (close / 100) * slRangeEndInput;
          tpRangeStart = close + (close / 100) * tpRangeStartInput;
          tpRangeEnd = close + (close / 100) * tpRangeEndInput;
        } else {
          slRangeStart = close + (close / 100) * slRangeStartInput;
          slRangeEnd = close + (close / 100) * slRangeEndInput;
          tpRangeStart = close - (close / 100) * tpRangeStartInput;
          tpRangeEnd = close - (close / 100) * tpRangeEndInput;
        }

        // Return values
        return [slRangeStart, slRangeEnd, tpRangeStart, tpRangeEnd];
      };
    },
  };
};
