import { useMarketplaceStore } from "../stores/useMarketplaceStore";

export const CONSTANTS = {
  USDT_SUFFIX: "USDT",
  SESSION_PREFIX: '={"session":"regular","symbol":"',
  DEFAULT_MA: "SMA",
} as const;

// ParseSymbol - function for parsing market data symbol
export const ParseSymbol = (symbol: string): string => {
  const parsed: string = symbol
    .replace(CONSTANTS.SESSION_PREFIX, "")
    .replace('"}', "")
    .split(CONSTANTS.USDT_SUFFIX)[0];
  return parsed;
};

// GetPriceSource - function for getting price source
export const GetPrice = (
  pineJS: any,
  sourceInput: string,
  context: any,
): any => {
  switch (sourceInput) {
    case "open":
      return pineJS.Std.open(context);
    case "high":
      return pineJS.Std.high(context);
    case "low":
      return pineJS.Std.low(context);
    case "hl2":
      return pineJS.Std.hl2(context);
    case "hlc3":
      return pineJS.Std.hlc3(context);
    case "ohlc4":
      return pineJS.Std.ohlc4(context);
    default:
      return pineJS.Std.close(context);
  }
};

// GetPriceSourceSeries - function for getting price source series
export const GetPriceSeries = (
  pineJS: any,
  sourceInput: string,
  context: any,
): any => {
  switch (sourceInput) {
    case "open":
      return context.new_var(pineJS.Std.open(context));
    case "high":
      return context.new_var(pineJS.Std.high(context));
    case "low":
      return context.new_var(pineJS.Std.low(context));
    case "hl2":
      return context.new_var(pineJS.Std.hl2(context));
    case "hlc3":
      return context.new_var(pineJS.Std.hlc3(context));
    case "ohlc4":
      return context.new_var(pineJS.Std.ohlc4(context));
    default:
      return context.new_var(pineJS.Std.close(context));
  }
};

// GetMarketDataSource - function for getting market data (volume, tape, open interest) source
export const GetMarketDataSource = (
  pineJS: any,
  sourceInput: string,
  context: any,
): number => {
  const mainSymbolTime: any = context.new_var(context.symbol.time);
  let sourceTime: any, sourceArray: any;
  switch (sourceInput) {
    case "Price":
      return pineJS.Std.close(context);
    case "Volume":
      return pineJS.Std.volume(context);
    case "CVD":
    case "Volume delta": {
      context.select_sym(2);
      sourceTime = context.new_var(context.symbol.time);
      sourceArray = context.new_var(pineJS.Std.low(context));
      context.select_sym(0);
      return sourceInput === "CVD"
        ? pineJS.Std.cum(
            2 * sourceArray.adopt(sourceTime, mainSymbolTime, 0) -
              pineJS.Std.volume(context),
            context,
          )
        : 2 * sourceArray.adopt(sourceTime, mainSymbolTime, 0) -
            pineJS.Std.volume(context);
    }
    case "Open interest": {
      context.select_sym(3);
      sourceTime = context.new_var(context.symbol.time);
      sourceArray = context.new_var(pineJS.Std.close(context));
      context.select_sym(0);
      return sourceArray.adopt(sourceTime, mainSymbolTime, 0);
    }
    case "Tape":
    case "Average trade size": {
      context.select_sym(2);
      sourceTime = context.new_var(context.symbol.time);
      sourceArray = context.new_var(pineJS.Std.open(context));
      context.select_sym(0);
      return sourceInput === "Tape"
        ? sourceArray.adopt(sourceTime, mainSymbolTime, 0)
        : pineJS.Std.volume(context) /
            sourceArray.adopt(sourceTime, mainSymbolTime, 0);
    }
    default:
      return pineJS.Std.volume(context);
  }
};

// MovingAverage - function for calculating custom type of moving average
export const MovingAverage = (
  pineJS: any,
  series: any,
  type: string,
  length: number,
  context: any,
): number => {
  const maFunctions: Record<
    string,
    (series: any, length: number, context: any) => number
  > = {
    SMA: (series, length, context) => pineJS.Std.sma(series, length, context),
    EMA: (series, length, context) => pineJS.Std.ema(series, length, context),
    WMA: (series, length, context) => pineJS.Std.wma(series, length, context),
    VWMA: (series, length, context) => pineJS.Std.vwma(series, length, context),
    LSMA: (series, length) => pineJS.Std.linreg(series, length, 0),
  };

  return (maFunctions[type] || maFunctions[CONSTANTS.DEFAULT_MA])(
    series,
    length,
    context,
  );
};

// ChannelBounds - function for calculating channel bounds
export const ChannelBounds = (
  pineJS: any,
  lowSeries: any,
  highSeries: any,
  closeSeries: any,
  channelType: string,
  maType: string,
  length: number,
  context: any,
): [number, number, number] => {
  const ma: number = MovingAverage(
    pineJS,
    closeSeries,
    maType,
    length,
    context,
  );

  let stdev: number, upperBand: number, lowerBand: number;
  switch (channelType) {
    case "Extremum channel":
      upperBand = pineJS.Std.highest(highSeries, length, context);
      lowerBand = pineJS.Std.lowest(lowSeries, length, context);
      break;
    case "Bollinger channel":
      stdev = pineJS.Std.stdev(closeSeries, length, context);
      upperBand = ma + stdev;
      lowerBand = ma - stdev;
      break;
    default:
      stdev = pineJS.Std.stdev(closeSeries, length, context);
      upperBand = ma + stdev;
      lowerBand = ma - stdev;
  }

  return [ma, upperBand, lowerBand];
};

// The functions MatrixGet, MatrixSet, Transpose, Multiply, vNorm, QRDiag, PInv, ADFTest are used to calculate the Dickey-Fuller test
// MatrixGet - function for getting matrix element
export const MatrixGet = (
  A: number[],
  i: number,
  j: number,
  nRows: number,
): number => {
  return A[i + nRows * j];
};

// MatrixSet - function for setting matrix element
export const MatrixSet = (
  A: number[],
  value: number,
  i: number,
  j: number,
  nRows: number,
): number[] => {
  A[i + nRows * j] = value;
  return A;
};

// Transpose - function for transposing matrix
export const Transpose = (
  A: number[],
  nRows: number,
  nColumns: number,
): number[] => {
  const at: number[] = Array(nRows * nColumns).fill(0);
  for (let i: number = 0; i < nRows; i++) {
    for (let j: number = 0; j < nColumns; j++) {
      MatrixSet(at, MatrixGet(A, i, j, nRows), j, i, nColumns);
    }
  }
  return at;
};

// Multiply - function for multiplying matrices
export const Multiply = (
  A: number[],
  B: number[],
  nRowsA: number,
  nColumnsA: number,
  nColumnsB: number,
): number[] => {
  const C: number[] = Array(nRowsA * nColumnsB).fill(0);
  const nRowsB: number = nColumnsA;
  for (let i: number = 0; i < nRowsA; i++) {
    for (let j: number = 0; j < nColumnsB; j++) {
      let elementC: number = 0;
      for (let k: number = 0; k < nColumnsA; k++) {
        elementC += MatrixGet(A, i, k, nRowsA) * MatrixGet(B, k, j, nRowsB);
      }
      MatrixSet(C, elementC, i, j, nRowsA);
    }
  }
  return C;
};

export const vNorm = (x: number[]): number => {
  const norm: number = x.reduce((sum, val) => sum + Math.pow(val, 2), 0);
  return Math.sqrt(norm);
};

// QRDiag - function for QR decomposition
export const QRDiag = (
  A: number[],
  nRows: number,
  nColumns: number,
): [number[], number[]] => {
  const Q: number[] = Array(nRows * nColumns).fill(0);
  const R: number[] = Array(nColumns * nColumns).fill(0);
  const a: number[] = Array(nRows).fill(0);

  for (let i: number = 0; i < nRows; i++) {
    a[i] = MatrixGet(A, i, 0, nRows);
  }
  let r: number = vNorm(a);
  MatrixSet(R, r, 0, 0, nColumns);
  for (let i: number = 0; i < nRows; i++) {
    MatrixSet(Q, a[i] / r, i, 0, nRows);
  }
  if (nColumns !== 1) {
    for (let k: number = 1; k < nColumns; k++) {
      for (let i: number = 0; i < nRows; i++) {
        a[i] = MatrixGet(A, i, k, nRows);
      }
      for (let j: number = 0; j < k; j++) {
        r = 0;
        for (let i: number = 0; i < nRows; i++) {
          r += MatrixGet(Q, i, j, nRows) * a[i];
        }
        MatrixSet(R, r, j, k, nColumns);
        for (let i: number = 0; i < nRows; i++) {
          a[i] -= r * MatrixGet(Q, i, j, nRows);
        }
      }
      r = vNorm(a);
      MatrixSet(R, r, k, k, nColumns);
      for (let i: number = 0; i < nRows; i++) {
        MatrixSet(Q, a[i] / r, i, k, nRows);
      }
    }
  }
  return [Q, R];
};

// PInv - function for calculating pseudo-inverse
export const PInv = (
  A: number[],
  nRows: number,
  nColumns: number,
): number[] => {
  const [Q, R] = QRDiag(A, nRows, nColumns);
  const QT: number[] = Transpose(Q, nRows, nColumns);

  const rinv: number[] = Array(nColumns * nColumns).fill(0);
  MatrixSet(rinv, 1 / MatrixGet(R, 0, 0, nColumns), 0, 0, nColumns);

  if (nColumns !== 1) {
    for (let j: number = 1; j < nColumns; j++) {
      for (let i: number = 0; i < j; i++) {
        let r = 0;
        for (let k: number = i; k < j; k++) {
          r += MatrixGet(rinv, i, k, nColumns) * MatrixGet(R, k, j, nColumns);
        }
        MatrixSet(rinv, r, i, j, nColumns);
      }
      for (let k: number = 0; k < j; k++) {
        MatrixSet(
          rinv,
          -MatrixGet(rinv, k, j, nColumns) / MatrixGet(R, j, j, nColumns),
          k,
          j,
          nColumns,
        );
      }
      MatrixSet(rinv, 1 / MatrixGet(R, j, j, nColumns), j, j, nColumns);
    }
  }

  const aInv: number[] = Multiply(rinv, QT, nColumns, nColumns, nRows);
  return aInv;
};

// ADFTest - function for calculating ADF test
export const ADFTest = (
  a: number[],
  nLag: number,
  confidence: string,
): [number, number] => {
  if (nLag >= a.length / 2 - 2) {
    throw new Error("ADF: Maximum lag must be less than (Length / 2 - 2)");
  }

  const nobs: number = a.length - nLag - 1;
  const y: number[] = [];
  const x: number[] = [];
  const x0: number[] = [];
  for (let i: number = 0; i < nobs; i++) {
    y.push(a[i] - a[i + 1]);
    x.push(a[i + 1]);
    x0.push(1.0);
  }

  let X: number[] = [...x];
  let M: number = 2;
  X = X.concat(x0);

  if (nLag > 0) {
    for (let n: number = 1; n <= nLag; n++) {
      const xl: number[] = [];
      for (let i: number = 0; i < nobs; i++) {
        xl.push(a[i + n] - a[i + n + 1]);
      }
      X = X.concat(xl);
      M += 1;
    }
  }

  const c: number[] = PInv(X, nobs, M);
  const coeff: number[] = Multiply(c, y, M, nobs, 1);
  const yHat: number[] = Multiply(X, coeff, nobs, M, 1);
  const meanX: number = x.reduce((sum, val) => sum + val, 0) / x.length;

  let sum1: number = 0;
  let sum2: number = 0;
  for (let i: number = 0; i < nobs; i++) {
    sum1 += Math.pow(y[i] - yHat[i], 2) / (nobs - M);
    sum2 += Math.pow(x[i] - meanX, 2);
  }

  const se: number = Math.sqrt(sum1 / sum2);
  const adf: number = coeff[0] / se;

  let crit: number = 0;
  switch (confidence) {
    case "90%":
      crit = -2.56677 - 1.5384 / nobs - 2.809 / (nobs * nobs);
      break;
    case "95%":
      crit =
        -2.86154 -
        2.8903 / nobs -
        4.234 / (nobs * nobs) -
        40.04 / (nobs * nobs * nobs);
      break;
    case "99%":
      crit =
        -3.43035 -
        6.5393 / nobs -
        16.786 / (nobs * nobs) -
        79.433 / (nobs * nobs * nobs);
      break;
  }
  return [adf, crit];
};

// DemoAdaptation - function for checking if the indicator is in demo mode
export const DemoAdaptation = (pineJS: any, context: any): boolean => {
  if (Date.now() - pineJS.Std.time(context) < 21600000) {
    return true;
  }
  return false;
};

// AdjustPrice - adaptation of such indicators as HFT Activity, OI Spike, etc. to synthetic symbols
export const AdjustPrice = (
  pineJS: any,
  context: any,
  price: number,
): number => {
  const symbol: string = pineJS.Std.ticker(context);

  if (
    symbol.includes("/") ||
    symbol.includes("*") ||
    symbol.includes("-") ||
    symbol.includes("+")
  ) {
    return pineJS.Std.hlc3(context);
  }

  return price;
};

// AccessCheck - function for checking access to indicators
export function AccessCheck(
  pineJS: any,
  category: string,
  productId: string,
  purchasedProductIds: string[],
  context: any,
): boolean {
  if (purchasedProductIds.includes(productId)) {
    return true;
  } else {
    console.log("Access denied diplay delayed data");
    const timeDiff: number = Date.now() - pineJS.Std.time(context);
    const period: number = pineJS.Std.period(context);

    switch (category) {
      case "Microstructure":
        return timeDiff >= 21600000 ? true : false;
      case "Technical":
      case "Risk management":
        return timeDiff >= 21600000 * period ? true : false;
      case "Sentiment":
        return timeDiff >= 604800000 ? true : false;
      default:
        return false;
    }
  }
}
