//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const layeringHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Layering",
    metainfo: {
      _metainfoVersion: 51,
      id: "layering-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Layering highlighter",
      shortDescription: "[SF] Layering highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
          ],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
            11: 11,
            12: 12,
            13: 13,
            14: 14,
            15: 15,
            16: 16,
            17: 17,
            18: 18,
            19: 19,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(60, 166, 75, 0.50)" },
              { color: "rgba(60, 166, 75, 0.45)" },
              { color: "rgba(60, 166, 75, 0.40)" },
              { color: "rgba(60, 166, 75, 0.35)" },
              { color: "rgba(60, 166, 75, 0.30)" },
              { color: "rgba(60, 166, 75, 0.25)" },
              { color: "rgba(60, 166, 75, 0.20)" },
              { color: "rgba(60, 166, 75, 0.15)" },
              { color: "rgba(60, 166, 75, 0.10)" },
              { color: "rgba(60, 166, 75, 0.05)" },
              { color: "rgba(178, 24, 44, 0.05)" },
              { color: "rgba(178, 24, 44, 0.10)" },
              { color: "rgba(178, 24, 44, 0.15)" },
              { color: "rgba(178, 24, 44, 0.20)" },
              { color: "rgba(178, 24, 44, 0.25)" },
              { color: "rgba(178, 24, 44, 0.30)" },
              { color: "rgba(178, 24, 44, 0.35)" },
              { color: "rgba(178, 24, 44, 0.40)" },
              { color: "rgba(178, 24, 44, 0.45)" },
              { color: "rgba(178, 24, 44, 0.50)" },
            ],
          },
        },
        styles: {},
        inputs: {},
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const period: number = PineJS.Std.period(this._context);
        const baseSymbol: string = ParseSymbol(symbol);
        const layeringSymbol: string = `${baseSymbol}#SF_LAYERING`;
        this._context.new_sym(layeringSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Market data parsing
        this._context.select_sym(1);
        const layeringTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const layeringArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);

        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const layering: number = layeringArray.adopt(
          layeringTime,
          mainSymbolTime,
          1,
        );

        //Colorer
        let backgroundColor: number = NaN;
        switch (true) {
          case layering === 10:
            backgroundColor = 0;
            break;
          case layering === 9:
            backgroundColor = 1;
            break;
          case layering === 8:
            backgroundColor = 2;
            break;
          case layering === 7:
            backgroundColor = 3;
            break;
          case layering === 6:
            backgroundColor = 4;
            break;
          case layering === 5:
            backgroundColor = 5;
            break;
          case layering === 4:
            backgroundColor = 6;
            break;
          case layering === 3:
            backgroundColor = 7;
            break;
          case layering === 2:
            backgroundColor = 8;
            break;
          case layering === 1:
            backgroundColor = 9;
            break;
          case layering === 0:
            backgroundColor = NaN;
            break;
          case layering === -1:
            backgroundColor = 10;
            break;
          case layering === -2:
            backgroundColor = 11;
            break;
          case layering === -3:
            backgroundColor = 12;
            break;
          case layering === -4:
            backgroundColor = 13;
            break;
          case layering === -5:
            backgroundColor = 14;
            break;
          case layering === -6:
            backgroundColor = 15;
            break;
          case layering === -7:
            backgroundColor = 16;
            break;
          case layering === -8:
            backgroundColor = 17;
            break;
          case layering === -9:
            backgroundColor = 18;
            break;
          case layering === -10:
            backgroundColor = 19;
            break;
          default:
            backgroundColor = NaN;
        }

        //Returns
        return [backgroundColor];
      };
    },
  };
};
