import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { useUserStore } from "@/lib/stores/userStore";

export interface ISFUser {
  uid: string;
  datasource: string;
  displayName: string;
  email: string;
  firstName: string;
  lastLoggedIn: number;
  lastName: string;
  nonce: string;
  referLink: string;
  referral: string;
  registeredAt: number;
  role: string;
  socialSubscriptions: Record<string, any>;
  telegram: boolean;
  solanaWallet: string;
  timezone: string;
  username: string;
  wallet: string;
  isAdmin: boolean;
}

export const useUser = (uid: string | undefined) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const { user, setUser } = useUserStore();

  useEffect(() => {
    if (!uid) {
      setUser(null);
      setLoading(false);
      return;
    }

    // Subscribe to real-time updates
    const userRef = doc(db, "users", uid);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const userData = { uid, ...doc.data() } as ISFUser;
          setUser(userData);
        } else {
          setUser(null);
        }
        setLoading(false);
      },
      (err) => {
        setError(
          err instanceof Error ? err : new Error("Failed to fetch user"),
        );
        setUser(null);
        setLoading(false);
      },
    );

    // Cleanup subscription on unmount or when uid changes
    return () => unsubscribe();
  }, [uid, setUser]);

  return { user, loading, error };
};
