//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { LineStudyPlotStyle, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const spoofingHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Spoofing highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "spoofing-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spoofing highlighter",
      shortDescription: "[SF] Spoofing highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "askSpoofingPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bidSpoofingPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [{ name: "Ask spoofing" }, { name: "Bid spoofing" }],
          valToIndex: {
            0: 0,
            1: 1,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(178, 24, 44, 0.25)" },
              { color: "rgba(60, 166, 75, 0.25)" },
            ],
          },
        },
        styles: {
          askSpoofingPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            linewidth: 3,
            transparency: 1,
          },
          bidSpoofingPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
            linewidth: 3,
            transparency: 1,
          },
        },
        precision: 2,
        inputs: {},
      },
      styles: {
        askSpoofingPlot: {
          title: "Ask spoofing",
          histogramBase: 0,
        },
        bidSpoofingPlot: {
          title: "Bid spoofing",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const period: number = PineJS.Std.period(this._context);

        const spoofingSymbolUp: string = `${baseSymbol}#SPOOF_LAYER_UP`;
        this._context.new_sym(spoofingSymbolUp, period);

        const spoofingSymbolDown: string = `${baseSymbol}#SPOOF_LAYER_DOWN`;
        this._context.new_sym(spoofingSymbolDown, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Market data parsing
        const close: number = PineJS.Std.close(this._context);
        this._context.select_sym(1);
        const askSpoofingTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const askSpoofingArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(2);
        const bidSpoofingTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const bidSpoofingArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        const askPrice: number = askSpoofingArray.adopt(
          askSpoofingTime,
          mainSymbolTimeSeries,
          1,
        );
        const bidPrice: number = bidSpoofingArray.adopt(
          bidSpoofingTime,
          mainSymbolTimeSeries,
          1,
        );

        // Calculation
        let ask: number = askPrice > close ? askPrice : NaN;
        let bid: number = bidPrice < close ? bidPrice : NaN;
        let backgroundColor: number = NaN;
        switch (true) {
          case ask > 0 && bid > 0:
            backgroundColor = NaN;
            break;
          case ask > 0:
            backgroundColor = 0;
            break;
          case bid > 0:
            backgroundColor = 1;
            break;
          default:
            backgroundColor = NaN;
        }

        // Adaptation to the price of synthetics
        const symbol: string = PineJS.Std.ticker(this._context);
        if (
          symbol.includes("/") ||
          symbol.includes("*") ||
          symbol.includes("-") ||
          symbol.includes("+")
        ) {
          ask = NaN;
          bid = NaN;
        }

        // Returns
        return [ask, bid, backgroundColor];
      };
    },
  };
};
