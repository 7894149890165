import { ISpoofLayerResponse } from "@/domain/interfaces/general.interface";
import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface PatternPredictResponse {
  data: {
    [key: string]: IPatternPredict[];
  };
  timestamp: number;
  timeFrame: string;
}

export interface PatternDetails {
  min: number;
  q1: number;
  median: number;
  q3: number;
  upperWhisker: number;
  max: number;
}

export interface IPatternPredict {
  name: string;
  timestamp: number;
  size: number;
  statistic: {
    totalPatterns: number;
    UD: PatternDetails;
    DD: PatternDetails;
  };
}

export interface PatternPredictFlat {
  symbol: string;
  timestamp: number;
  size: number;
  name: string;
  totalPatterns: number;
  UD: PatternDetails;
  DD: PatternDetails;
}

export function usePatternPredict(interval: string) {
  const handler = useCallback(
    (_: PatternPredictResponse, newData: PatternPredictResponse) => {
      return newData;
    },
    [],
  );

  const topic =
    `sf.core.scripts.screener.patternPredict.${interval}` as SocketTopics;
  return useGenericSocket<PatternPredictResponse, PatternPredictResponse>(
    topic,
    handler,
  );
}
