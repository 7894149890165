//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  LineStudyPlotStyle,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { GetPrice } from "@/lib/indicators/indicators-common-functions";

export const simpleCevVolatilityApproximation = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Simple CEV volatility approximation",
    metainfo: {
      _metainfoVersion: 51,
      id: "simple-cev-volatility-approximation@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Simple CEV volatility approximation",
      shortDescription: "[SF] CEV vol",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          lengthInput: 10,
          multiplierInput: 2,
        },
      },
      styles: {
        metricPlot: {
          title: "CEV vol",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
        {
          id: "multiplierInput",
          name: "Multiplier",
          defval: 2,
          min: 1,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, lengthInput, multiplierInput]: [
          string,
          number,
          number,
        ] = Array.from({ length: 3 }, (_, i) => this._input(i));

        // Market data parsing
        const source: number = GetPrice(PineJS, sourceInput, this._context);
        const sourceSeries: any = this._context.new_var(source);

        // Calculation
        const avg: number =
          PineJS.Std.sum(sourceSeries, lengthInput, this._context) /
          lengthInput;
        const stdev: number =
          PineJS.Std.stdev(sourceSeries, lengthInput, this._context) /
          lengthInput;
        const approxCEV: number =
          stdev * multiplierInput * Math.abs((source - avg) / avg);

        // Return
        return [approxCEV];
      };
    },
  };
};
