import { useEffect, useRef } from "react";
import {
  getAggregatedConstructorTopic,
  socketConnection,
} from "@/lib/natsConnection.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type IValuesByTime = {
  "15m": number;
  "30m": number;
  "1h": number;
  "4h": number;
  "1d": number;
  "1w": number;
  "1M": number;
};

// {"timestamp":1732693242179,"symbol":"DOTUSDT","priceChg":{"15m":-0.036,"1M":96.556,"1d":2.303,"1h":0.22,"1w":37.995,"30m":0.415,"4h":1.922},"openInterestChg":{"15m":0.302,"1M":1.326,"1d":1.421,"1h":0.169,"1w":1.932,"30m":0.34,"4h":1.823},"volumeChg":{"15m":5.46,"1M":195.896,"1d":8.063,"1h":-25.042,"1w":101.904,"30m":17.789,"4h":-24.233},"buySellRatioChg":{"15m":50.377,"1M":48.425,"1d":48.528,"1h":52.916,"1w":48.766,"30m":52.736,"4h":51.702},"volumeQuote":{"15m":3268812,"1M":10339706497,"1d":527286016,"1h":11726631,"1w":5573277132,"30m":6359978,"4h":48942536},"adtv":{"15m":91.71,"1M":242.22,"1d":94.56,"1h":87.57,"1w":153.89,"30m":109.14,"4h":100.86},"funding":0.00024883,"openInterest":13785701.8,"manipulationMonitor":{"corr":0.998512,"corrV":0.976352},"powerCvd":{"value":1.578,"isShort":false,"isLong":false},"spotFutureSpread":{"sma":-0.086,"value":-0.061}}
export interface IAggregatedConstructor {
  timestamp: number;
  symbol: string;
  priceChg: IValuesByTime;
  openInterestChg: IValuesByTime;
  volumeChg: IValuesByTime;
  buySellRatioChg: IValuesByTime;
  volumeQuote: IValuesByTime;
  adtv: IValuesByTime;
  funding: number;
  openInterest: number;
  manipulationMonitor: {
    corr: number;
    corrV: number;
  };
  powerCvd: {
    value: number;
    isShort: boolean;
    isLong: boolean;
  };
  spotFutureSpread: {
    sma: number;
    value: number;
  };
}

export type IAggregatedData = Record<string, IAggregatedConstructor>;

const key = ["aggregatedData"];

export function useAggregatedConstructor(symbols: string[]) {
  const unsubscribeRef = useRef<Record<string, () => void>>({});
  const queryClient = useQueryClient();

  const oldSymbols = useRef<string[]>([]);

  useEffect(() => {
    // sub to new symbols
    symbols
      .filter((s) => !oldSymbols.current.includes(s))
      .forEach((symbol) => {
        const topic = getAggregatedConstructorTopic(symbol);
        socketConnection
          .subscribe(topic, (newData: IAggregatedConstructor) => {
            queryClient.setQueryData<IAggregatedData>(
              key,
              (currentData: Record<string, IAggregatedConstructor> = {}) => {
                return { ...currentData, [newData.symbol]: newData };
              },
            );
          })
          .then((unsub) => {
            unsubscribeRef.current[symbol] = unsub;
          });
      });

    //unsub from unused symbols
    oldSymbols.current
      .filter((s) => !symbols.includes(s))
      .forEach((symbol) => {
        unsubscribeRef.current[symbol]();
        delete unsubscribeRef.current[symbol];
      });

    oldSymbols.current = symbols;
  }, [queryClient, symbols]);

  useEffect(() => {
    return () => {
      // unsub from everything on unmount
      Object.keys(unsubscribeRef.current).forEach((symbol) => {
        unsubscribeRef.current[symbol]();
      });
    };
  }, []);

  return useQuery<IAggregatedData>({
    queryKey: key,
    enabled: false,
  });
}
