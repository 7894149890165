//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const marketPowerHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market power Highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-power-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Market power highlighter",
      shortDescription: "[SF] Market power highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "0%" },
            { name: "10%" },
            { name: "20%" },
            { name: "30%" },
            { name: "40%" },
            { name: "40%" },
            { name: "50%" },
            { name: "60%" },
            { name: "70%" },
            { name: "80%" },
            { name: "90%" },
            { name: "100%" },
          ],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
            11: 11,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgba(38,164,82,0.0)", width: 1, style: 0 },
              1: { color: "rgba(38,164,82,0.05)", width: 1, style: 0 },
              2: { color: "rgba(38,164,82,0.1)", width: 1, style: 0 },
              3: { color: "rgba(38,164,82,0.15)", width: 1, style: 0 },
              4: { color: "rgba(38,164,82,0.2)", width: 1, style: 0 },
              5: { color: "rgba(38,164,82,0.25)", width: 1, style: 0 },
              6: { color: "rgba(38,164,82,0.3)", width: 1, style: 0 },
              7: { color: "rgba(38,164,82,0.35)", width: 1, style: 0 },
              8: { color: "rgba(38,164,82,0.4)", width: 1, style: 0 },
              9: { color: "rgba(38,164,82,0.45)", width: 1, style: 0 },
              10: { color: "rgba(38,164,82,0.5)", width: 1, style: 0 },
              11: { color: "rgba(38,164,82,0.55)", width: 1, style: 0 },
            },
          },
        },
        styles: {},
        inputs: {
          filterNoiselInput: false,
        },
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "filterNoiselInput",
          name: "Filter noise?",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const marketPowerSymbol: string = `${baseSymbol}#SF_MARKET_POWER`;
        this._context.new_sym(
          marketPowerSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const filterNoiselInput: boolean = this._input(0);

        // Data parsing
        this._context.select_sym(1);
        const marketPowerTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const marketPowerArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        let value: number = marketPowerArray.adopt(
          marketPowerTime,
          mainSymbolTime,
          0,
        );

        // Background color calculation
        if (filterNoiselInput && Math.abs(value) < 1) {
          value = NaN;
        }

        let bgColor: number = 0;
        switch (true) {
          case Math.abs(value) > 15:
            bgColor = 11;
            break;
          case Math.abs(value) > 14:
            bgColor = 10;
            break;
          case Math.abs(value) > 13:
            bgColor = 9;
            break;
          case Math.abs(value) > 12:
            bgColor = 8;
            break;
          case Math.abs(value) > 11:
            bgColor = 7;
            break;
          case Math.abs(value) > 10:
            bgColor = 6;
            break;
          case Math.abs(value) > 9:
            bgColor = 5;
            break;
          case Math.abs(value) > 8:
            bgColor = 4;
            break;
          case Math.abs(value) > 7:
            bgColor = 3;
            break;
          case Math.abs(value) > 6:
            bgColor = 2;
            break;
          case Math.abs(value) > 5:
            bgColor = 1;
            break;
          default:
            bgColor = 0;
        }

        // Return
        return [bgColor];
      };
    },
  };
};
