//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const netOpenInterestDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Net open interest delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "net-open-interest-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Net open interest delta",
      shortDescription: "[SF] Net OI delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaColorer",
          type: StudyPlotType.Colorer,
          target: "oiDeltaPlot",
          palette: "oiDeltaPalette",
        },
        {
          id: "negOiDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "posOiDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        oiDeltaPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          oiDeltaPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          oiDeltaPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          oiDeltaColorer: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          negOiDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          posOiDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          oiDeltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          displayModeInput: "Taker net long",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        oiDeltaPlot: {
          title: "Net OI delta",
          histogramBase: 0,
        },
        negOiDeltaMaPlot: {
          title: "Negative net OI delta MA",
          histogramBase: 0,
        },
        posOiDeltaMaPlot: {
          title: "Positive net OI delta MA",
          histogramBase: 0,
        },
        oiDeltaStDevPlot: {
          title: "Net OI delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "displayModeInput",
          name: "Display mode",
          defval: "Taker net long",
          options: [
            "Taker net long",
            "Taker net short",
            "Maker net long",
            "Maker net short",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const netOiSymbol: string = `${ParseSymbol(symbol)}#NETOIDELTA`;
        this._context.new_sym(netOiSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [
          displayModeInput,
          measureInput,
          maTypeInput,
          lengthInput,
          showMetricsInput,
        ]: [string, string, string, number, boolean] = Array.from(
          { length: 5 },
          (_, i) => this._input(i + 1),
        );

        // Get market data
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const priceData: { time: any; values: any } = {
          time: this._context.new_var(this._context.symbol.time),
          values: this._context.new_var(PineJS.Std.close(this._context)),
        };

        this._context.select_sym(2);
        const oiData: {
          time: any;
          longDelta: any;
          shortDelta: any;
        } = {
          time: this._context.new_var(this._context.symbol.time),
          longDelta: this._context.new_var(PineJS.Std.open(this._context)),
          shortDelta: this._context.new_var(PineJS.Std.high(this._context)),
        };

        this._context.select_sym(0);

        const deltas: { long: number; short: number } = {
          long: oiData.longDelta.adopt(oiData.time, mainSymbolTime, 1),
          short: oiData.shortDelta.adopt(oiData.time, mainSymbolTime, 1),
        };

        // Calculations
        let oiDelta: number =
          displayModeInput === "Taker net long" ||
          displayModeInput === "Maker net short"
            ? deltas.long
            : deltas.short;

        if (measureInput === "USD") {
          oiDelta *= priceData.values.adopt(priceData.time, mainSymbolTime, 0);
        }

        const metrics: {
          stdev: number;
          posMA: number;
          negMA: number;
        } = {
          stdev: NaN,
          posMA: NaN,
          negMA: NaN,
        };

        if (showMetricsInput) {
          const series: {
            oi: any;
            pos: any;
            neg: any;
          } = {
            oi: this._context.new_var(oiDelta),
            pos: this._context.new_var(oiDelta > 0 ? oiDelta : 0),
            neg: this._context.new_var(oiDelta < 0 ? oiDelta : 0),
          };

          metrics.posMA = MovingAverage(
            PineJS,
            series.pos,
            maTypeInput,
            lengthInput,
            this._context,
          );
          metrics.negMA = MovingAverage(
            PineJS,
            series.neg,
            maTypeInput,
            lengthInput,
            this._context,
          );
          metrics.stdev = PineJS.Std.stdev(
            series.oi,
            lengthInput,
            this._context,
          );
        }

        // Return
        return [
          0,
          oiDelta,
          Number(oiDelta >= 0),
          metrics.negMA,
          metrics.posMA,
          metrics.stdev,
        ];
      };
    },
  };
};
