// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  LineStudyPlotStyle,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import { GetPrice } from "@/lib/indicators/indicators-common-functions";

export const altucherBollingerBands = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Altucher Bollinger bands",
    metainfo: {
      _metainfoVersion: 51,
      id: "altucher-bollinger-bands@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Altucher Bollinger bands",
      shortDescription: "[SF] Altucher BB",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "fastSmaPlot", type: StudyPlotType.Line },
        { id: "slowSmaPlot", type: StudyPlotType.Line },
        { id: "conditional1Plot", type: StudyPlotType.Line },
        { id: "conditional2Plot", type: StudyPlotType.Line },
      ],
      filledAreas: [
        {
          id: "Background",
          objAId: "conditional1Plot",
          objBId: "conditional2Plot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        filledAreasStyle: {
          Background: {
            color: "rgba(255, 160, 40, 0.5)",
            visible: true,
            transparency: 50,
          },
        },
        styles: {
          fastSmaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(255, 160, 40)",
          },
          slowSmaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(13, 157, 219)",
          },
          conditional1Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.Circles,
            color: "rgb(255, 160, 40)",
          },
          conditional2Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.Circles,
            color: "rgb(255, 160, 40)",
          },
        },
        inputs: {
          useCustomSettingsInput: false,
          sourceInput: "close",
          bbLengthInput: 450,
          bbMultInput: 1.5,
          fastSmaLengthInput: 235,
          slowSmaLengthInput: 345,
          levelInput: -25,
        },
      },
      styles: {
        fastSmaPlot: {
          title: "Fast SMA",
          histogramBase: 0,
        },
        slowSmaPlot: {
          title: "Slow SMA",
          histogramBase: 0,
        },
        conditional1Plot: {
          title: "Upper band",
          histogramBase: 0,
        },
        conditional2Plot: {
          title: "Lower band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "useCustomSettingsInput",
          name: "Use custom settings",
          defval: false,
          type: StudyInputType.Bool,
        },
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "bbLengthInput",
          name: "BB Length",
          defval: 450,
          type: StudyInputType.Integer,
        },
        {
          id: "bbMultInput",
          name: "BB Multiplier",
          defval: 1.5,
          type: StudyInputType.Float,
        },
        {
          id: "fastSmaLengthInput",
          name: "Fast SMA length",
          defval: 235,
          type: StudyInputType.Integer,
        },
        {
          id: "slowSmaLengthInput",
          name: "Slow SMA length",
          defval: 345,
          type: StudyInputType.Integer,
        },
        {
          id: "levelInput",
          name: "Level",
          defval: -25,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Inputs
        const [
          useCustomSettingsInput,
          sourceInput,
          bbLengthInput,
          bbMultInput,
          fastSmaLengthInput,
          slowSmaLengthInput,
          levelInput,
        ]: [boolean, string, number, number, number, number, number] =
          Array.from({ length: 7 }, (_, i) => this._input(i));

        // Market data parsing
        const close: any = GetPrice(PineJS, sourceInput, this._context);
        const closeSeries: any = this._context.new_var(close);

        // Calculations
        let bbLength: number,
          bbMult: number,
          fastSmaLength: number,
          slowSmaLength: number,
          level: number;

        if (useCustomSettingsInput) {
          bbLength = bbLengthInput;
          bbMult = bbMultInput;
          fastSmaLength = fastSmaLengthInput;
          slowSmaLength = slowSmaLengthInput;
          level = levelInput;
        } else {
          const lengthMlt: number = Number(PineJS.Std.period(this._context));
          bbLength = Math.round(450 / lengthMlt);
          bbMult = bbMultInput;
          fastSmaLength = Math.round(235 / lengthMlt);
          slowSmaLength = Math.round(345 / lengthMlt);
          level = levelInput;
        }

        const bbBasis: number = PineJS.Std.sma(
          closeSeries,
          bbLength,
          this._context,
        );
        const sigma: number = PineJS.Std.stdev(
          closeSeries,
          bbLength,
          this._context,
        );
        const bbUpper: number = bbBasis + sigma * bbMult;
        const bbLower: number = bbBasis - sigma * bbMult;

        const formula: number = ((close - bbLower) / (bbUpper - bbLower)) * 100;

        const booleanExpress: boolean = close < bbLower && formula < level;

        const fastSma: number = PineJS.Std.sma(
          closeSeries,
          fastSmaLength,
          this._context,
        );
        const slowSma: number = PineJS.Std.sma(
          closeSeries,
          slowSmaLength,
          this._context,
        );

        const conditional1: number = booleanExpress
          ? close - (close / 100) * 3.5
          : NaN;
        const conditional2: number = booleanExpress
          ? close - (close / 100) * 4.5
          : NaN;

        // Return values
        return [fastSma, slowSma, conditional1, conditional2];
      };
    },
  };
};
