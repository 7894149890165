//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const quotedSpread = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Quoted spread",
    metainfo: {
      _metainfoVersion: 51,
      id: "quoted-spread@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Quoted spread",
      shortDescription: "[SF] Quoted spread",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "percent",
        precision: 5,
      },

      plots: [
        {
          id: "markPricePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "askPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bidPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          markPricePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
          askPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          bidPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
            transparency: 0,
          },
        },
        precision: 5,
        inputs: {
          symbolInput: "",
        },
      },
      styles: {
        markPricePlot: {
          title: "Mark price",
          histogramBase: 0,
        },
        askPlot: {
          title: "Ask",
          histogramBase: 0,
        },
        bidPlot: {
          title: "Bid",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const period: number = PineJS.Std.period(this._context);
        const baseSymbol: string = ParseSymbol(symbol);
        const quotedSpreadSymbol: string = `${baseSymbol}#BIDASKQUOTEDSPREAD`;
        this._context.new_sym(quotedSpreadSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Market data parsing
        this._context.select_sym(1);
        const orderbookTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const askArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const bidArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        let ask: number = askArray.adopt(orderbookTime, mainSymbolTime, 0);
        let bid: number = bidArray.adopt(orderbookTime, mainSymbolTime, 0);

        // Calculate mark price
        const markPrice: number = (ask + bid) / 2;
        ask = ((ask - markPrice) / markPrice) * 100;
        bid = ((bid - markPrice) / markPrice) * 100;

        // Return values
        return [0, ask, bid];
      };
    },
  };
};
