//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  GetMarketDataSource,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const positiveNegativeDataIndex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Positive & negative data index",
    metainfo: {
      _metainfoVersion: 51,
      id: "positive-negative-data-index@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Positive & negative data index",
      shortDescription: "[SF] PNDI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "pndiPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "pndiColorer",
          type: StudyPlotType.Colorer,
          target: "pndiPlot",
          palette: "linePalette",
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maColorer",
          type: StudyPlotType.Colorer,
          target: "maPlot",
          palette: "linePalette",
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.Colorer,
          target: "backgroundPlot",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "pndiPlot",
          objBId: "maPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        linePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling PNDI" },
            1: { name: "Growing PNDI" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling PNDI" },
            1: { name: "Growing PNDI" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0)",
            visible: true,
            transparency: 20,
          },
        },
        palettes: {
          linePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.1)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.1)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          pndiPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          pndiColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          maColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          backgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          modeInput: "Positive",
          sourceInput: "Volume",
          initialValueInput: 100,
          maTypeInput: "SMA",
          lengthInput: 240,
        },
      },
      styles: {
        pndiPlot: {
          title: "PNDI",
          histogramBase: 0,
        },
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "modeInput",
          name: "Calculation mode",
          defval: "Positive",
          options: ["Positive", "Negative"],
          type: StudyInputType.Text,
        },
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: [
            "Volume",
            "Volume delta",
            "Open interest",
            "Tape",
            "Average trade size",
          ],
          type: StudyInputType.Text,
        },
        {
          id: "initialValueInput",
          name: "Initial value",
          defval: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: any = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);

        const volumeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, period);

        const openInterestSymbol: string = `${baseSymbol}#SF_OPEN_INTEREST`;
        this._context.new_sym(openInterestSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [
          modeInput,
          sourceInput,
          initialValueInput,
          maTypeInput,
          maLengthInput,
        ]: [string, string, number, string, number] = Array.from(
          { length: 5 },
          (_, i) => this._input(i + 1),
        );

        // Market data parsing
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const priceTime: any = this._context.new_var(this._context.symbol.time);
        const priceArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(0);
        const price: number = priceArray.adopt(priceTime, mainSymbolTime, 0);
        const priceSeries: any = this._context.new_var(price);
        const pricePrevious: number = priceSeries.get(1);

        const dataSource: number = GetMarketDataSource(
          PineJS,
          sourceInput,
          this._context,
        );
        const dataSourceSeries: any = this._context.new_var(dataSource);

        // Calculations
        const pndiSeries: any = this._context.new_var(0);
        const pndiPrevious: number = pndiSeries.get(1);
        const change: number = PineJS.Std.change(dataSourceSeries);

        // CalculatePndi - function to calculate PNDI
        const CalculatePndi = (): number => {
          if (PineJS.Std.na(pndiPrevious)) {
            return initialValueInput;
          }

          const isValidChange: boolean =
            modeInput === "Negative" ? change < 0 : change > 0;
          return isValidChange
            ? (pndiPrevious * close) / closePrevious
            : pndiPrevious;
        };

        const pndi: number = CalculatePndi();
        pndiSeries.set(pndi);

        const movingAverage: number = MovingAverage(
          PineJS,
          pndiSeries,
          maTypeInput,
          maLengthInput,
          this._context,
        );

        const color: number = pndi > movingAverage ? 1 : 0;

        // Return values
        return [pndi, color, movingAverage, color, color];
      };
    },
  };
};
