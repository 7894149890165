//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const spotPerpetualsDominanceHighlighter = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Spot perpetuals dominance highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "spot-perpetuals-dominance-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spot perpetuals dominance highlighter",
      shortDescription: "[SF] Spot perp dominance highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      defaults: {
        styles: {},
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(13, 157, 219, 0.25)" },
              { color: "rgba(255, 160, 40, 0.25)" },
            ],
          },
        },
      },
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "Perpetuals dominance" },
            { name: "Spot dominance" },
          ],
          valToIndex: {
            100: 0,
            200: 1,
          },
        },
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const period: number = PineJS.Std.period(this._context);
        const spotPerpSymbol: string = `${baseSymbol}#SF_SPREAD`;
        this._context.new_sym(spotPerpSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Market data parsing
        this._context.select_sym(1);
        const spotPerpetualsDominanceTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotPerpetualsDominanceArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const spotPerpetualsDominanceSmaArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const dominance: number = spotPerpetualsDominanceArray.adopt(
          spotPerpetualsDominanceTime,
          mainSymbolTime,
          0,
        );
        const dominanceSma: number = spotPerpetualsDominanceSmaArray.adopt(
          spotPerpetualsDominanceTime,
          mainSymbolTime,
          0,
        );

        // Calculations
        let color: number = NaN;
        if (dominance <= dominanceSma) {
          color = 100;
        } else {
          color = 200;
        }

        // Return
        return [color];
      };
    },
  };
};
