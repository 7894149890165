import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import {
  IColumn,
  IRow,
  ISfTable,
} from "@/domain/interfaces/sfTable.interface.ts";
import {
  IStrategy,
  IStrategyV2Generic,
} from "@/domain/interfaces/general.interface.ts";

import { PriceData, useBinancePrices } from "@/hooks/useBinancePrices.ts";
import dayjs from "dayjs";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useAltusherBB } from "@/hooks/strategies/useAltusherBB";
import { useAltusherGaps } from "@/hooks/strategies/useAltusherGaps";
import { useCmStrategyV1 } from "@/hooks/strategies/useCmStrategyV1";
import { useCmStrategyV2 } from "@/hooks/strategies/useCmStrategyV2";
import { useDirectionalIndexWithBollingerConfirmationV1 } from "@/hooks/strategies/useDirectionalIndexWithBollingerConfirmationV1";
import { useDualEmaBollingerV1 } from "@/hooks/strategies/useDualEmaBollingerV1";
import { useMultiPeriodVolatilityFilterV1 } from "@/hooks/strategies/useMultiPeriodVolatilityFilterV1";
import { useRelativeHighLowOscillatorV1 } from "@/hooks/strategies/useRelativeHighLowOscillatorV1";
import { useTriplePriceDeviationFilterV1 } from "@/hooks/strategies/useTriplePriceDeviationFilterV1";
import { useVolatilityBasedEmaV1 } from "@/hooks/strategies/useVolatilityBasedEmaV1";
import { useDashboard } from "@/lib/store";
import { Badge } from "@/components/ui/badge";

const columnHelper = createColumnHelper<IStrategyV2Generic>();
import updateLocale from "dayjs/plugin/updateLocale";
import { EScreenerStrategy } from "@/domain/constants";
import { ESFTableType } from "@/domain/enums/dashboard.enum";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "1 s",
    m: "1 m",
    mm: "%d m",
    h: "1 h",
    hh: "%d h",
    d: "1 d",
    dd: "%d d",
    M: "1M",
    MM: "%dM",
    y: "1y",
    yy: "%dy",
  },
});

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
    cell: (row) => {
      const value = row.getValue();
      const className = cn({
        "pl-2 text-left": true,
        "bg-green-800": row.row.original.direction === "BUY",
        "bg-red-800": row.row.original.direction === "SELL",
      });
      return <div className={className}>{value}</div>;
    },
  }),
  columnHelper.accessor("price", {
    header: "Price",
    cell: (row) => {
      const value = row.getValue();
      return <div>{value}</div>;
    },
    size: 70,
    minSize: 20,
  }),
  columnHelper.accessor("profit", {
    header: "% Chg",
    cell: (row) => {
      const value = row.getValue();
      return (
        <div
          className={cn({
            "text-green-500": value >= 0,
            "text-red-500": value < 0,
          })}
        >
          {value?.toFixed(2)}%
        </div>
      );
    },
    size: 70,
    minSize: 20,
  }),
  columnHelper.accessor("timestamp", {
    header: "Time",
    cell: (row) => {
      const value = row.getValue();
      return <div>{dayjs.utc(value).format("HH:mm")}</div>;
    },
    size: 50,
    minSize: 20,
  }),
  columnHelper.accessor("strategy", {
    header: "Name",
    enableSorting: false,
    cell: (row) => {
      const value = row.getValue();
      const strategyName =
        EScreenerStrategy[value as keyof typeof EScreenerStrategy] || value;
      return <div>{strategyName}</div>;
    },
  }),

  columnHelper.accessor("timeAgo", {
    header: "Time ago",
    enableSorting: false,
  }),
];

const rowClass: ISfTable<IRow<IStrategyV2Generic>>["rowClassCb"] = (row) =>
  cn({
    "border-neutral-500": true,
    "animate-pulse": row.timestamp > Date.now() - 10000,
    "bg-green-800": row.direction === "BUY" || row.name.includes("Altusher"),
    "bg-red-800": row.direction === "SELL",
  });

const prepareData = (
  altusherBB: IStrategyV2Generic[],
  altusherGaps: IStrategyV2Generic[],
  cmStrategyV1: IStrategyV2Generic[],
  cmStrategyV2: IStrategyV2Generic[],
  directionalIndexWithBollingerConfirmation: IStrategyV2Generic[],
  dualEmaBollinger: IStrategyV2Generic[],
  multiPeriodVolatilityFilter: IStrategyV2Generic[],
  relativeHighLowOscillator: IStrategyV2Generic[],
  triplePriceDeviationFilter: IStrategyV2Generic[],
  volatilityBasedEma: IStrategyV2Generic[],
  prices: Record<string, PriceData>,
) => {
  const altusherBBData = altusherBB.map((item) => {
    const currentPrice = prices[item.symbol]?.price ?? 0;
    return {
      ...item,
      currentPrice,
      strategy: "SCREENER_STRATEGY_ALTUSHER_BB1",
      profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
      timeAgo: dayjs.utc(item.timestamp).fromNow(),
    };
  });

  const altusherGapsData = altusherGaps.map((item) => {
    const currentPrice = prices[item.symbol]?.price ?? 0;
    return {
      ...item,
      currentPrice,
      profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
      timeAgo: dayjs.utc(item.timestamp).fromNow(),
      strategy: "SCREENER_STRATEGY_ALTUSHER_GAPS1",
    };
  });

  const cmStrategyV1Data = cmStrategyV1.map((item) => {
    const currentPrice = prices[item.symbol]?.price ?? 0;
    return {
      ...item,
      currentPrice,
      profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
      timeAgo: dayjs.utc(item.timestamp).fromNow(),
    };
  });

  const cmStrategyV2Data = cmStrategyV2.map((item) => {
    const currentPrice = prices[item.symbol]?.price ?? 0;
    return {
      ...item,
      currentPrice,
      profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
      timeAgo: dayjs.utc(item.timestamp).fromNow(),
    };
  });
  const directionalIndexWithBollingerConfirmationData =
    directionalIndexWithBollingerConfirmation.map((item) => {
      const currentPrice = prices[item.symbol]?.price ?? 0;
      return {
        ...item,
        currentPrice,
        profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
        timeAgo: dayjs.utc(item.timestamp).fromNow(),
      };
    });

  const dualEmaBollingerData = dualEmaBollinger.map((item) => {
    const currentPrice = prices[item.symbol]?.price ?? 0;
    return {
      ...item,
      currentPrice,
      profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
      timeAgo: dayjs.utc(item.timestamp).fromNow(),
    };
  });

  const multiPeriodVolatilityFilterData = multiPeriodVolatilityFilter.map(
    (item) => {
      const currentPrice = prices[item.symbol]?.price ?? 0;
      return {
        ...item,
        currentPrice,
        profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
        timeAgo: dayjs.utc(item.timestamp).fromNow(),
      };
    },
  );

  const relativeHighLowOscillatorData = relativeHighLowOscillator.map(
    (item) => {
      const currentPrice = prices[item.symbol]?.price ?? 0;
      return {
        ...item,
        currentPrice,
        profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
        timeAgo: dayjs.utc(item.timestamp).fromNow(),
        strategy: "SCREENER_STRATEGY_RELATIVE_HIGH_LOW_OSCILLATOR1",
      };
    },
  );

  const triplePriceDeviationFilterData = triplePriceDeviationFilter.map(
    (item) => {
      const currentPrice = prices[item.symbol]?.price ?? 0;
      return {
        ...item,
        currentPrice,
        profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
        timeAgo: dayjs.utc(item.timestamp).fromNow(),
        strategy: "SCREENER_STRATEGY_TRIPLE_PRICE_DEVIATION_FILTER1",
      };
    },
  );

  const volatilityBasedEmaData = volatilityBasedEma.map((item) => {
    const currentPrice = prices[item.symbol]?.price ?? 0;
    return {
      ...item,
      currentPrice,
      profit: currentPrice ? 100 - (item.price / currentPrice) * 100 : 0,
      timeAgo: dayjs.utc(item.timestamp).fromNow(),
      strategy: "SCREENER_STRATEGY_VOLATILITY_BASED_EMA1",
    };
  });

  return [
    ...altusherBBData,
    ...altusherGapsData,
    ...cmStrategyV1Data,
    ...cmStrategyV2Data,
    ...directionalIndexWithBollingerConfirmationData,
    ...dualEmaBollingerData,
    ...multiPeriodVolatilityFilterData,
    ...relativeHighLowOscillatorData,
    ...triplePriceDeviationFilterData,
    ...volatilityBasedEmaData,
  ];
};

function rowClassCb(
  row: IStrategyV2Generic,
  idx: number,
  table: Table<IRow>,
  sortingState: SortingState,
) {
  const meta = table.options.meta as { tableType: ESFTableType };
  const isHighlightable =
    meta?.tableType.includes(ESFTableType.unpinned) &&
    !meta?.tableType.includes(ESFTableType.chart);

  if (!isHighlightable) {
    return "";
  }

  const now = Date.now();
  const rowTime = row.timestamp;
  const isRecent = now - rowTime < 60000; // 60000ms = 1 minute

  if (!isRecent) {
    return "";
  }

  const isSortedDesc = sortingState.some((sort) => sort.desc);
  const color = isSortedDesc ? "bg-green-600/20" : "bg-red-600/20";
  return cn(color, "animate-pulse");
}

export function StrategiesPaneBody() {
  const { data: altusherBB, isLoading: isAltusherBBLoading } = useAltusherBB();
  const { data: altusherGaps, isLoading: isAltusherGapsLoading } =
    useAltusherGaps();
  const { data: cmStrategyV1, isLoading: isCmStrategyV1Loading } =
    useCmStrategyV1();
  const { data: cmStrategyV2, isLoading: isCmStrategyV2Loading } =
    useCmStrategyV2();
  const {
    data: directionalIndexWithBollingerConfirmation,
    isLoading: isDirectionalIndexWithBollingerConfirmationLoading,
  } = useDirectionalIndexWithBollingerConfirmationV1();
  const { data: dualEmaBollinger, isLoading: isDualEmaBollingerLoading } =
    useDualEmaBollingerV1();
  const {
    data: multiPeriodVolatilityFilter,
    isLoading: isMultiPeriodVolatilityFilterLoading,
  } = useMultiPeriodVolatilityFilterV1();
  const {
    data: relativeHighLowOscillator,
    isLoading: isRelativeHighLowOscillatorLoading,
  } = useRelativeHighLowOscillatorV1();
  const {
    data: triplePriceDeviationFilter,
    isLoading: isTriplePriceDeviationFilterLoading,
  } = useTriplePriceDeviationFilterV1();
  const { data: volatilityBasedEma, isLoading: isVolatilityBasedEmaLoading } =
    useVolatilityBasedEmaV1();

  const { data: prices, isLoading: isPricesLoading } = useBinancePrices();
  const { blacklist } = useDashboard();

  const isLoading =
    isAltusherBBLoading ||
    isAltusherGapsLoading ||
    isCmStrategyV1Loading ||
    isCmStrategyV2Loading ||
    isDirectionalIndexWithBollingerConfirmationLoading ||
    isDualEmaBollingerLoading ||
    isMultiPeriodVolatilityFilterLoading ||
    isRelativeHighLowOscillatorLoading ||
    isTriplePriceDeviationFilterLoading ||
    isVolatilityBasedEmaLoading ||
    isPricesLoading;

  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();

  const preparedData = useMemo(() => {
    if (
      !altusherBB ||
      !altusherGaps ||
      !cmStrategyV1 ||
      !cmStrategyV2 ||
      !directionalIndexWithBollingerConfirmation ||
      !dualEmaBollinger ||
      !multiPeriodVolatilityFilter ||
      !relativeHighLowOscillator ||
      !triplePriceDeviationFilter ||
      !volatilityBasedEma ||
      !prices
    ) {
      return [];
    }

    let data = prepareData(
      altusherBB,
      altusherGaps,
      cmStrategyV1,
      cmStrategyV2,
      directionalIndexWithBollingerConfirmation,
      dualEmaBollinger,
      multiPeriodVolatilityFilter,
      relativeHighLowOscillator,
      triplePriceDeviationFilter,
      volatilityBasedEma,
      prices,
    );

    data = data.sort((a, b) => b.timestamp - a.timestamp);

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IStrategyV2Generic>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [
    config,
    prices,
    altusherBB,
    altusherGaps,
    cmStrategyV1,
    cmStrategyV2,
    directionalIndexWithBollingerConfirmation,
    dualEmaBollinger,
    multiPeriodVolatilityFilter,
    relativeHighLowOscillator,
    triplePriceDeviationFilter,
    volatilityBasedEma,
    blacklist,
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={(...args) => rowClassCb(...args, config.sortingState)}
      />
    </>
  );
}
