//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const realizedVolalility = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Realized volalility",
    metainfo: {
      _metainfoVersion: 51,
      id: "realized-volalility@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Realized volalility",
      shortDescription: "[SF] RV",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          lengthInput: 10,
          measureInput: "Percent",
        },
      },
      styles: {
        metricPlot: {
          title: "RV",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "Percent",
          options: ["USD", "Percent"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [measureInput, lengthInput]: [string, number] = [
          this._input(0),
          this._input(1),
        ];

        // Data parsing
        const high: number = PineJS.Std.high(this._context);
        const low: number = PineJS.Std.low(this._context);

        // Calculation
        const candleSize: number = high - low;
        const candleSizeSeries: any = this._context.new_var(candleSize);
        let realizedVol: number = PineJS.Std.sum(
          candleSizeSeries,
          lengthInput,
          this._context,
        );
        if (measureInput === "Percent") {
          const hlc3: number = PineJS.Std.hlc3(this._context);
          realizedVol /= hlc3 / 100;
        }

        // Return
        return [realizedVol];
      };
    },
  };
};
