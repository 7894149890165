//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import { GetPriceSeries } from "@/lib/indicators/indicators-common-functions";

export const normalizedMomentumIndicator = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Normalized Momentum Indicator",
    metainfo: {
      _metainfoVersion: 51,
      id: "normalized-momentum-indicator@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Normalized momentum indicator",
      shortDescription: "[SF] NMI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          lengthInput: 10,
        },
      },
      styles: {
        metricPlot: {
          title: "NMI",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, lengthInput]: [string, number] = [
          this._input(0),
          this._input(1),
        ];

        // Market data parsing
        const sourceSeries: any = GetPriceSeries(
          PineJS,
          sourceInput,
          this._context,
        );

        // Calculation
        const atr: number = PineJS.Std.atr(lengthInput, this._context);
        const nmi: number =
          (sourceSeries - sourceSeries.get(lengthInput)) / atr;

        // Return
        return [nmi];
      };
    },
  };
};
