//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const orderFlowIntesity = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Order flow intensity",
    metainfo: {
      _metainfoVersion: 51,
      id: "order-flow-intesity@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Order flow intensity",
      shortDescription: "[SF] Order flow intensity",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "intensityPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "intensityPlotColorer",
          type: StudyPlotType.Colorer,
          target: "intensityPlot",
          palette: "intensityPalette",
        },
        {
          id: "dayHighPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        intensityPalette: {
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
          },
          colors: {
            0: { name: "Color 1" },
            1: { name: "Color 2" },
            2: { name: "Color 3" },
            3: { name: "Color 4" },
            4: { name: "Color 5" },
            5: { name: "Color 6" },
            6: { name: "Color 7" },
            7: { name: "Color 8" },
            8: { name: "Color 9" },
            9: { name: "Color 10" },
          },
        },
      },
      defaults: {
        palettes: {
          intensityPalette: {
            colors: {
              0: { color: "#211CFF", width: 1, style: 0 },
              1: { color: "#1871FF", width: 1, style: 0 },
              2: { color: "#18BAFF", width: 1, style: 0 },
              3: { color: "#18FFB5", width: 1, style: 0 },
              4: { color: "#18FF42", width: 1, style: 0 },
              5: { color: "#63FF18", width: 1, style: 0 },
              6: { color: "#D6FF18", width: 1, style: 0 },
              7: { color: "#FFCF18", width: 1, style: 0 },
              8: { color: "#FF7118", width: 1, style: 0 },
              9: { color: "#FF2821", width: 1, style: 0 },
            },
          },
        },
        styles: {
          intensityPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            transparency: 1,
            color: "rgb(149, 152, 161)",
          },
          intensityPlotColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            transparency: 1,
            color: "rgb(149, 152, 161)",
          },
          dayHighPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            display: 3,
            transparency: 1,
            color: "rgb(255, 255, 255)",
          },
        },
        inputs: {
          symbolInput: "",
          sourceInput: "Tape",
          lengthInput: 240,
        },
      },
      styles: {
        intensityPlot: {
          title: "Order flow intensity",
          histogramBase: 0,
        },
        dayHighPlot: {
          title: "Day high",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "sourceInput",
          name: "Source",
          defval: "Tape",
          options: ["Tape", "Average trade size", "Volume"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      const CONSTANTS = {
        USDT_SUFFIX: "USDT",
        SESSION_PREFIX: '={"session":"regular","symbol":"',
        SF_VOLUME_SUFFIX: "#SF_VOLUME",
        MINUTES_IN_DAY: 1440,
        Z_SCORE_THRESHOLDS: [
          { value: 5.00095, color: 9 },
          { value: 4, color: 8 },
          { value: 3.03323, color: 7 },
          { value: 2.5, color: 6 },
          { value: 1.83975, color: 5 },
          { value: 1.11586, color: 4 },
          { value: 0.67681, color: 3 },
          { value: 0.4105, color: 2 },
          { value: 0, color: 1 },
        ],
      } as const;

      // Helper function to get symbol data
      const GetSymbolData = (
        context: any,
        symbolIndex: number,
      ): { time: any; data: any } => {
        context.select_sym(symbolIndex);
        return {
          time: context.new_var(context.symbol.time),
          data: context.new_var(
            symbolIndex === 2
              ? PineJS.Std.open(context)
              : PineJS.Std.volume(context),
          ),
        };
      };

      // Function to determine color based on z-score
      const GetColorByZScore = (zScore: number): number => {
        const threshold = CONSTANTS.Z_SCORE_THRESHOLDS.find(
          (t) => zScore >= t.value,
        );
        return threshold?.color ?? 0;
      };

      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const tapeSymbol: string = `${ParseSymbol(symbol)}${CONSTANTS.SF_VOLUME_SUFFIX}`;
        this._context.new_sym(tapeSymbol, period);
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number, number] {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, lengthInput]: [string, number] = [
          this._input(1),
          this._input(2),
        ];

        // Get data source
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        let source: number;
        if (sourceInput === "Tape") {
          const tapeData = GetSymbolData(this._context, 2);
          this._context.select_sym(0);
          source = tapeData.data.adopt(tapeData.time, mainSymbolTime, 0);
        } else if (sourceInput === "Average trade size") {
          const volumeData = GetSymbolData(this._context, 1);
          const tapeData = GetSymbolData(this._context, 2);
          this._context.select_sym(0);
          source =
            volumeData.data.adopt(volumeData.time, mainSymbolTime, 0) /
            tapeData.data.adopt(tapeData.time, mainSymbolTime, 0);
        } else {
          const volumeData = GetSymbolData(this._context, 1);
          this._context.select_sym(0);
          source = volumeData.data.adopt(volumeData.time, mainSymbolTime, 0);
        }

        const sourceSeries: any = this._context.new_var(source);

        // Calculations
        const avg: number =
          PineJS.Std.sum(sourceSeries, lengthInput, this._context) /
          lengthInput;
        const stdev: number = PineJS.Std.stdev(
          sourceSeries,
          lengthInput,
          this._context,
        );
        const zScore: number = (source - avg) / stdev;

        // Calculate dayHigh
        let dayHigh: number = NaN;
        if (PineJS.Std.isintraday(this._context)) {
          const length: number =
            CONSTANTS.MINUTES_IN_DAY /
            parseInt(PineJS.Std.period(this._context));
          dayHigh = PineJS.Std.highest(sourceSeries, length, this._context);
        }

        // Return values
        return [source, GetColorByZScore(zScore), dayHigh];
      };
    },
  };
};
