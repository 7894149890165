import { IHftActivities } from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export function useHFTActivity() {
  const handler = useCallback(
    (oldData: IHftActivities = {}, newData: IHftActivities) => ({
      ...oldData,
      ...newData,
    }),
    [],
  );

  return useGenericSocket<IHftActivities, IHftActivities>(
    SocketTopics.hftActivity,
    handler,
  );
}
