import {
  IPowerTrades,
  MarketPower,
} from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export function useMarketPower() {
  const handler = useCallback((oldData: MarketPower, newData: MarketPower) => {
    return newData;
  }, []);

  return useGenericSocket<MarketPower, MarketPower>(
    SocketTopics.marketPower,
    handler,
  );
}
