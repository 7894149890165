//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const spotPerpetualsTimeDominance = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Spot perpetuals time dominance",
    metainfo: {
      _metainfoVersion: 51,
      id: "spot-perpetuals-time-dominance@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spot perpetuals time dominance",
      shortDescription: "[SF] Spot perp TD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "mxsPerpPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "mxsSpotPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "sfsPerpPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "sfsSpotPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},

        styles: {
          mxsPerpPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(13, 157, 219, 1)",
          },
          mxsSpotPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(255, 160, 40, 1)",
          },
          sfsPerpPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(13, 157, 219, .25)",
          },
          sfsSpotPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(255, 160, 40, .25)",
          },
        },
        precision: 4,
        inputs: {
          symbolInput: "",
        },
      },
      styles: {
        mxsPerpPlot: {
          title: "Matrix perpetuals",
          histogramBase: 0,
        },
        mxsSpotPlot: {
          title: "Matrix spot",
          histogramBase: 0,
        },
        sfsPerpPlot: {
          title: "Perpetuals",
          histogramBase: 0,
        },
        sfsSpotPlot: {
          title: "Spot",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const period: number = PineJS.Std.period(this._context);

        const mxsSymbol: string = `${baseSymbol}#MATRIXSPREAD`;
        this._context.new_sym(mxsSymbol, period);

        const sfsSymbol: string = `${baseSymbol}#SPOTFUTURESPREAD`;
        this._context.new_sym(sfsSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Parsing market data
        this._context.select_sym(1);
        const mxsTime: any = this._context.new_var(this._context.symbol.time);
        const mxsPerpArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const mxsSpotArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );

        this._context.select_sym(2);
        const sfsSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const sfsPerpArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const sfsSpotArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        const mxsPerp: number = mxsPerpArray.adopt(mxsTime, mainSymbolTime, 1);
        const mxsSpot: number = mxsSpotArray.adopt(mxsTime, mainSymbolTime, 1);
        const sfsPerp: number = sfsPerpArray.adopt(
          sfsSymbolTime,
          mainSymbolTime,
          1,
        );
        const sfsSpot: number = sfsSpotArray.adopt(
          sfsSymbolTime,
          mainSymbolTime,
          1,
        );

        // Returning results
        return [mxsPerp, mxsSpot, sfsPerp, sfsSpot];
      };
    },
  };
};
