//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import {
  AdjustPrice,
  AccessCheck,
} from "@/lib/indicators/indicators-common-functions";

enum PlotSymbolSize {
  Auto = "auto",
  Tiny = "tiny",
  Small = "small",
  Normal = "normal",
  Large = "large",
  Huge = "huge",
}

enum MarkLocation {
  AboveBar = "AboveBar",
  BelowBar = "BelowBar",
  Top = "Top",
  Bottom = "Bottom",
  Right = "Right",
  Left = "Left",
  Absolute = "Absolute",
  AbsoluteUp = "AbsoluteUp",
  AbsoluteDown = "AbsoluteDown",
}

export const hftActivity = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "HFT activity",
    metainfo: {
      _metainfoVersion: 51,
      id: "hft-activity@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] HFT activity",
      shortDescription: "[SF] HFT activity",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "tinyShapePlot", type: StudyPlotType.Shapes },
        {
          id: "tinyShapeColorer",
          type: StudyPlotType.Colorer,
          target: "tinyShapePlot",
          palette: "tinyShapePalette",
        },
        { id: "smallShapePlot", type: StudyPlotType.Shapes },
        {
          id: "smallShapeColorer",
          type: StudyPlotType.Colorer,
          target: "smallShapePlot",
          palette: "smallShapePalette",
        },
        { id: "normalShapePlot", type: StudyPlotType.Shapes },
        {
          id: "normalShapeColorer",
          type: StudyPlotType.Colorer,
          target: "normalShapePlot",
          palette: "normalShapePalette",
        },
        { id: "largeShapePlot", type: StudyPlotType.Shapes },
        {
          id: "largeShapeColorer",
          type: StudyPlotType.Colorer,
          target: "largeShapePlot",
          palette: "largeShapePalette",
        },
        { id: "hugeShapePlot", type: StudyPlotType.Shapes },
        {
          id: "hugeShapeColorer",
          type: StudyPlotType.Colorer,
          target: "hugeShapePlot",
          palette: "hugeShapePalette",
        },
      ],
      filledAreas: [],
      palettes: {
        tinyShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Sell activity" },
            1: { name: "Buy activity" },
          },
        },
        smallShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Sell activity" },
            1: { name: "Buy activity" },
          },
        },
        normalShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Sell activity" },
            1: { name: "Buy activity" },
          },
        },
        largeShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Sell activity" },
            1: { name: "Buy activity" },
          },
        },
        hugeShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Sell activity" },
            1: { name: "Buy activity" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {},
        palettes: {
          tinyShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          smallShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          normalShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          largeShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          hugeShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          tinyShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_square",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          smallShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_square",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          normalShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_square",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          largeShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_square",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          hugeShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_square",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          activityFilter: 1,
        },
      },
      styles: {
        tinyShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Tiny,
          title: "Tiny activity",
        },
        smallShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Small,
          title: "Small activity",
        },
        normalShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Normal,
          title: "Normal activity",
        },
        largeShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Large,
          title: "Large activity",
        },
        hugeShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Huge,
          title: "Huge activity",
        },
      },
      inputs: [
        {
          id: "activityFilter",
          name: "Activity filter",
          defval: 1,
          max: 5,
          min: 1,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const hftSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#HFTACTIVITY`;
        this._context.new_sym(hftSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const filterInput: number = this._input(0);

        // Market data parsing
        this._context.select_sym(1);
        const hftTime: any = this._context.new_var(this._context.symbol.time);
        const hftPriceArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const hftWeightArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const hftSideArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        let hftPrice: number = hftPriceArray.adopt(
          hftTime,
          mainSymbolTimeSeries,
          1,
        );
        const hftWeight: number = hftWeightArray.adopt(
          hftTime,
          mainSymbolTimeSeries,
          1,
        );
        const hftSide: number = hftSideArray.adopt(
          hftTime,
          mainSymbolTimeSeries,
          1,
        );

        hftPrice = AdjustPrice(PineJS, this._context, hftPrice);

        //Calculations
        let tinyShapePrice: number,
          smallShapePrice: number,
          normalShapePrice: number,
          largeShapePrice: number,
          hugeShapePrice: number;

        if (hftWeight >= filterInput) {
          switch (true) {
            case hftWeight === 1:
              tinyShapePrice = hftPrice;
              break;
            case hftWeight === 2:
              smallShapePrice = hftPrice;
              break;
            case hftWeight === 3:
              normalShapePrice = hftPrice;
              break;
            case hftWeight === 4:
              largeShapePrice = hftPrice;
              break;
            case hftWeight === 5:
              hugeShapePrice = hftPrice;
              break;
          }
        }

        const access: boolean = true;

        const shouldShowData: any = AccessCheck(
          PineJS,
          "Microstructure",
          access,
          this._context,
        );

        if (shouldShowData) {
          return [
            tinyShapePrice,
            hftSide,
            smallShapePrice,
            hftSide,
            normalShapePrice,
            hftSide,
            largeShapePrice,
            hftSide,
            hugeShapePrice,
            hftSide,
          ];
        } else {
          return [NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN];
        }
      };
    },
  };
};
