import { BINANCE_RESOLUTION_MAP_REVERSE } from "@/lib/datafeed/config.ts";
import { ResolutionString } from "@tradingView/charting_library";
import {
  EFilterType,
  ENumberComparisonType,
  EWidgetType,
} from "@/domain/enums/dashboard.enum.ts";
import {
  IPaneConfig,
  IWidgetOptions,
} from "@/domain/interfaces/dashboard.interface.ts";
import { merge } from "lodash";

export const colors = [
  "#B2182C",
  "#FFA028",
  "#3CA64B",
  "#047857",
  "#0d9ddb",
  "#4338ca",
  "#6d28d9",
  "#a21caf",
  "#be185d",
];

export const MAX_CHARTS = 10;

export const MAX_LAYOUTS_PER_PLAN: Record<string, number> = {
  BASIC: 1,
  TRADER: 3,
  PRO: 10,
  EXPERT: 99,
};

export const MAX_WIDGETS_PER_PLAN: Record<string, number> = {
  BASIC: 4,
  TRADER: 8,
  PRO: 16,
  EXPERT: 99,
};

export const WIDGET_TYPES_PER_PLAN: Record<string, EWidgetType[]> = {
  BASIC: [
    EWidgetType.controlPanel,
    EWidgetType.chart,
    EWidgetType.adtvScreener,
    EWidgetType.priceScreener,
    EWidgetType.oiScreener,
    EWidgetType.volumeScreener,
    EWidgetType.deltaScreener,
    EWidgetType.liquidationsScanner,
    EWidgetType.classicSma,
    EWidgetType.classicRsi,
    EWidgetType.classicAdx,
    EWidgetType.classicPpo,
    EWidgetType.classicNatr,
    EWidgetType.classicCmo,
    EWidgetType.classicNmi,
    EWidgetType.classicMacd,
    EWidgetType.classicCci,
    EWidgetType.classicMfi,
    EWidgetType.classicRoc,
    EWidgetType.classicKco,
    EWidgetType.classicWilliamsPrcR,
    EWidgetType.classicBollingerPrc,
    EWidgetType.classicStochastic,
  ],
  TRADER: [
    EWidgetType.cascadeLevels,
    EWidgetType.stopKiller,
    EWidgetType.powerTradesScreener,
    EWidgetType.htfActivityScreener,
    EWidgetType.spotFuturesSpread,
    EWidgetType.cryptoDriftScreener,
    EWidgetType.strategies,
    EWidgetType.sizeHighlighter,
    EWidgetType.spoofLayer,
    EWidgetType.formations,
    EWidgetType.patternPredict,
    EWidgetType.candlePattern,
    EWidgetType.smartHiLow,
    EWidgetType.adf,
  ],
  PRO: [
    EWidgetType.activityDetectorScreener,
    EWidgetType.marketRatioScreener,
    EWidgetType.manipulationMonitor,
    EWidgetType.spreadDominationScreener,
    EWidgetType.predictLiquidation,
    EWidgetType.pairsScanner,
    EWidgetType.trendAssessmentScreener,
  ],
  EXPERT: [
    EWidgetType.marketPower,
    EWidgetType.retailPower,
    EWidgetType.activityDetectorTdScreener,
  ],
};

export const CANDLE_PATTERN_FILTERS = [];

export const GLOBAL_FILTERS = [];

export const dynamicColumns: Record<
  string,
  {
    title: string;
    group: string;
    availableTimeFrames?: string[];
    timeFrameTitles?: string[];
  }
> = {
  priceChg: {
    title: "Price",
    group: "Technical",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour %CHG", "Day %CHG", "Week %CHG", "Month %CHG"],
  },
  openInterest: {
    title: "Open Interest",
    group: "Technical",
  },
  manipulationMonitor: {
    title: "Manipulation Monitor",
    group: "Microstructure",
    availableTimeFrames: ["corr", "corrV"],
    timeFrameTitles: ["Price", "Volume"],
  },
  spotFuturesSpread: {
    title: "Spot Futures Spread",
    group: "Microstructure",
    availableTimeFrames: ["funding", "spread"],
    timeFrameTitles: ["Funding", "Spread"],
  },
  openInterestChg: {
    title: "Open Interest Chg",
    group: "Technical",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour %CHG", "Day %CHG", "Week %CHG", "Month %CHG"],
  },
  volumeChg: {
    title: "Volume",
    group: "Technical",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour %CHG", "Day %CHG", "Week %CHG", "Month %CHG"],
  },
  buySellRatioChg: {
    title: "Buy/Sell Ratio",
    group: "Microstructure",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour", "Day", "Week", "Month"],
  },
  marketRatio: {
    title: "Market Ratio",
    group: "Correlation",
    availableTimeFrames: ["1M", "1d", "1w"],
    timeFrameTitles: ["Month", "Day", "Week"],
  },
  funding: {
    title: "Funding",
    group: "Technical",
  },
  cryptoDrift: {
    title: "Crypto Drift",
    group: "Microstructure",
  },
  activityDetector: {
    title: "Activity Detector",
    group: "Microstructure",
  },
  marketPower: {
    title: "Market Power",
    group: "Microstructure",
  },
  retailPower: {
    title: "Retail Power",
    group: "Microstructure",
  },
};

const defaultConfig: Pick<
  IPaneConfig,
  "interval" | "color" | "numberOfCharts" | "availableIntervals"
> = {
  color: colors[0],
  interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
  availableIntervals: [
    BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["3m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
  ],

  numberOfCharts: 3,
};

export const ROLE_HIERARCHY: Record<string, string[]> = {
  EXPERT: ["PRO", "TRADER", "BASIC"],
  PRO: ["TRADER", "BASIC"],
  TRADER: ["BASIC"],
  BASIC: [],
};

export enum PLAN {
  BASIC = "BASIC",
  TRADER = "TRADER",
  PRO = "PRO",
  EXPERT = "EXPERT",
  TBD = "SOON",
}

export function getAccessibleRoles(role: string): string[] {
  const roles = [role, ...(ROLE_HIERARCHY[role] || [])];
  return roles;
}

export function hasAccessToWidget(
  userRole: string,
  widgetType: EWidgetType,
): boolean {
  const role = userRole.toUpperCase();

  // Get all accessible roles based on hierarchy
  const accessibleRoles = [role, ...(ROLE_HIERARCHY[role] || [])];

  // Check if widget type exists in any of the accessible roles
  return accessibleRoles.some((r) =>
    WIDGET_TYPES_PER_PLAN[r]?.includes(widgetType),
  );
}

export const widgets: Record<EWidgetType, IWidgetOptions> = {
  [EWidgetType.chart]: {
    component: EWidgetType.chart,
    name: "Chart",
    config: {
      plan: PLAN.BASIC,
      symbol: "BTCUSDT",
      color: colors[0],
    },
  },
  [EWidgetType.spotFuturesSpread]: {
    component: EWidgetType.spotFuturesSpread,
    name: "Spot Perp Spread",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      indicatorTitle: "[SFM] Spot perpetuals dominance",
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "Spread",
          filterByField: "spread",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Funding",
          filterByField: "funding",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "funding",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.marketPower]: {
    component: EWidgetType.marketPower,
    name: "Market Power",
    config: {
      ...defaultConfig,
      plan: PLAN.EXPERT,
      indicatorTitle: "[SFM] Market power highlighter",
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicSma]: {
    component: EWidgetType.classicSma,
    name: "SMA",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicRsi]: {
    component: EWidgetType.classicRsi,
    name: "RSI",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicAdx]: {
    component: EWidgetType.classicAdx,
    name: "ADX",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicPpo]: {
    component: EWidgetType.classicPpo,
    name: "PPO",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicNatr]: {
    component: EWidgetType.classicNatr,
    name: "NATR",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicMacd]: {
    component: EWidgetType.classicMacd,
    name: "Macd",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicCmo]: {
    component: EWidgetType.classicCmo,
    name: "CMO",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicNmi]: {
    component: EWidgetType.classicNmi,
    name: "NMI",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicCci]: {
    component: EWidgetType.classicCci,
    name: "CCI",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicKco]: {
    component: EWidgetType.classicKco,
    name: "KCO",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicRoc]: {
    component: EWidgetType.classicRoc,
    name: "ROC",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicMfi]: {
    component: EWidgetType.classicMfi,
    name: "MFI",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicWilliamsPrcR]: {
    component: EWidgetType.classicWilliamsPrcR,
    name: "Williams %R",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicBollingerPrc]: {
    component: EWidgetType.classicBollingerPrc,
    name: "Bollinger %R",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.classicStochastic]: {
    component: EWidgetType.classicStochastic,
    name: "Stochastic",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      plan: PLAN.BASIC,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.retailPower]: {
    component: EWidgetType.retailPower,
    name: "Retail Power",
    config: {
      ...defaultConfig,
      plan: PLAN.EXPERT,
      indicatorTitle: "[SFM] Retail power highlighter",
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.formations]: {
    component: EWidgetType.formations,
    name: "Formations",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
      ],
      filters: [
        {
          type: EFilterType.select,
          title: "Type",
          filterByField: "type",
          options: [
            { label: "Ascending Triangle Up", value: "ASCENDING_TRIANGLE_UP" },
            {
              label: "Ascending Triangle Down",
              value: "ASCENDING_TRIANGLE_DOWN",
            },
            {
              label: "Descending Triangle Up",
              value: "DESCENDING_TRIANGLE_UP",
            },
            {
              label: "Descending Triangle Down",
              value: "DESCENDING_TRIANGLE_DOWN",
            },
            { label: "Triangle Up", value: "TRIANGLE_UP" },
            { label: "Triangle Down", value: "TRIANGLE_DOWN" },
            { label: "Horizontal Channel", value: "HORIZONTAL_CHANNEL" },
            { label: "Ascending Channel", value: "ASCENDING_CHANNEL" },
            { label: "Descending Channel", value: "DESCENDING_CHANNEL" },
            { label: "Ascending Wedge Up", value: "ASCENDING_WEDGE_UP" },
            { label: "Ascending Wedge Down", value: "ASCENDING_WEDGE_DOWN" },
            { label: "Descending Wedge Up", value: "DESCENDING_WEDGE_UP" },
            { label: "Descending Wedge Down", value: "DESCENDING_WEDGE_DOWN" },
            { label: "Double Down", value: "DOUBLE_DOWN" },
            { label: "Double Up", value: "DOUBLE_UP" },
            { label: "Triple Down", value: "TRIPLE_DOWN" },
            { label: "Triple Up", value: "TRIPLE_UP" },
            { label: "Head and Shoulders Up", value: "HEAD_AND_SHOULDERS_UP" },
            {
              label: "Head and Shoulders Down",
              value: "HEAD_AND_SHOULDERS_DOWN",
            },
          ],
        },
      ],
      sortingState: [
        {
          id: "duration",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.candlePattern]: {
    component: EWidgetType.candlePattern,
    name: "Candle Pattern",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["3m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["2h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["6h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["8h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["12h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
        // BINANCE_RESOLUTION_MAP_REVERSE["1w"] as ResolutionString,
        // BINANCE_RESOLUTION_MAP_REVERSE["1M"] as ResolutionString,
      ],
      filters: [
        {
          type: EFilterType.select,
          title: "Pattern",
          filterByField: "pattern",
          options: [
            // Basic Doji Patterns
            { label: "Doji Star", value: "DOJI_STAR" },
            { label: "Gravestone Doji", value: "DOJI_GRAVESTONE" },
            { label: "Dragonfly Doji", value: "DOJI_DRAGONFLY" },
            { label: "Rickshaw Doji", value: "DOJI_RICKSHAW" },
            { label: "Four Price Doji", value: "DOJI_FOUR_PRICE" },
            { label: "Doji Star (Bullish)", value: "DOJI_STAR_BULLISH" },
            { label: "Doji Star (Bearish)", value: "DOJI_STAR_BEARISH" },

            // Pin Bar and Hammer Patterns
            { label: "Pin Bar (Bullish)", value: "PIN_BAR_BULLISH" },
            { label: "Pin Bar (Bearish)", value: "PIN_BAR_BEARISH" },
            { label: "Hammer (Bullish)", value: "HAMMER_BULLISH" },
            { label: "Hanging Man (Bearish)", value: "HANGING_MAN_BEARISH" },
            {
              label: "Inverted Hammer (Bearish)",
              value: "INVERTED_HAMMER_BEARISH",
            },
            {
              label: "Shooting Star (Bearish)",
              value: "SHOOTING_STAR_BEARISH",
            },

            // Marubozu Patterns
            { label: "Marubozu (Bullish)", value: "MARUBOZU_BULLISH" },
            { label: "Marubozu (Bearish)", value: "MARUBOZU_BEARISH" },

            // Engulfing and Harami Patterns
            { label: "Engulfing (Bullish)", value: "ENGULFING_BULLISH" },
            { label: "Engulfing (Bearish)", value: "ENGULFING_BEARISH" },
            { label: "Harami (Bullish)", value: "HARAMI_BULLISH" },
            { label: "Harami (Bearish)", value: "HARAMI_BEARISH" },
            {
              label: "Full Size Engulfing (Bullish)",
              value: "ENGULFING_FULL_SIZE_BULLISH",
            },
            {
              label: "Full Size Engulfing (Bearish)",
              value: "ENGULFING_FULL_SIZE_BEARISH",
            },
            {
              label: "Full Size Harami (Bullish)",
              value: "HARAMI_FULL_SIZE_BULLISH",
            },
            {
              label: "Full Size Harami (Bearish)",
              value: "HARAMI_FULL_SIZE_BEARISH",
            },

            // Multi-Candle Patterns
            {
              label: "Three White Soldiers (Bullish)",
              value: "THREE_WHITE_SOLDIERS_BULLISH",
            },
            {
              label: "Three Black Crows (Bearish)",
              value: "THREE_BLACK_CROWS_BEARISH",
            },
            { label: "Morning Star (Bullish)", value: "MORNING_STAR_BULLISH" },
            {
              label: "Dark Cloud Cover (Bearish)",
              value: "DARK_CLOUD_COVER_BEARISH",
            },
            {
              label: "Three Rising Method (Bullish)",
              value: "THREE_RISING_METHOD_BULLISH",
            },
            {
              label: "Three Falling Method (Bearish)",
              value: "THREE_FALLING_METHOD_BEARISH",
            },
            { label: "Mat Hold (Bullish)", value: "MAT_HOLD_BULLISH" },

            // Special Patterns
            {
              label: "Clearance in the Clouds (Bullish)",
              value: "CLEARANCE_IN_THE_CLOUDS_BULLISH",
            },
            {
              label: "Abandoned Baby (Bullish)",
              value: "ABANDONED_BABY_BULLISH",
            },
            {
              label: "Abandoned Baby (Bearish)",
              value: "ABANDONED_BABY_BEARISH",
            },
            { label: "Tweezer Top (Bullish)", value: "TWEEZER_TOP_BULLISH" },
            {
              label: "Tweezer Bottom (Bearish)",
              value: "TWEEZER_BOTTOM_BEARISH",
            },
            {
              label: "Tasuki Gap Upside (Bullish)",
              value: "TASUKI_GAP_UPSIDE_BULLISH",
            },
            {
              label: "Tasuki Gap Downside (Bullish)",
              value: "TASUKI_GAP_DOWNSIDE_BULLISH",
            },
          ],
        },
      ],
      sortingState: [
        {
          id: "symbol",
          desc: false,
        },
      ],
    },
  },
  [EWidgetType.patternPredict]: {
    component: EWidgetType.patternPredict,
    name: "Pattern Predict",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
      ],
      filters: [
        {
          type: EFilterType.select,
          title: "Name",
          filterByField: "name",
          options: [
            { label: "Ascending Triangle Up", value: "ASCENDING_TRIANGLE_UP" },
            {
              label: "Ascending Triangle Down",
              value: "ASCENDING_TRIANGLE_DOWN",
            },
            {
              label: "Descending Triangle Up",
              value: "DESCENDING_TRIANGLE_UP",
            },
            {
              label: "Descending Triangle Down",
              value: "DESCENDING_TRIANGLE_DOWN",
            },
            { label: "Triangle Up", value: "TRIANGLE_UP" },
            { label: "Triangle Down", value: "TRIANGLE_DOWN" },
            { label: "Horizontal Channel", value: "HORIZONTAL_CHANNEL" },
            { label: "Ascending Channel", value: "ASCENDING_CHANNEL" },
            { label: "Descending Channel", value: "DESCENDING_CHANNEL" },
            { label: "Ascending Wedge Up", value: "ASCENDING_WEDGE_UP" },
            { label: "Ascending Wedge Down", value: "ASCENDING_WEDGE_DOWN" },
            { label: "Descending Wedge Up", value: "DESCENDING_WEDGE_UP" },
            { label: "Descending Wedge Down", value: "DESCENDING_WEDGE_DOWN" },
            { label: "Double Down", value: "DOUBLE_DOWN" },
            { label: "Double Up", value: "DOUBLE_UP" },
            { label: "Triple Down", value: "TRIPLE_DOWN" },
            { label: "Triple Up", value: "TRIPLE_UP" },
            { label: "Head and Shoulders Up", value: "HEAD_AND_SHOULDERS_UP" },
            {
              label: "Head and Shoulders Down",
              value: "HEAD_AND_SHOULDERS_DOWN",
            },
            // Candlestick Patterns
            { label: "Two Crows Down", value: "TWO_CROWS_DOWN" },
            {
              label: "Three Black Crows Down",
              value: "THREE_BLACK_CROWS_DOWN",
            },
            { label: "Three Inside Up", value: "THREE_INSIDE_UP" },
            { label: "Three Inside Down", value: "THREE_INSIDE_DOWN" },
            { label: "Three Line Strike Up", value: "THREE_LINE_STRIKE_UP" },
            {
              label: "Three Line Strike Down",
              value: "THREE_LINE_STRIKE_DOWN",
            },
            { label: "Three Outside Up", value: "THREE_OUTSIDE_UP" },
            { label: "Three Outside Down", value: "THREE_OUTSIDE_DOWN" },
            {
              label: "Three Stars in South Up",
              value: "THREE_STARS_IN_SOUTH_UP",
            },
            {
              label: "Three White Soldiers Up",
              value: "THREE_WHITE_SOLDIERS_UP",
            },
            { label: "Advance Block Down", value: "ADVANCE_BLOCK_DOWN" },
            { label: "Belt Hold Up", value: "BELT_HOLD_UP" },
            { label: "Belt Hold Down", value: "BELT_HOLD_DOWN" },
            { label: "Break Away Up", value: "BREAK_AWAY_UP" },
            { label: "Break Away Down", value: "BREAK_AWAY_DOWN" },
            { label: "Closing Marubozu Up", value: "CLOSING_MARUBOZU_UP" },
            { label: "Closing Marubozu Down", value: "CLOSING_MARUBOZU_DOWN" },
            {
              label: "Conceal Baby Swallow Up",
              value: "CONCEAL_BABY_SWALL_UP",
            },
            { label: "Counter Attack Up", value: "COUNTER_ATTACK_UP" },
            { label: "Counter Attack Down", value: "COUNTER_ATTACK_DOWN" },
            { label: "Doji Up", value: "DOJI_UP" },
            { label: "Doji Star Up", value: "DOJI_STAR_UP" },
            { label: "Doji Star Down", value: "DOJI_STAR_DOWN" },
            { label: "Dragon Fly Doji Up", value: "DRAGON_FLY_DOJI_UP" },
            { label: "Engulfing Up", value: "ENGULFING_UP" },
            {
              label: "Gap Side Side White Up",
              value: "GAP_SIDE_SIDE_WHITE_UP",
            },
            {
              label: "Gap Side Side White Down",
              value: "GAP_SIDE_SIDE_WHITE_DOWN",
            },
            { label: "Gravestone Doji Up", value: "GRAVESTONE_DOJI_UP" },
            { label: "Hammer Up", value: "HAMMER_UP" },
            { label: "Hanging Man Down", value: "HANGING_MAN_DOWN" },
            { label: "Harami Up", value: "HARAMI_UP" },
            { label: "Harami Down", value: "HARAMI_DOWN" },
            { label: "Harami Cross Up", value: "HARAMI_CROSS_UP" },
            { label: "Harami Cross Down", value: "HARAMI_CROSS_DOWN" },
            { label: "High Wave Up", value: "HIGH_WAVE_UP" },
            { label: "High Wave Down", value: "HIGH_WAVE_DOWN" },
            { label: "Hikkake Up", value: "HIKKAKE_UP" },
            { label: "Hikkake Down", value: "HIKKAKE_DOWN" },
            { label: "Hikkake Mod Up", value: "HIKKAKE_MOD_UP" },
            { label: "Hikkake Mod Down", value: "HIKKAKE_MOD_DOWN" },
            { label: "Homing Pigeon Up", value: "HOMING_PIGEON_UP" },
            {
              label: "Identical Three Crows Down",
              value: "IDENTICAL_THREE_CROWS_DOWN",
            },
            { label: "Inneck Down", value: "INNECK_DOWN" },
            { label: "Inverted Hammer Up", value: "INVERTED_HAMMER_UP" },
            { label: "Kicking Up", value: "KICKING_UP" },
            { label: "Kicking Down", value: "KICKING_DOWN" },
            { label: "Kicking By Length Up", value: "KICKING_BY_LENGTH_UP" },
            {
              label: "Kicking By Length Down",
              value: "KICKING_BY_LENGTH_DOWN",
            },
            { label: "Ladder Bottom Up", value: "LADDER_BOTTOM_UP" },
            { label: "Long Legged Doji Up", value: "LONG_LEGGED_DOJI_UP" },
            { label: "Long Line Up", value: "LONG_LINE_UP" },
            { label: "Long Line Down", value: "LONG_LINE_DOWN" },
            { label: "Marubozu Up", value: "MARUBOZU_UP" },
            { label: "Marubozu Down", value: "MARUBOZU_DOWN" },
            { label: "Matching Low Up", value: "MATCHING_LOW_UP" },
            { label: "On Neck Up", value: "ONNECK_UP" },
            { label: "Piercing Up", value: "PIERCING_UP" },
            { label: "Rick Shaw Man Up", value: "RICK_SHAW_MAN_UP" },
            {
              label: "Rise Fall Three Methods Up",
              value: "RISE_FALL_THREE_METHODS_UP",
            },
            {
              label: "Rise Fall Three Methods Down",
              value: "RISE_FALL_THREE_METHODS_DOWN",
            },
            { label: "Separating Lines Up", value: "SEPARATING_LINES_UP" },
            { label: "Separating Lines Down", value: "SEPARATING_LINES_DOWN" },
            { label: "Shooting Star Down", value: "SHOOTING_STAR_DOWN" },
            { label: "Short Line Up", value: "SHORT_LINE_UP" },
            { label: "Short Line Down", value: "SHORT_LINE_DOWN" },
            { label: "Spinning Top Up", value: "SPINNING_TOP_UP" },
            { label: "Spinning Top Down", value: "SPINNING_TOP_DOWN" },
            { label: "Stalled Pattern Down", value: "STALLED_PATTERN_DOWN" },
            { label: "Stick Sandwich Up", value: "STICK_SANDWICH_UP" },
            { label: "Takuri Up", value: "TAKURI_UP" },
            { label: "Tasuki Gap Up", value: "TASUKI_GAP_UP" },
            { label: "Tasuki Gap Down", value: "TASUKI_GAP_DOWN" },
            { label: "Thrusting Down", value: "THRUSTING_DOWN" },
            { label: "Tri Star Up", value: "TRI_STAR_UP" },
            { label: "Tri Star Down", value: "TRI_STAR_DOWN" },
            { label: "Unique Three River Up", value: "UNIQUE_THREE_RIVER_UP" },
            {
              label: "Upside Gap Two Crows Down",
              value: "UPSIDE_GAP_TWO_CROWS_DOWN",
            },
            {
              label: "X Side Gap Three Methods Up",
              value: "X_SIDE_GAP_THREE_METHODS_UP",
            },
            {
              label: "X Side Gap Three Methods Down",
              value: "X_SIDE_GAP_THREE_METHODS_DOWN",
            },
            { label: "Abandoned Baby Up", value: "ABANDONED_BABY_UP" },
            { label: "Abandoned Baby Down", value: "ABANDONED_BABY_DOWN" },
            { label: "Dark Cloud Cover Down", value: "DARK_CLOUD_COVER_DOWN" },
            {
              label: "Evening Doji Star Down",
              value: "EVENING_DOJI_STAR_DOWN",
            },
            { label: "Evening Star Down", value: "EVENING_STAR_DOWN" },
            { label: "Mat Hold Up", value: "MAT_HOLD_UP" },
            { label: "Morning Doji Star Up", value: "MORNING_DOJI_STAR_UP" },
            { label: "Morning Star Up", value: "MORNING_STAR_UP" },
          ],
        },
      ],
      sortingState: [
        {
          id: "totalPatterns",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.sizeHighlighter]: {
    component: EWidgetType.sizeHighlighter,
    name: "Spoofing",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Spoofing highlighter",
      mergedWithChart: true,
      plan: PLAN.TRADER,
      filters: [
        {
          type: EFilterType.number,
          title: "Price",
          filterByField: "price",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Prc",
          filterByField: "prc",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "prc",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.spoofLayer]: {
    component: EWidgetType.spoofLayer,
    name: "Layering",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Layering highlighter",
      mergedWithChart: true,
      plan: PLAN.TRADER,
      filters: [
        {
          type: EFilterType.number,
          title: "Price",
          filterByField: "price",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Prc",
          filterByField: "prc",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "prc",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.spreadDominationScreener]: {
    component: EWidgetType.spreadDominationScreener,
    name: "Spot Perp TD",
    config: {
      ...defaultConfig,
      plan: PLAN.PRO,
      indicatorTitle: "[SFM] Spot perpetuals time dominance",
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "D.CUM",
          filterByField: "dCum",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "M.CUM",
          filterByField: "mCum",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.smartHiLow]: {
    component: EWidgetType.smartHiLow,
    name: "Smart Hi/Lo",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      filters: [
        {
          type: EFilterType.number,
          title: "PRC",
          filterByField: "prc",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.select,
          title: "LEVEL",
          filterByField: "level",
          options: [
            { label: "DAY", value: "DAY" },
            { label: "WEEK", value: "WEEK" },
            { label: "MONTH", value: "MONTH" },
            { label: "YEAR", value: "YEAR" },
          ],
        },
      ],
    },
  },
  [EWidgetType.strategies]: {
    component: EWidgetType.strategies,
    name: "Strategies",

    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "direction",
          options: [
            {
              label: "Buy",
              value: "BUY",
            },
            {
              label: "Sell",
              value: "SELL",
            },
          ],
        },
        {
          type: EFilterType.multiSelect,
          title: "Strategy",
          filterByField: "strategy",
          options: [
            {
              label: "Altusher BB",
              value: "SCREENER_STRATEGY_ALTUSHER_BB1",
            },
            {
              label: "Altusher Gaps",
              value: "SCREENER_STRATEGY_ALTUSHER_GAPS1",
            },
            {
              label: "CM V1",
              value: "SCREENER_STRATEGY_CM_STRATEGY1",
            },
            {
              label: "CM V2",
              value: "SCREENER_STRATEGY_CM_STRATEGY2",
            },
            {
              label: "Dual EMA Bollinger",
              value: "SCREENER_STRATEGY_DUAL_EMA_BOLLINGER1",
            },
            {
              label: "Multi Period Volatility Filter",
              value: "SCREENER_STRATEGY_MULTI_PERIOD_VOLATILITY_FILTER1",
            },
            {
              label: "Relative High Low Oscillator",
              value: "SCREENER_STRATEGY_RELATIVE_HIGH_LOW_OSCILLATOR1",
            },
            {
              label: "Directional Index with Bollinger Confirmation",
              value:
                "SCREENER_STRATEGY_DIRECTIONAL_INDEX_WITH_BOLINGER_CONFIRMATION1",
            },
            {
              label: "Triple Price Deviation Filter",
              value: "SCREENER_STRATEGY_TRIPLE_PRICE_DEVIATION_FILTER1",
            },
            {
              label: "Volatility Based EMA",
              value: "SCREENER_STRATEGY_VOLATILITY_BASED_EMA1",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.stopKiller]: {
    component: EWidgetType.stopKiller,
    name: "Stop Killer",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      indicatorTitle: "[SFM] Stop killer highlighter",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "kill",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "side",
          options: [
            {
              label: "Buy",
              value: "UP",
            },
            {
              label: "Sell",
              value: "DOWN",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.manipulationMonitor]: {
    component: EWidgetType.manipulationMonitor,
    name: "MM",

    config: {
      ...defaultConfig,
      plan: PLAN.PRO,
      indicatorTitle: "[SFM] Manipulation monitor",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "corr",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "M.M.Price",
          filterByField: "corr",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "M.M.Volume",
          filterByField: "corrV",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.priceScreener]: {
    component: EWidgetType.priceScreener,
    name: "Price",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      sortingState: [
        {
          id: "price",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Price",
          filterByField: "price",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.adtvScreener]: {
    component: EWidgetType.adtvScreener,

    name: "ADTV",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      indicatorTitle: "[SFT] Average day trade volume",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "adtv",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "ADTV",
          filterByField: "adtv",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "ADTS",
          filterByField: "adts",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.activityDetectorScreener]: {
    component: EWidgetType.activityDetectorScreener,
    name: "Activity Detector",

    config: {
      ...defaultConfig,
      plan: PLAN.PRO,
      indicatorTitle: "[SFM] Activity detector",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.activityDetectorTdScreener]: {
    component: EWidgetType.activityDetectorTdScreener,
    name: "Activity Detector TD",

    config: {
      ...defaultConfig,
      plan: PLAN.PRO,
      indicatorTitle: "[SFM] Activity detector highlighter",
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
      ],
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "valueCum",
          desc: true,
        },
        {
          id: "value",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Value Cum",
          filterByField: "valueCum",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.marketRatioScreener]: {
    component: EWidgetType.marketRatioScreener,
    name: "Market Ratio",

    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      filters: [
        {
          type: EFilterType.number,
          title: "Day Value",
          filterByField: "dayValue",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Week Value",
          filterByField: "weekValue",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Month Value",
          filterByField: "monthValue",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "monthValue",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.cryptoDriftScreener]: {
    component: EWidgetType.cryptoDriftScreener,
    name: "Crypto Drift",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      indicatorTitle: "[SFM] Crypto drift",
      indicatorMergedWithChart: true,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.trendAssessmentScreener]: {
    component: EWidgetType.trendAssessmentScreener,
    name: "Trend Assessment",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      indicatorTitle: "[SFT] Trend assessment",
      indicatorMergedWithChart: true,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "HugeTrendCum",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Huge",
          filterByField: "HugeTrendCum",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Medium",
          filterByField: "mediumTrendCum",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Small",
          filterByField: "smallTrendCum",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Thread",
          filterByField: "threadCum",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.oiScreener]: {
    component: EWidgetType.oiScreener,
    name: "OI",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      indicatorTitle: "[SFT] Open interest",
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "openInterest",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Open Interest",
          filterByField: "openInterest",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.volumeScreener]: {
    component: EWidgetType.volumeScreener,
    name: "Volume",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      indicatorTitle: "Volume",
      indicatorMergedWithChart: true,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "volume",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "volume",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.deltaScreener]: {
    component: EWidgetType.deltaScreener,
    name: "Volume Δ",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      indicatorTitle: "[SFT] Volume delta",
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "buyDelta",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Buy Delta",
          filterByField: "buyDelta",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "Sell Delta",
          filterByField: "sellDelta",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.adf]: {
    component: EWidgetType.adf,
    name: "ADF",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      indicatorTitle: "[SFT] ADF",
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["3m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
      ],
      sortingState: [
        {
          id: "adf",
          desc: false,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "ADF",
          filterByField: "adf",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.liquidationsScanner]: {
    component: EWidgetType.liquidationsScanner,
    name: "Liq",
    config: {
      ...defaultConfig,
      plan: PLAN.BASIC,
      indicatorTitle: "[SFM] Liquidations",
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.powerTradesScreener]: {
    component: EWidgetType.powerTradesScreener,
    name: "Power Trades",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
      ],
      indicatorTitle: "[SFM] Power trades",
      filters: [
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "volumeQuote",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "Delta",
          filterByField: "deltaQuote",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.multiSelect,
          title: "Weight",
          filterByField: "weight",
          options: [
            {
              label: "Tiny",
              value: "TINY",
            },
            {
              label: "Small",
              value: "SMALL",
            },
            {
              label: "Normal",
              value: "NORMAL",
            },
            {
              label: "Huge",
              value: "HUGE",
            },
          ],
        },
      ],
      sortingState: [
        {
          id: "symbol",
          desc: false,
        },
      ],
    },
  },
  [EWidgetType.htfActivityScreener]: {
    component: EWidgetType.htfActivityScreener,
    name: "HTF Activity",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1d"] as ResolutionString,
      ],
      indicatorTitle: "[SFM] HFT activity",
      filters: [
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "volumeQuote",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "Delta",
          filterByField: "deltaQuote",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.multiSelect,
          title: "Weight",
          filterByField: "weight",
          options: [
            {
              label: "Tiny",
              value: "TINY",
            },
            {
              label: "Small",
              value: "SMALL",
            },
            {
              label: "Normal",
              value: "NORMAL",
            },
            {
              label: "Huge",
              value: "HUGE",
            },
          ],
        },
      ],
      sortingState: [
        {
          id: "symbol",
          desc: false,
        },
      ],
    },
  },
  [EWidgetType.pairsScanner]: {
    component: EWidgetType.pairsScanner,
    name: "STAT ARB PAIRS",
    config: {
      ...defaultConfig,
      plan: PLAN.PRO,
      indicatorTitle: "[SFT] Bollinger bands",
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.cascadeLevels]: {
    component: EWidgetType.cascadeLevels,
    name: "Cascade Levels",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
      ],
      sortingState: [
        {
          id: "toFcPrc",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Percents",
          filterByField: "toFcPrc",
          numberComparisonType: ENumberComparisonType.less,
        },
        {
          type: EFilterType.select,
          title: "Cells",
          filterByField: "fcSum",
          options: [
            {
              label: "1",
              value: "1",
            },
            {
              label: "2",
              value: "2",
            },
            {
              label: "3",
              value: "3",
            },
            {
              label: "4",
              value: "4",
            },
          ],
        },
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "side",
          options: [
            {
              label: "Buy",
              value: "UP",
            },
            {
              label: "Sell",
              value: "DOWN",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.predictLiquidation]: {
    component: EWidgetType.predictLiquidation,
    name: "Predict Liquidations",
    config: {
      ...defaultConfig,
      plan: PLAN.TRADER,
      sortingState: [
        {
          id: "prc",
          desc: false,
        },
      ],
      filters: [
        {
          type: EFilterType.text,
          title: "Symbol",
          filterByField: "symbol",
        },
        {
          type: EFilterType.number,
          title: "Percents",
          filterByField: "prc",
          numberComparisonType: ENumberComparisonType.less,
        },
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "quoteQuantity",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "side",
          options: [
            {
              label: "Buy",
              value: "UP",
            },
            {
              label: "Sell",
              value: "DOWN",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.controlPanel]: {
    component: EWidgetType.controlPanel,
    name: "Control Panel",
    config: {
      plan: PLAN.BASIC,
      color: colors[0],
    },
  },
};

export enum EScreenerStrategy {
  SCREENER_STRATEGY_CM_STRATEGY1 = "CM v.1",
  SCREENER_STRATEGY_CM_STRATEGY2 = "CM v.2",
  SCREENER_STRATEGY_DUAL_EMA_BOLLINGER1 = "Dual EMA Bollinger",
  SCREENER_STRATEGY_ALTUSHER_BB1 = "Altusher BB",
  SCREENER_STRATEGY_ALTUSHER_GAPS1 = "Altusher Gaps",
  SCREENER_STRATEGY_RELATIVE_HIGH_LOW_OSCILLATOR1 = "Relative High Low Oscillator",
  SCREENER_STRATEGY_MULTI_PERIOD_VOLATILITY_FILTER1 = "Multi Period Volatility Filter",
  SCREENER_STRATEGY_DIRECTIONAL_INDEX_WITH_BOLINGER_CONFIRMATION1 = "Directional Index with Bollinger Confirmation",
  SCREENER_STRATEGY_TRIPLE_PRICE_DEVIATION_FILTER1 = "Triple Price Deviation Filter",
  SCREENER_STRATEGY_VOLATILITY_BASED_EMA1 = "Volatility Based EMA",
}

export const APP_VERSION = "2.0.6";
