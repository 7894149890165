import React from "react";

import { ToggleExpandAll } from "@/components/widgets/controlPanel/favorites/toggleExpandAll.tsx";
import { RemoveAllFavorites } from "@/components/widgets/controlPanel/favorites/removeAllFavorites.tsx";
import { AddFolder } from "@/components/widgets/controlPanel/favorites/addFolder.tsx";
import { FavoritesInner } from "@/components/widgets/controlPanel/favorites/favoritesInner.tsx";
import { SelectColumns } from "@/components/widgets/controlPanel/favorites/selectColumns.tsx";
import { AddFavoriteAlt } from "@/components/widgets/controlPanel/favorites/addFavoriteAlt.tsx";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

export function Favorites() {
  return (
    <Card className="bg-background pt-2 border-neutral-700">
      <CardContent className="p-2">
        <div className="flex gap-1 items-center w-full mb-2">
          <AddFolder />
          <ToggleExpandAll />
          <RemoveAllFavorites />
        </div>
        <FavoritesInner />
      </CardContent>
    </Card>
  );
}
