import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { getClassicTopic, SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export interface IClassicResponse {
  symbol: string;
  value: number;
}

export function useClassic(timeframe: string, name: string) {
  const handler = useCallback(
    (_: IClassicResponse, newData: IClassicResponse) => {
      return newData;
    },
    [],
  );
  const topic = getClassicTopic(timeframe, name);

  return useGenericSocket<IClassicResponse, IClassicResponse>(topic, handler);
}
