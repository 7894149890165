import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ESFTableType } from "@/domain/enums/dashboard.enum.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import { IHiLow, useSmartHiLow } from "@/hooks/useSmartHiLow";

const columnHelper = createColumnHelper<IHiLow>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),
  columnHelper.accessor("weight", {
    header: "Lv",
    cell: (row) => {
      const value = row.getValue();
      return <div className="text-xs">{value}</div>;
    },
    size: 120,
    minSize: 120,
  }),

  columnHelper.accessor("levelPrice", {
    header: "Lv. PRICE",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("price", {
    header: "Cur. PRICE",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("prc", {
    header: "DISTANCE",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={true}
          className="font-bold"
          decimal={2}
          suffix="%"
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
];

export function SmartHiLowPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: smartHiLow, isLoading } = useSmartHiLow();

  const preparedData = useMemo(() => {
    if (!smartHiLow?.data) {
      return [];
    }

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        smartHiLow.data = smartHiLow.data.filter(
          (item) => item.symbol !== symbol,
        );
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IHiLow>(
        smartHiLow.data,
        config.filters,
        config.filterValues,
      );
    }

    return smartHiLow.data;
  }, [config, smartHiLow?.data, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
