import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export type ICandlePatterns = {
  [key: string]: ICandlePattern;
};

export type ICandlePattern = {
  data: {
    [key: string]: string[];
  };
  timeFrame: string;
  timestamp: number;
};

export type ICandlePatternRow = {
  symbol: string;
  pattern: string;
};

export function useCandlePatterns() {
  const handler = useCallback(
    (_: ICandlePatterns, newData: ICandlePatterns) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<ICandlePatterns, ICandlePatterns>(
    SocketTopics.candlePatterns,
    handler,
  );
}
