import { useDashboard } from "@/lib/store.ts";
import { AltSelect } from "@/components/shared/altSelect.tsx";
import { Button } from "@/components/ui/button.tsx";
import React from "react";
import { useToast } from "@/components/ui/use-toast.ts";

export function MoveFavorite({
  sourceFolderId,
  favoriteName,
}: {
  sourceFolderId?: string | null;
  favoriteName: string;
}) {
  const { toast } = useToast();

  const { folders, updateFolders } = useDashboard((state) => ({
    updateFolders: state.updateFavorites,
    folders: state.favorites
      .filter((f) => f.id !== sourceFolderId)
      .map((f) => ({
        label: f.name,
        value: f.id,
        bgColor: f.color,
      })),
  }));

  const moveHandler = (folderId: string) => {
    updateFolders((state) => {
      const sourceFolder = state.find((f) => f.id === sourceFolderId);
      const targetFolder = state.find(
        (f) => f.id.toLocaleLowerCase() === folderId.toLocaleLowerCase(),
      );
      if (sourceFolder) {
        sourceFolder.symbols = sourceFolder.symbols.filter(
          (s) => s !== favoriteName,
        );
      }
      if (targetFolder) {
        if (!targetFolder.symbols.includes(favoriteName)) {
          targetFolder.symbols.push(favoriteName);
        } else {
          toast({
            variant: "destructive",
            title: `${favoriteName} already exists in ${targetFolder.name}`,
          });
        }
      } else {
        toast({
          variant: "destructive",
          title: `Folder ${folderId} not found`,
        });
      }
    });
  };

  return (
    <AltSelect
      onChange={moveHandler}
      options={folders}
      trigger={
        <Button size="xs" variant="ghost" className="w-4 h-4">
          <i className="fa-regular fa-folder-arrow-up"></i>
        </Button>
      }
      emptyText="Folders not found"
      searchText="Search folder"
    />
  );
}
