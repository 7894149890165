//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  GetMarketDataSource,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const rateOfChange = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Rate of change",
    metainfo: {
      _metainfoVersion: 51,
      id: "rate-of-change@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Rate of change",
      shortDescription: "[SF] ROC",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "rocPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
          rocPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          sourceInput: "Price",
          lengthInput: 60,
        },
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        rocPlot: {
          title: "ROC",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: [
            "Price",
            "Volume",
            "Volume delta",
            "Open interest",
            "Tape",
            "Average trade size",
          ],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 100,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: any = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);

        const volumeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, period);

        const openInterestSymbol: string = `${baseSymbol}#SF_OPEN_INTEREST`;
        this._context.new_sym(openInterestSymbol, period);
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [sourceInput, lengthInput]: [string, number] = [
          this._input(1),
          this._input(2),
        ];

        // Get market data
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const timeVar: any = this._context.new_var(this._context.symbol.time);
        const priceArray: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        const volumeArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(0);
        const data: number = GetMarketDataSource(
          PineJS,
          sourceInput,
          this._context,
        );
        const dataSeries: any = this._context.new_var(data);

        // Calculations
        const prevData: number = dataSeries.get(lengthInput);
        const roc: number =
          prevData !== 0 ? (100 * (data - prevData)) / prevData : 0;

        // Return values
        return [0, roc];
      };
    },
  };
};
