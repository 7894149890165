import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useDashboard } from "@/lib/store.ts";
import { cn } from "@/lib/utils.ts";
import { useUserStore } from "@/lib/stores/userStore";
import { MAX_LAYOUTS_PER_PLAN } from "@/domain/constants";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { keyBy } from "lodash";
import {
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IEditableLayoutName {
  layoutId: string;
  initialName: string;
  onNameChange: (layoutId: string, newName: string) => void;
}
function EditableLayoutName({
  layoutId,
  initialName,
  onNameChange,
}: IEditableLayoutName) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const finalizeEditing = () => {
    setIsEditing(false);
    onNameChange(layoutId, name);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      (e.currentTarget as HTMLInputElement).blur();
    }
  };

  return isEditing ? (
    <input
      type="text"
      value={name}
      onChange={(e) => setName(e.target.value)}
      onBlur={finalizeEditing}
      onKeyDown={handleKeyPress}
      autoFocus
      className="bg-transparent"
      style={{
        width: name.length * 8 + "px",
      }}
    />
  ) : (
    <span
      style={{
        width: name.length * 8 + "px",
      }}
      onDoubleClick={() => setIsEditing(true)}
    >
      {name}
    </span>
  );
}

function AddLayoutButton() {
  const { addLayout, layouts } = useDashboard((state) => ({
    addLayout: state.addLayout,
    layouts: state.layouts,
  }));
  const userRole = useUserStore((state) => state.user?.role || "FREE");
  const maxLayouts = MAX_LAYOUTS_PER_PLAN[userRole.toUpperCase()];

  return (
    <Button variant="outline" size="xs" onClick={() => addLayout()}>
      Add Layout ({layouts.length}/{maxLayouts})
    </Button>
  );
}

function RemoveLayoutBadge({
  setDialogOpen,
}: {
  setDialogOpen: (a: boolean) => void;
}) {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDialogOpen(true);
  };

  return (
    <Badge
      variant="secondary"
      className="rounded-full w-4 h-4 p-0 flex justify-center items-center absolute -top-2 -right-2 z-10"
      onClick={handleClick}
    >
      <i className="fa-regular fa-xmark"></i>
    </Badge>
  );
}

export function LayoutSwitcher() {
  const {
    layouts,
    activeLayout,
    activeLayoutId,
    layoutsOrder,
    updateDashboardState,
    setActiveLayoutId,
    removeActiveLayout,
  } = useDashboard((state) => ({
    layouts: state.layouts,
    activeLayoutId: state.activeLayoutId,
    activeLayout: state.layouts.find((l) => l.id === state.activeLayoutId),
    layoutsOrder: state.layoutsOrder,
    updateDashboardState: state.updateDashboardState,
    setActiveLayoutId: state.setActiveLayoutId,
    removeActiveLayout: state.removeActiveLayout,
  }));
  const [isRemoveLayoutDialogOpen, setRemoveLayoutDialogOpen] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
  );

  const layoutsObject = useMemo(() => keyBy(layouts, "id"), [layouts]);

  function onDragStart({ active }: DragStartEvent) {
    document.body.classList.add("dragging");
  }
  function removeBodyClassDragging() {
    document.body.classList.remove("dragging");
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    removeBodyClassDragging();
    if (active.id !== over?.id) {
      const oldIndex = layoutsOrder.indexOf(active.id as string);
      const newIndex = layoutsOrder.indexOf(over?.id as string);
      updateDashboardState((state) => {
        state.layoutsOrder = arrayMove(state.layoutsOrder, oldIndex, newIndex);
      });
    }
  }

  const handleConfirmRemoving = () => {
    removeActiveLayout();
    setRemoveLayoutDialogOpen(false);
  };

  const handleCancelRemoving = () => {
    setRemoveLayoutDialogOpen(false);
  };

  return layouts ? (
    <div className="flex gap-2 items-center">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToWindowEdges, restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        onDragCancel={removeBodyClassDragging}
        onDragStart={onDragStart}
      >
        <SortableContext
          items={layoutsOrder}
          strategy={horizontalListSortingStrategy}
        >
          <div className="flex gap-1">
            {layoutsOrder.map((layoutId) => (
              <SortableItem key={layoutId} id={layoutId}>
                <Button
                  key={layoutId}
                  value={layoutId}
                  variant={
                    layoutId === activeLayoutId ? "default" : "secondary"
                  }
                  size="xs"
                  className={cn(
                    "relative gap-1 items-center",
                    layoutId === activeLayoutId && "hover:opacity-100",
                  )}
                  onClick={() => {
                    setActiveLayoutId(layoutId);
                  }}
                >
                  <EditableLayoutName
                    layoutId={layoutId}
                    initialName={layoutsObject[layoutId].name}
                    onNameChange={(id, newName) => {
                      const updatedLayouts = layouts.map((layout) =>
                        layout.id === id
                          ? { ...layout, name: newName }
                          : layout,
                      );
                      updateDashboardState((state) => {
                        state.layouts = updatedLayouts;
                      });
                    }}
                  />
                  {layouts.length !== 1 && layoutId === activeLayoutId && (
                    <RemoveLayoutBadge
                      setDialogOpen={setRemoveLayoutDialogOpen}
                    />
                  )}
                </Button>
              </SortableItem>
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <AddLayoutButton />
      <Dialog
        open={isRemoveLayoutDialogOpen}
        onOpenChange={setRemoveLayoutDialogOpen}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Remove layout</DialogTitle>
            <DialogDescription>
              Are you sure you want to remove {""}
              <span className="underline">{activeLayout?.name}</span>{" "}
              completely? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="destructive" onClick={handleConfirmRemoving}>
              Yes, remove it
            </Button>
            <Button variant="secondary" onClick={handleCancelRemoving}>
              No, keep it
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  ) : (
    <>loading</>
  );
}

function SortableItem({ id, children }: PropsWithChildren<{ id: string }>) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(isDragging && "z-50")}
    >
      {children}
    </div>
  );
}
