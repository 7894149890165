// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import {
  ParseSymbol,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const volumeDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Volume delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "volume-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Volume delta",
      shortDescription: "[SF] Volume delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "sellVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "buyVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "sellVolumeMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "buyVolumeMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "volumeDeltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          sellVolumePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          buyVolumePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
          sellVolumeMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          buyVolumeMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          volumeDeltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          displayMode: "Delta",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 20,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        buyVolumePlot: {
          title: "Buy taker volume",
          histogramBase: 0,
        },
        sellVolumePlot: {
          title: "Sell taker volume",
          histogramBase: 0,
        },
        buyVolumeMaPlot: {
          title: "Buy taker volume MA",
          histogramBase: 0,
        },
        sellVolumeMaPlot: {
          title: "Sell taker volume MA",
          histogramBase: 0,
        },
        volumeDeltaStDevPlot: {
          title: "Volume delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "displayMode",
          name: "Display mode",
          defval: "Delta",
          options: ["Delta", "Default"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const volumeSymbol: string = `${ParseSymbol(symbol)}${"#SF_VOLUME"}`;
        this._context.new_sym(volumeSymbol, period);
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number, number, number, number, number] {
        this._context = context;
        this._input = inputCallback;

        // Getting user`s input data
        const [displayMode, measure, maType, length, showMetrics]: [
          string,
          string,
          string,
          number,
          boolean,
        ] = [1, 2, 3, 4, 5].map((i) => this._input(i));

        // Getting market data
        this._context.select_sym(1);
        const timeData: any = this._context.new_var(this._context.symbol.time);
        const priceData: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        const volumeData: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(2);
        const buyVolumeTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const buyVolumeData: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );

        this._context.select_sym(0);
        const mainTime: any = this._context.new_var(this._context.symbol.time);

        // Volume calculations
        const volume: number = volumeData.adopt(timeData, mainTime, 1);
        let buyVolume: number = buyVolumeData.adopt(buyVolumeTime, mainTime, 1);
        let sellVolume: number = buyVolume - volume;
        let volumeDelta: number = sellVolume + buyVolume;

        if (measure === "USD") {
          const price: number = priceData.adopt(timeData, mainTime, 1);
          [buyVolume, sellVolume, volumeDelta] = [
            buyVolume,
            sellVolume,
            volumeDelta,
          ].map((v) => v * price);
        }

        // Metrics calculations
        let volumeDeltaStdev: number = 0,
          buyVolumeMa: number = 0,
          sellVolumeMa: number = 0;

        if (showMetrics) {
          const series: any[] = [buyVolume, sellVolume, volumeDelta].map((v) =>
            this._context.new_var(v),
          );
          buyVolumeMa = MovingAverage(
            PineJS,
            series[0],
            maType,
            length,
            this._context,
          );
          sellVolumeMa = MovingAverage(
            PineJS,
            series[1],
            maType,
            length,
            this._context,
          );
          volumeDeltaStdev = PineJS.Std.stdev(series[2], length, this._context);
        }

        if (displayMode === "Delta") {
          buyVolume = volumeDelta > 0 ? volumeDelta : NaN;
          sellVolume = volumeDelta < 0 ? volumeDelta : NaN;
        }

        // Return values
        return [
          0,
          sellVolume,
          buyVolume,
          sellVolumeMa,
          buyVolumeMa,
          volumeDeltaStdev,
        ];
      };
    },
  };
};
