//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import {
  ParseSymbol,
  ADFTest,
} from "@/lib/indicators/indicators-common-functions";

export const spotPerpetualsAugmentedDickeyFullerTest = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Spot Perpetuals Augmented Dickey–Fuller test",
    metainfo: {
      _metainfoVersion: 51,
      id: "spot-perpetuals-augmented-dickey-fuller-test@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Spot perpetuals augmented Dickey–Fuller test",
      shortDescription: "[SF] Spot perp ADF test",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zerolinePlot", type: StudyPlotType.Line },
        { id: "criticalPlot", type: StudyPlotType.Line },
        { id: "tauadfPlot", type: StudyPlotType.Line },
        {
          id: "tauadfColorer",
          type: StudyPlotType.Colorer,
          target: "tauadfPlot",
          palette: "metricPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Not stationary" },
            1: { name: "Stationary" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          criticalPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
          tauadfPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
          tauadfColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
        },
        inputs: {
          symbolInput: "",
          lookbackInput: 240,
          nlagInput: 0,
          confidenceInput: "90%",
        },
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        criticalPlot: {
          title: "Critical value",
          histogramBase: 0,
        },
        tauadfPlot: {
          title: "Test statistic",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "lookbackInput",
          name: "Lookback",
          defval: 240,
          min: 2,
          type: StudyInputType.Integer,
        },
        {
          id: "nlagInput",
          name: "Maximum lag",
          defval: 0,
          min: 0,
          type: StudyInputType.Integer,
        },
        {
          id: "confidenceInput",
          name: "Confidence level",
          defval: "90%",
          options: ["90%", "95%", "99%"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const period: number = PineJS.Std.period(this._context);
        const baseSymbol: string = ParseSymbol(symbol);
        const spotPerpSymbol: string = `${baseSymbol}#SF_SPREAD`;
        this._context.new_sym(spotPerpSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [lookbackInput, nlagInput, confidenceInput]: [
          number,
          number,
          string,
        ] = Array.from({ length: 3 }, (_, i) => this._input(i + 1));

        // Market data parsing
        this._context.select_sym(1);
        const spotPerpSpreadTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotPerpSpreadArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotPerpSpread: number = -spotPerpSpreadArray.adopt(
          spotPerpSpreadTime,
          mainSymbolTime,
          0,
        );
        const spotPerpSpreadSeries: any = this._context.new_var(spotPerpSpread);

        //Calculations
        const a: number[] = [];
        for (let i: number = 0; i < lookbackInput; i++) {
          a.push(spotPerpSpreadSeries.get(i));
        }
        const [tauADF, crit] = ADFTest(a, nlagInput, confidenceInput);
        const tauColor: number = tauADF > crit ? 0 : 1;

        //Returns
        return [0, crit, tauADF, tauColor];
      };
    },
  };
};
