import React from "react";
import { cn } from "@/lib/utils";
import { usePrice } from "@/hooks/usePrice";
import { isUndefined } from "lodash";

export function Price() {
  const { data: priceData } = usePrice();

  if (isUndefined(priceData)) {
    return null;
  }

  const price = priceData.data["3tNPxTMS1ETgXeLFTjZTbt6KVbMnXU3Qtz9dwWLWtks5"];
  const formattedPrice = Number(price).toFixed(4);

  return (
    <div
      onClick={() => {
        window.open("https://spreadfighter.link/token", "_blank");
      }}
      className={cn(
        "px-1.5 h-10 flex cursor-pointer items-center justify-center text-xxs rounded-md border mx-2 w-20 text-center",
        "text-[#3CA64B] border-[#3CA64B]",
      )}
    >
      $SF: ${formattedPrice}
    </div>
  );
}
