import { useDashboard } from "@/lib/store.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { FaPlus } from "react-icons/fa6";
import { cn } from "@/lib/utils.ts";
import { FaCheck } from "react-icons/fa6";
import React from "react";
import { dynamicColumns } from "@/domain/constants.ts";

export function SelectColumns() {
  const { favoritesSettings, updateFavoritesSettings } = useDashboard(
    (state) => ({
      favoritesSettings: state.favoritesSettings,
      updateFavoritesSettings: state.updateFavoritesSettings,
    }),
  );

  const handleColumnToggle = (column: string) => {
    updateFavoritesSettings((state) => {
      if (state.activeColumns.includes(column as any)) {
        state.activeColumns = state.activeColumns.filter((c) => c !== column);
      } else {
        state.activeColumns.push(column as any);
      }
    });
  };

  const groupedColumns = Object.entries(dynamicColumns).reduce(
    (acc, [key, col]) => {
      const group = col.group || "Other";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({ key, col });
      return acc;
    },
    {} as Record<
      string,
      {
        key: string;
        col: (typeof dynamicColumns)[keyof typeof dynamicColumns];
      }[]
    >,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="xs" variant="ghost">
          <FaPlus />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Choose Columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {Object.entries(groupedColumns).map(([group, columns]) => (
          <DropdownMenuSub key={group}>
            <DropdownMenuSubTrigger>
              <span>{group}</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {columns.map(({ key, col }) =>
                  col.availableTimeFrames ? (
                    <DropdownMenuSub key={key}>
                      <DropdownMenuSubTrigger>
                        <span className={cn("h-full w-4", key)}>
                          {favoritesSettings.activeColumns.find(
                            (e) => e.split(".")[0] === (key as any),
                          ) && <FaCheck />}
                        </span>
                        <span>{col.title}</span>
                      </DropdownMenuSubTrigger>
                      <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                          {col.availableTimeFrames.map((tf, i) => (
                            <DropdownMenuItem
                              key={key + tf}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleColumnToggle(`${key}.${tf}`);
                              }}
                            >
                              <span
                                className={cn("h-full w-4", `${key}.${tf}`)}
                              >
                                {favoritesSettings.activeColumns.includes(
                                  `${key}.${tf}` as any,
                                ) && <FaCheck />}
                              </span>
                              <span>{col.timeFrameTitles?.[i]}</span>
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuSubContent>
                      </DropdownMenuPortal>
                    </DropdownMenuSub>
                  ) : (
                    <DropdownMenuItem
                      key={key}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleColumnToggle(key);
                      }}
                    >
                      <span className="h-full w-4">
                        {favoritesSettings.activeColumns.includes(
                          key as any,
                        ) && <FaCheck />}
                      </span>
                      <span>{col.title}</span>
                    </DropdownMenuItem>
                  ),
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
