import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { useUserStore } from "./userStore";
import { IMarketplaceProduct } from "@/domain/interfaces/marketplace.interface.ts";
import { immer } from "zustand/middleware/immer";
import { useMarketplaceProducts } from "@/hooks/useMarketplaceProducts";

interface MarketplaceState {
  products: IMarketplaceProduct[]; // Replace 'any' with your actual product type
  setProducts: (products: IMarketplaceProduct[]) => void;
  purchasedProductIds: string[];
  setPurchasedProductIds: (ids: string[]) => void;
  setPurchasedProducts: (products: IMarketplaceProduct[]) => void;
  clearPurchasedProducts: () => void;
}

export const useMarketplaceStore = create<MarketplaceState>()(
  subscribeWithSelector(
    persist(
      devtools(
        immer((set) => ({
          products: [],
          purchasedProductIds: ["free"],
          setProducts: (products) =>
            set((state) => {
              state.products = products;
            }),
          setPurchasedProducts: (products) =>
            set((state) => {
              state.purchasedProductIds = products.map((product) => product.id);
            }),
          clearPurchasedProducts: () =>
            set((state) => {
              state.purchasedProductIds = [];
            }),
          setPurchasedProductIds: (ids) =>
            set((state) => {
              state.purchasedProductIds = ids;
            }),
        })),
      ),
      {
        name: "sf-marketplace-storage",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
