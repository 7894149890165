import React, { ReactNode } from "react";
import {
  Menubar,
  MenubarContent,
  MenubarGroup,
  MenubarItem,
  MenubarLabel,
  MenubarMenu,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { PLAN, widgets } from "@/domain/constants.ts";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { IWidgetOptions } from "@/domain/interfaces/dashboard.interface.ts";
import { useAuth } from "@/hooks/useAuth.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useRTT } from "@/hooks/useRTT.ts";
import { cn } from "@/lib/utils.ts";
import { Actions, Node } from "flexlayout-react";
import { isUndefined } from "lodash";
import { Logo } from "@/components/shared/logo.tsx";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { APP_VERSION } from "@/domain/constants";
import { toast } from "../ui/use-toast";
import { useUser } from "@/hooks/useUser";
import { useUserStore } from "@/lib/stores/userStore";
import { MAX_WIDGETS_PER_PLAN, hasAccessToWidget } from "@/domain/constants";
import { Badge } from "../ui/badge";
import { Price } from "@/components/shared/price";

function RTT() {
  const { data: rtt } = useRTT();
  if (isUndefined(rtt)) {
    return null;
  }

  const getRTTColor = (rtt: number | null) => {
    if (!rtt) {
      return "text-gray-600 border-gray-600";
    }

    switch (true) {
      case rtt < 150:
        return "text-[#3CA64B] border-[#3CA64B]";
      case rtt < 250:
        return "text-[#FFA028] border-[#FFA028]";
      case rtt < 350:
        return "text-[#B2182C] border-[#B2182C]";
      default:
        return "text-[#B2182C] border-[#B2182C]";
    }
  };

  const rttColor = getRTTColor(rtt);

  return (
    <div
      className={cn(
        "px-1.5 h-10 flex items-center justify-center text-xxs rounded-md border mx-2 w-14 text-center",
        rttColor,
      )}
    >
      {rtt} ms
    </div>
  );
}

interface MenuItem {
  label: string;
  widgetType?: EWidgetType;
  onClick?: (model: any) => void;
  className?: string;
  shortcut?: ReactNode;
  items?: MenuItem[];
  plan?: PLAN;
}

const menuItems: MenuItem[] = [
  {
    label: "Chart",
    widgetType: EWidgetType.chart,
  },
  {
    label: "ControlPanel",
    widgetType: EWidgetType.controlPanel,
  },
  {
    label: "Screeners",
    items: [
      {
        label: "Market Structure",
        items: [
          {
            label: "Activity Detector TD",
            widgetType: EWidgetType.activityDetectorTdScreener,
            plan: PLAN.EXPERT,
          },
          {
            label: "Activity Detector",
            widgetType: EWidgetType.activityDetectorScreener,
            plan: PLAN.PRO,
          },
          {
            label: "ADF",
            widgetType: EWidgetType.adf,
            plan: PLAN.TRADER,
          },
          {
            label: "ADF TD",
            plan: PLAN.TBD,
          },
          {
            label: "Crypto Drift",
            widgetType: EWidgetType.cryptoDriftScreener,
            plan: PLAN.TRADER,
          },
          {
            label: "Pickup 2.0",
            plan: PLAN.TBD,
          },
        ],
      },
      {
        label: "Top Gainers",
        items: [
          {
            label: "Price",
            widgetType: EWidgetType.priceScreener,
          },
          {
            label: "Open Interest",
            widgetType: EWidgetType.oiScreener,
          },
          {
            label: "Volume",
            widgetType: EWidgetType.volumeScreener,
          },
          {
            label: "Volume Delta",
            widgetType: EWidgetType.deltaScreener,
          },
        ],
      },
      {
        label: "Market Overview",
        items: [
          {
            label: "Correlation Matrix",
            plan: PLAN.TBD,
          },
          {
            label: "Derevative Spreads",
            plan: PLAN.TBD,
          },
          {
            label: "Market Ratio",
            widgetType: EWidgetType.marketRatioScreener,
            plan: PLAN.TRADER,
          },
          {
            label: "Stat Arbitrage Pairs",
            widgetType: EWidgetType.pairsScanner,
            plan: PLAN.TRADER,
          },
        ],
      },

      {
        label: "Microstructure",
        items: [
          {
            label: "ADTV/ADTS",
            widgetType: EWidgetType.adtvScreener,
          },
          {
            label: "Cascade Levels",
            widgetType: EWidgetType.cascadeLevels,
            plan: PLAN.TRADER,
          },
          {
            label: "HTF Activity",
            widgetType: EWidgetType.htfActivityScreener,
            plan: PLAN.TRADER,
          },
          {
            label: "Layering",
            widgetType: EWidgetType.spoofLayer,
            plan: PLAN.TRADER,
          },
          {
            label: "Liquidations",
            widgetType: EWidgetType.liquidationsScanner,
          },
          {
            label: "Manipulations",
            widgetType: EWidgetType.manipulationMonitor,
            plan: PLAN.PRO,
          },
          {
            label: "Market Power",
            widgetType: EWidgetType.marketPower,
            plan: PLAN.EXPERT,
          },
          {
            label: "Power Trades",
            widgetType: EWidgetType.powerTradesScreener,
            plan: PLAN.TRADER,
          },
          {
            label: "Predict Liquidations",
            widgetType: EWidgetType.predictLiquidation,
            plan: PLAN.TRADER,
          },
          {
            label: "Retail Power",
            widgetType: EWidgetType.retailPower,
            plan: PLAN.EXPERT,
          },
          {
            label: "Spoofing",
            widgetType: EWidgetType.sizeHighlighter,
            plan: PLAN.TRADER,
          },
          {
            label: "Spot Perp Spread",
            widgetType: EWidgetType.spotFuturesSpread,
            plan: PLAN.TRADER,
          },
          {
            label: "Spot Perp TD",
            widgetType: EWidgetType.spreadDominationScreener,
            plan: PLAN.PRO,
          },
          {
            label: "Stop Killer",
            widgetType: EWidgetType.stopKiller,
          },
        ],
      },
      {
        label: "Technical",
        items: [
          {
            label: "ADX",
            widgetType: EWidgetType.classicAdx,
          },
          {
            label: "Bollinger %B",
            widgetType: EWidgetType.classicBollingerPrc,
          },
          {
            label: "Candlestick Patterns",
            plan: PLAN.TRADER,
            widgetType: EWidgetType.candlePattern,
          },
          {
            label: "CCI (Commodity Channel Index)",
            widgetType: EWidgetType.classicCci,
          },
          {
            label: "Chande Momentum Oscillator",
            widgetType: EWidgetType.classicCmo,
          },
          {
            label: "Formations",
            plan: PLAN.TRADER,
            widgetType: EWidgetType.formations,
          },
          {
            label: "Keltner Channel Oscillator",
            widgetType: EWidgetType.classicKco,
          },
          {
            label: "MACD Normalized Signals",
            widgetType: EWidgetType.classicMacd,
          },
          {
            label: "MFI (Money Flow Index)",
            widgetType: EWidgetType.classicMfi,
          },
          {
            label: "NATR",
            widgetType: EWidgetType.classicNatr,
          },
          {
            label: "NMI (Normalized Momentum Indicator)",
            widgetType: EWidgetType.classicNmi,
          },
          {
            label: "Pattern Predict",
            widgetType: EWidgetType.patternPredict,
            plan: PLAN.TRADER,
          },
          {
            label: "PPO (Percentage Price Oscillator)",
            widgetType: EWidgetType.classicPpo,
          },
          {
            label: "ROC (Rate of Change)",
            widgetType: EWidgetType.classicRoc,
          },
          {
            label: "RSI",
            widgetType: EWidgetType.classicRsi,
          },
          {
            label: "Smart High Low",
            widgetType: EWidgetType.smartHiLow,
            plan: PLAN.TRADER,
          },
          {
            label: "SMA",
            widgetType: EWidgetType.classicSma,
          },
          {
            label: "Stochastic Oscillator",
            widgetType: EWidgetType.classicStochastic,
          },
          {
            label: "Trend Assessment",
            widgetType: EWidgetType.trendAssessmentScreener,
            plan: PLAN.TRADER,
          },
          {
            label: "Williams %R",
            widgetType: EWidgetType.classicWilliamsPrcR,
          },
        ],
      },
      {
        label: "Sentiment",
        items: [
          {
            label: "Positive Sentiment",
            plan: PLAN.TBD,
          },
          {
            label: "Sentiment Balance",
            plan: PLAN.TBD,
          },
          {
            label: "Negative Sentiment",
            plan: PLAN.TBD,
          },
          {
            label: "Sentiment Volume",
            plan: PLAN.TBD,
          },
          {
            label: "Developer Activity",
            plan: PLAN.TBD,
          },
          {
            label: "Twitter Followers",
            plan: PLAN.TBD,
          },
          {
            label: "GitHub Activity",
            plan: PLAN.TBD,
          },
          {
            label: "Social Activity",
            plan: PLAN.TBD,
          },
          {
            label: "Social Dominance",
            plan: PLAN.TBD,
          },
          {
            label: "Social Volume",
            plan: PLAN.TBD,
          },
          {
            label: "AI-Powered Social Dominance",
            plan: PLAN.TBD,
          },
        ],
      },
    ],
  },
  {
    label: "Strategies",
    widgetType: EWidgetType.strategies,
  },
  {
    label: "Settings",
    items: [
      {
        label: "Debug",
        items: [
          {
            label: "Clear layout",
            onClick: (model) => {
              if (
                !confirm(
                  "Are you sure you want to clear the layout? This action cannot be undone.",
                )
              ) {
                return;
              }
              const ids: string[] = [];
              model?.visitNodes((node: Node, level: number) => {
                if (node.getType() === "tabset") {
                  ids.push(node.getId());
                }
              });
              ids.forEach((id) => {
                model?.doAction(Actions.deleteTabset(id));
              });
            },
            shortcut: <i className="fa-regular fa-broom-wide" />,
          },
          {
            label: "Clear storage",
            onClick: () => {
              if (
                !confirm(
                  "Are you sure you want to clear the layout? This action cannot be undone.",
                )
              ) {
                return;
              }
              window.localStorage.removeItem("sf-state");
              sessionStorage.removeItem("bybitSymbols");
              sessionStorage.removeItem("binanceSymbols");
              window.location.reload();
            },
            className: "text-red-900",
            shortcut: <i className="fa-regular fa-refresh" />,
          },
        ],
      },
    ],
  },
  {
    label: "Marketplace",
    // className: "text-[#FFA028]",
    // onClick: () => {
    //   window.open("https://marketplace.spreadfighter.com", "_blank");
    // },
  },
];

export function DashboardHeader() {
  const { user, logout } = useAuth();
  const { user: userStore } = useUserStore();

  function stripUid(uid: string) {
    return uid.slice(0, 4) + "..." + uid.slice(-4);
  }

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      description: text,
    });
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <Logo className="w-8 h-8" />
        <HeaderMenu />
        <RTT />
        <Price />
        <button
          className={cn(
            "px-1.5 h-10 flex items-center justify-center text-xs rounded-md border mx-2 w-20 text-center",
            "text-white border-white",
          )}
          data-featurebase-feedback
        >
          Feedback
        </button>
      </div>
      <div className="p-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="relative h-8 w-8 rounded-full">
              <Avatar className="h-8 w-8">
                <AvatarImage
                  src={user?.photoURL || ""}
                  alt={user?.displayName || ""}
                />
                <AvatarFallback>{user?.displayName?.charAt(0)}</AvatarFallback>
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-48" align="end" forceMount>
            <DropdownMenuItem asChild className="text-xs">
              <a
                href="https://ru.spreadfighter.wiki"
                target="_blank"
                rel="noopener noreferrer"
              >
                Wiki
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-xs">Profile</DropdownMenuItem>
            <DropdownMenuItem onClick={logout} className="text-xs">
              Logout
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <div className="px-2 py-1.5 text-sm text-muted-foreground text-center">
              <div className="text-xxs">Version: {APP_VERSION}</div>
              <div className="text-xxs flex items-center justify-center">
                UID: {stripUid(user?.uid || "")}
                <i
                  className="fa-regular fa-copy ml-1 cursor-pointer"
                  onClick={() => copyToClipboard(user?.uid || "")}
                />
              </div>
              <div className="text-xxs flex items-center justify-center">
                Plan: {userStore?.role || "Free"}
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

function HeaderMenu() {
  const { grid, model } = useGrid();
  const { user } = useUserStore();

  const addWidget = (widgetOptions: IWidgetOptions) => {
    // Count current widgets
    let currentWidgets = 0;
    model?.visitNodes((node: Node) => {
      if (node.getType() === "tab") {
        currentWidgets++;
      }
    });

    const userRole = user?.role || "BASIC";
    const maxWidgets = MAX_WIDGETS_PER_PLAN[userRole.toUpperCase()];

    if (currentWidgets >= maxWidgets) {
      toast({
        title: "Widget limit reached",
        description: `Your ${userRole} plan allows up to ${maxWidgets} widgets. Upgrade your plan for more widgets.`,
        variant: "destructive",
      });
      return;
    }

    grid?.addTabWithDragAndDrop("Drag me! Esc for cancel", widgetOptions, () =>
      console.log("added"),
    );
  };

  const onClick = (menu: MenuItem) => {
    if (menu.onClick) {
      menu.onClick(model);
    } else if (menu.widgetType) {
      addWidget(widgets[menu.widgetType]);
    } else {
      return;
    }
  };

  const renderMegaMenuContent = (items: MenuItem[]) => {
    return (
      <div
        className="flex flex-col flex-wrap gap-x-8 p-4"
        style={{ maxHeight: "600px" }}
      >
        {items.map((section, index) => (
          <div key={index} className="min-w-[180px] mb-4">
            {/* Column Header */}
            <div className="font-semibold text-xs text-muted-foreground uppercase tracking-wider mb-1 px-2">
              {section.label}
            </div>
            {/* Column Items */}
            <div className="space-y-0.5">
              {section.items?.map((item, itemIndex) => {
                const isInactive =
                  (!item.widgetType && !item.onClick) ||
                  (item.widgetType &&
                    !hasAccessToWidget(user?.role || "BASIC", item.widgetType));

                return (
                  <MenubarItem
                    key={itemIndex}
                    onClick={() => onClick(item)}
                    disabled={isInactive}
                    className={cn(
                      "w-full flex items-center justify-between px-2 py-1 cursor-pointer rounded-sm",
                      item.className,
                      isInactive && "opacity-50 cursor-not-allowed",
                    )}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-1.5">
                        {item.shortcut && (
                          <span className="text-muted-foreground">
                            {item.shortcut}
                          </span>
                        )}
                        <span className="text-xs">{item.label}</span>
                      </div>

                      {isInactive && item.plan && (
                        <Badge className="text-yellow-400" variant="secondary">
                          <span className="text-xxs">{item.plan}</span>
                        </Badge>
                      )}
                    </div>
                  </MenubarItem>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Menubar>
      {menuItems.map((menu, index) => {
        const isInactive =
          (!menu.items?.length && !menu.widgetType && !menu.onClick) ||
          (menu.widgetType &&
            !hasAccessToWidget(user?.role || "BASIC", menu.widgetType));
        return (
          <MenubarMenu key={index}>
            <MenubarTrigger disabled={isInactive} onClick={() => onClick(menu)}>
              {menu.label}
            </MenubarTrigger>
            {menu.items && (
              <MenubarContent className="w-[1000px] p-0">
                {renderMegaMenuContent(menu.items)}
              </MenubarContent>
            )}
          </MenubarMenu>
        );
      })}
    </Menubar>
  );
}
