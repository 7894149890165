// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  FilledAreaType,
  LineStudyPlotStyle,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  GetPriceSeries,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const movingAverage = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Moving average",
    metainfo: {
      _metainfoVersion: 51,
      id: "moving-average@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Moving average",
      shortDescription: "[SF] MA",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "fastMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "fastMaColorer",
          type: StudyPlotType.Colorer,
          target: "fastMaPlot",
          palette: "linePalette",
        },
        {
          id: "slowMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "slowMaColorer",
          type: StudyPlotType.Colorer,
          target: "slowMaPlot",
          palette: "linePalette",
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.Colorer,
          target: "backgroundPlot",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "fastMaPlot",
          objBId: "slowMaPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        linePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Bearish" },
            1: { name: "Bullish" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Bearish" },
            1: { name: "Bullish" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0)",
            visible: true,
            transparency: 100,
          },
        },
        palettes: {
          linePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.1)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.1)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          fastMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 100,
          },
          fastMaColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
          },
          slowMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 100,
          },
          slowMaColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
          },
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 100,
          },
          backgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(243, 60, 48, 1)",
          },
        },
        precision: 2,
        inputs: {
          fastSourceInput: "close",
          fastLengthInput: 20,
          fastMaTypeInput: "SMA",
          slowSourceInput: "close",
          slowLengthInput: 240,
          slowMaTypeInput: "SMA",
        },
      },
      styles: {
        fastMaPlot: {
          title: "Fast MA",
          histogramBase: 0,
        },
        slowMaPlot: {
          title: "Slow MA",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "fastSourceInput",
          name: "Fast MA source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "fastLengthInput",
          name: "Fast MA length",
          defval: 20,
          type: StudyInputType.Integer,
        },
        {
          id: "fastMaTypeInput",
          name: "Type of fast MA",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "OIWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "slowSourceInput",
          name: "Slow MA source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "slowLengthInput",
          name: "Slow MA length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "slowMaTypeInput",
          name: "Type of slow MA",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "OIWMA", "LSMA"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [
          fastSourceInput,
          fastLengthInput,
          fastMaTypeInput,
          slowSourceInput,
          slowLengthInput,
          slowMaTypeInput,
        ]: [string, number, string, string, number, string] = Array.from(
          { length: 6 },
          (_, i) => this._input(i),
        );

        // Market data parsing
        const fastSourceSeries: any = GetPriceSeries(
          PineJS,
          fastSourceInput,
          this._context,
        );
        const slowSourceSeries: any = GetPriceSeries(
          PineJS,
          slowSourceInput,
          this._context,
        );

        // Calculations
        const fastMa: number = MovingAverage(
          PineJS,
          fastSourceSeries,
          fastMaTypeInput,
          fastLengthInput,
          this._context,
        );
        const slowMa: number = MovingAverage(
          PineJS,
          slowSourceSeries,
          slowMaTypeInput,
          slowLengthInput,
          this._context,
        );

        const color: number = fastMa > slowMa ? 1 : 0;

        //Return
        return [fastMa, color, slowMa, color, color];
      };
    },
  };
};
