//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const tape = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Tape",
    metainfo: {
      _metainfoVersion: 51,
      id: "tape@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Tape",
      shortDescription: "[SF] Tape",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "tapePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          tapePlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          maPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          displayModeInput: "Total",
          measureInput: "Trade count",
          maTypeInput: "SMA",
          lengthInput: 20,
          metricsBoolInput: false,
        },
      },
      styles: {
        tapePlot: {
          title: "Tape",
          histogramBase: 0,
        },
        maPlot: {
          title: "Tape MA",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "displayModeInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Total",
          options: ["Trade count", "Speed (tpm)"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const tapeSymbol: string = `${ParseSymbol(symbol)}${"#SF_VOLUME"}`;
        this._context.new_sym(tapeSymbol, period);
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number] {
        this._context = context;
        this._input = inputCallback;

        // Getting user`s input data
        const [displayMode, measure, maType, length, showMetrics]: [
          string,
          string,
          string,
          number,
          boolean,
        ] = [1, 2, 3, 4, 5].map((i) => this._input(i));

        // Parsing market data
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        this._context.select_sym(2);
        const tapeTime: any = this._context.new_var(this._context.symbol.time);
        const tapeArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const buyVolumeArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(1);
        const volumeTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const volumeArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(0);
        let tape: number = tapeArray.adopt(tapeTime, mainSymbolTime, 0);

        // Calculations
        if (measure === "Speed (tpm)") {
          tape /= CONSTANTS.MINUTES_IN_DAY / PineJS.Std.period(this._context);
        }

        let buyVolume: number, volume: number;
        switch (displayMode) {
          case "Buy":
            buyVolume = buyVolumeArray.adopt(tapeTime, mainSymbolTime, 0);
            volume = volumeArray.adopt(tapeTime, mainSymbolTime, 0);
            tape *= buyVolume / volume;
            break;
          case "Sell":
            buyVolume = buyVolumeArray.adopt(tapeTime, mainSymbolTime, 0);
            volume = volumeArray.adopt(tapeTime, mainSymbolTime, 0);
            tape *= (volume - buyVolume) / volume;
            break;
          case "Delta":
            buyVolume = buyVolumeArray.adopt(tapeTime, mainSymbolTime, 0);
            volume = volumeArray.adopt(tapeTime, mainSymbolTime, 0);
            tape *= (2 * buyVolume - volume) / volume;
            break;
          default:
            break;
        }

        const tapeSeries: any = this._context.new_var(tape);
        const tapeMa: number = showMetrics
          ? MovingAverage(PineJS, tapeSeries, maType, length, this._context)
          : NaN;

        // Returning results
        return [tape, tapeMa];
      };
    },
  };
};
