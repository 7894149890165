//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  GetPriceSeries,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const bollingerBands = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Bollinger bands",
    metainfo: {
      _metainfoVersion: 51,
      id: "bollinger-bands@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Bollinger bands",
      shortDescription: "[SF] BB",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "maPlot", type: StudyPlotType.Line },
        { id: "upperBandPlot", type: StudyPlotType.Line },
        { id: "lowerBandPlot", type: StudyPlotType.Line },
      ],
      filledAreas: [
        {
          id: "Background",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        filledAreasStyle: {
          Background: {
            color: "rgba(60, 166, 75, 0.1)",
            visible: true,
            transparency: 20,
          },
        },
        styles: {
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 40,
          },
          upperBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
          lowerBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "close",
          maTypeInput: "SMA",
          lengthInput: 240,
          sigmaMltInput: 3.0,
        },
      },
      styles: {
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "MA type",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "sigmaMltInput",
          name: "Sigma multiplier",
          defval: 3.0,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [sourceInput, maTypeInput, lengthInput, sigmaMltInput]: [
          string,
          string,
          number,
          number,
        ] = Array.from({ length: 4 }, (_, i) => this._input(i));

        //Market data parsing
        const sourceSeries: any = GetPriceSeries(
          PineJS,
          sourceInput,
          this._context,
        );

        //Calculaionsz
        const ma: number = MovingAverage(
          PineJS,
          sourceSeries,
          maTypeInput,
          lengthInput,
          this._context,
        );
        const sigma: number = PineJS.Std.stdev(
          sourceSeries,
          lengthInput,
          this._context,
        );

        const upperBand: number = ma + sigmaMltInput * sigma;
        const lowerBand: number = ma - sigmaMltInput * sigma;

        //Returns
        return [ma, upperBand, lowerBand];
      };
    },
  };
};
