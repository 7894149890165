import { useDashboard } from "@/lib/store.ts";
import React, { useCallback, useMemo, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { useBinanceSymbols } from "@/hooks/useBinanceSymbols.ts";
import Select, { SingleValue } from "react-select";
import { cn } from "@/lib/utils.ts";
import { useToast } from "@/components/ui/use-toast.ts";
import { useFavoriteFolders } from "@/hooks/useFavoriteFolders.ts";
import { FaFolderPlus } from "react-icons/fa6";

export function AddFolder() {
  const { folders, addFavoriteFolder, availableColors, canAddMoreFolders } =
    useFavoriteFolders();

  const { toast } = useToast();
  const handleAddFolderClick = useCallback(() => {
    if (!canAddMoreFolders) {
      toast({
        variant: "destructive",
        title: "You have reached the maximum number of folders",
      });
      return;
    }

    addFavoriteFolder({
      name: "New Folder #" + Number(folders.length + 1),
      color: availableColors[0],
      expanded: true,
    });
  }, [
    addFavoriteFolder,
    availableColors,
    canAddMoreFolders,
    folders.length,
    toast,
  ]);

  return (
    <div className="flex">
      <Button
        size="xs"
        onClick={handleAddFolderClick}
        className="flex gap-2 items-center
      "
      >
        <FaFolderPlus />
        Add Folder
      </Button>
    </div>
  );
}

// function AddFavoriteAlter({ folderId }: { folderId: string }) {
//   const { data: symbols } = useBinanceSymbols();
//   const { addFavorite, folder } = useDashboard((state) => ({
//     addFavorite: state.addSymbolsToFavorites,
//     folder: state.favorites.find((f) => f.id === folderId),
//   }));

//   const [value, setValue] = useState<SingleValue<{
//     label: string;
//     value: string;
//   }> | null>(null);
//   const options = useMemo(
//     () =>
//       symbols
//         ?.filter((e) => {
//           return !folder?.symbols?.includes(e.symbol);
//         })
//         .sort((a, b) => a.symbol.localeCompare(b.symbol))
//         .map((e) => {
//           return { label: e.symbol, value: e.symbol };
//         }),
//     [symbols, folder],
//   );

//   const handleAddFavorite = (
//     e: SingleValue<{ label: string; value: string }>,
//   ) => {
//     setValue(e);
//     if (e) {
//       addFavorite(folderId, [e.value]);
//       setValue(null);
//     }
//   };

//   function addAllHandler() {
//     const filtered = options?.reduce((acc: string[], e) => {
//       if (!folder?.symbols.includes(e.value)) {
//         acc.push(e.value);
//       }
//       return acc;
//     }, []);
//     if (filtered) {
//       addFavorite(folderId, filtered);
//     }
//   }

//   return (
//     <div className="inline-flex gap-2 items-center">
//       <Select
//         closeMenuOnSelect={true}
//         onChange={handleAddFavorite}
//         unstyled={true}
//         value={value}
//         blurInputOnSelect={true}
//         placeholder={options?.length ? "Add Favorite" : "This folder is full"}
//         isDisabled={!options?.length}
//         noOptionsMessage={() => "This folder has all symbols"}
//         options={options}
//         classNames={{
//           clearIndicator: ({ isFocused }) =>
//             cn(
//               isFocused ? "text-white" : "text-gray-400",
//               "p-1", // Уменьшенные отступы
//               isFocused ? "hover:text-white" : "hover:text-gray-500",
//             ),
//           container: () => cn("w-36 text-xs"), // Уменьшенная ширина
//           control: ({ isDisabled, isFocused }) =>
//             cn(
//               "h-6 !min-h-6 max-h-6",
//               isDisabled ? "bg-neutral-800" : "bg-neutral-900",
//               isDisabled
//                 ? "border-neutral-700"
//                 : isFocused
//                   ? "border-purple-600"
//                   : "border-neutral-700",
//               "rounded",
//               "border-solid",
//               "border",
//               isFocused && "shadow-outline-purple",
//               isFocused
//                 ? "hover:border-purple-600"
//                 : "hover:border-neutral-600",
//               "", // Уменьшенная высота
//               "py-0", // Уменьшенные вертикальные отступы
//             ),
//           dropdownIndicator: ({ isFocused }) =>
//             cn(
//               isFocused ? "text-white" : "text-gray-400",
//               isFocused ? "hover:text-white" : "hover:text-gray-500",
//             ),
//           group: () => cn("py-1"), // Уменьшенные отступы
//           groupHeading: () =>
//             cn(
//               "text-gray-500",
//               "text-xxs", // Еще меньший размер текста
//               "font-medium",
//               "mb-1",
//               "px-2", // Уменьшенные горизонтальные отступы
//               "uppercase",
//             ),
//           // indicatorSeparator: ({ isDisabled }) =>
//           //   cn(isDisabled ? "bg-neutral-700" : "bg-neutral-600", "my-1"),
//           indicatorSeparator: () => null as any,
//           input: () => cn("m-0.5", "py-0.5", "text-xs"), // Меньший размер текста
//           loadingIndicator: ({ isFocused }) =>
//             cn(isFocused ? "text-white" : "text-gray-400", "p-1"), // Уменьшенные отступы
//           loadingMessage: () => cn("text-gray-500", "text-xs", "py-1", "px-2"), // Меньший размер текста и отступы
//           menu: () => cn("bg-neutral-900 rounded shadow-lg my-1 !z-50"),
//           menuList: () => cn("py-1"),
//           multiValue: () =>
//             cn("bg-neutral-700", "rounded-sm", "m-0.5", "text-xs"), // Меньший размер текста
//           multiValueLabel: () =>
//             cn("rounded-sm", "text-white", "text-xs", "p-1", "pl-2"), // Меньший размер текста и отступы
//           multiValueRemove: ({ isFocused }) =>
//             cn(
//               "rounded-sm",
//               isFocused ? "bg-red-700" : "bg-neutral-700",
//               "px-1",
//               "hover:bg-red-700",
//               "hover:text-white",
//               "text-xs", // Меньший размер текста
//             ),
//           noOptionsMessage: () =>
//             cn("text-gray-500", "text-xs", "py-1", "px-2"), // Меньший размер текста и отступы
//           option: ({ isDisabled, isFocused, isSelected }) =>
//             cn(
//               isSelected
//                 ? "bg-purple-600"
//                 : isFocused
//                   ? "bg-purple-500"
//                   : "bg-transparent",
//               isDisabled
//                 ? "text-neutral-400"
//                 : isSelected
//                   ? "text-white"
//                   : "text-neutral-200",
//               "!text-xs", // Меньший размер текста
//               "py-1", // Уменьшенные отступы
//               "px-2", // Уменьшенные горизонтальные отступы
//               !isDisabled &&
//                 (isSelected ? "active:bg-purple-600" : "active:bg-purple-400"),
//             ),
//           placeholder: () => cn("text-neutral-400", "text-xs", "mx-0.5"), // Меньший размер текста
//           singleValue: ({ isDisabled }) =>
//             cn(
//               isDisabled ? "text-neutral-400" : "text-white",
//               "text-xs",
//               "mx-0.5",
//             ), // Меньший размер текста
//           valueContainer: () => cn("py-0", "px-2", ""), // Уменьшенные отступы и высота
//         }}
//         menuPortalTarget={document.body}
//         menuPosition={"absolute"}
//       />
//       <Button onClick={addAllHandler} size="xs" disabled={!options?.length}>
//         Add All
//       </Button>
//     </div>
//   );
// }
