//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const averageDayTradeVolume = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Average day trade volume",
    metainfo: {
      _metainfoVersion: 51,
      id: "average-day-trade-volume@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Average day trade volume",
      shortDescription: "[SF] ADTV",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "adtvPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          adtvPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          sideInput: "Total",
          measureInput: "USD",
        },
      },
      styles: {
        adtvPlot: {
          title: "ADTV",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "sideInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);
        const volumeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const sideInput: string = this._input(1);

        // Market data parsing
        this._context.select_sym(1);
        const volumeData: { time: any; volume: any } = {
          time: this._context.new_var(this._context.symbol.time),
          volume: this._context.new_var(PineJS.Std.volume(this._context)),
        };

        this._context.select_sym(2);
        const buyVolumeData: { time: any; volume: any } = {
          time: this._context.new_var(this._context.symbol.time),
          volume: this._context.new_var(PineJS.Std.low(this._context)),
        };

        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Calculations
        const totalVolume: number = volumeData.volume.adopt(
          volumeData.time,
          mainSymbolTime,
          0,
        );
        const buyVolume: number = buyVolumeData.volume.adopt(
          buyVolumeData.time,
          mainSymbolTime,
          0,
        );

        const source: number = (() => {
          switch (sideInput) {
            case "Buy":
              return buyVolume;
            case "Sell":
              return totalVolume - buyVolume;
            case "Delta":
              return 2 * buyVolume - totalVolume;
            default:
              return totalVolume; // Включает случай 'Total'
          }
        })();

        const sourceSeries: any = this._context.new_var(source);
        const MINUTES_IN_DAY: number = 1440;
        const period: number = PineJS.Std.period(this._context);
        const length: number = MINUTES_IN_DAY / Number(period);

        // Return values
        return [PineJS.Std.sum(sourceSeries, length, this._context) / length];
      };
    },
  };
};
