import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { AddFavoriteAlt } from "@/components/widgets/controlPanel/favorites/addFavoriteAlt.tsx";
import { FolderName } from "@/components/widgets/controlPanel/favorites/folderName.tsx";
import { MoveFavorite } from "@/components/widgets/controlPanel/favorites/moveFavorite.tsx";
import { RemoveFavorite } from "@/components/widgets/controlPanel/favorites/removeFavorite.tsx";
import { RemoveFolder } from "@/components/widgets/controlPanel/favorites/removeFolder.tsx";
import { ToggleExpand } from "@/components/widgets/controlPanel/favorites/toggleExpand.tsx";
import { IFavoriteFolder } from "@/domain/interfaces/dashboard.interface.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useDashboard } from "@/lib/store.ts";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { notUndefined, useVirtualizer } from "@tanstack/react-virtual";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { cn, prepareFormula } from "@/lib/utils.ts";
import {
  IAggregatedConstructor,
  IAggregatedData,
  useAggregatedConstructor,
} from "@/hooks/useAggregatedConstructor.ts";
import { TableRowRenderer } from "@/components/shared/tableRowRenderer.tsx";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { PriceData, useBinancePrices } from "@/hooks/useBinancePrices.ts";
import { HiArrowDown, HiArrowUp } from "react-icons/hi2";
import { MarketRatio } from "@/components/shared/marketRatio.tsx";
import { Ratio } from "@/components/shared/ratio.tsx";
import { IMarketRatio, useMarketRatioAll } from "@/hooks/useMarketRatioAll.ts";
import { SelectColumns } from "./selectColumns";
import {
  ICryptoDrift,
  ICryptoDriftResponse,
  useCryptoDrift,
} from "@/hooks/useCryptoDrift";
import {
  IActivityDetector,
  IActivityDetectorResponse,
  useActivityDetector,
} from "@/hooks/useActivityDetector";
import { useMarketPower } from "@/hooks/useMarketPower";
import { useRetailPower } from "@/hooks/useRetailPower";
import {
  IParam,
  MarketPower,
  MarketPowerItem,
  RetailPower,
  RetailPowerItem,
} from "@/domain/interfaces/general.interface";
import Formula from "fparser";
import { FillAll } from "./fillAll";

type IFolderRow = IFavoriteFolder & {
  isFolder: boolean;
};
type ISymbolRow = IAggregatedConstructor & {
  name: string;
  folderId: string | null;
  isPinned: boolean;
  folderColor?: string;
  currentPrice: PriceData;
  marketRatio: IMarketRatio;
  cryptoDrift: ICryptoDrift;
  activityDetector: IActivityDetector;
  marketPower: MarketPowerItem;
  retailPower: RetailPowerItem;
};
type IFavoritesRow = Partial<IFolderRow & ISymbolRow>;

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};

function RemovePinned({ symbolName }: { symbolName: string }) {
  const { updatePinned } = useDashboard((state) => ({
    updatePinned: state.updatePinned,
  }));

  const handleRemovePinned = useCallback(() => {
    updatePinned((state) => {
      state.forEach(
        (widget) =>
          (widget.symbols = widget.symbols.filter((s) => s !== symbolName)),
      );
    });
  }, [symbolName, updatePinned]);
  return (
    <Button
      size="xs"
      variant="ghost"
      onClick={handleRemovePinned}
      className="w-4 h-4"
    >
      <i className="fa-regular fa-trash" />
    </Button>
  );
}

const FolderRow = memo(function FolderRow({
  row,
  size,
}: {
  row: Row<IFolderRow>;
  size: number;
}) {
  const { color, id } = row.original;
  return (
    <TableRow
      key={row.id}
      style={{
        height: `${size}px`,
        borderLeftColor: color,
      }}
      className="border-l-4 bg-transparent border-b-neutral-700"
    >
      <TableCell colSpan={row.getVisibleCells().length}>
        <div className="flex w-full justify-between h-[17px]">
          <div className="flex items-center">
            <ToggleExpand folderId={id} />
            <FolderName folderId={id} />
          </div>
          <div className="flex gap-1 items-center justify-end">
            <FillAll folderId={id} />
            <AddFavoriteAlt folderId={id} />
            <RemoveFolder folderId={id} />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
});
const SymbolRow = memo(function SymbolRow({
  row,
  size,
}: {
  row: Row<ISymbolRow>;
  size: number;
}) {
  const { folderColor, name: symbol } = row.original;
  const { setSymbolForColor, colorsToSymbolMap } = useDashboard((state) => ({
    setSymbolForColor: state.setSymbolForColor,
    colorsToSymbolMap: state.colorsToSymbolMap,
  }));
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);

  const color = getNodeConfig().color;
  const nodeSymbol = colorsToSymbolMap[color];
  return (
    <TableRow
      key={row.id}
      style={{
        height: `${size}px`,
        borderLeftColor: folderColor,
      }}
      className={cn(
        "border-l-4 bg-transparent cursor-pointer text-right border-b-neutral-700",
        symbol === nodeSymbol && "bg-[mediumblue] active",
      )}
      onClick={() => {
        copyToClipboard(symbol);
        setSymbolForColor(color, symbol as string);
      }}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <TableCell
            key={cell.id}
            style={{
              maxWidth: cell.column.columnDef.maxSize,
              width: cell.column.columnDef.size,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </TableRow>
  );
});

function getPrice(
  prices: Record<string, PriceData> | undefined,
  symbol: string,
) {
  if (!prices) {
    return;
  }

  const calculator = new Formula(prepareFormula(symbol));

  const priceParams: IParam = {};

  for (const key in prices) {
    priceParams[key] = prices[key].price;
  }

  const price = calculator.evaluate(priceParams);

  return {
    priceChange: 0,
    priceChangePrc: 0,
    closeQty: 0,
    baseVolume: 0,
    quoteVolume: 0,
    price: Array.isArray(price)
      ? Number(price[0].toFixed(3))
      : Number(price.toFixed(3)),
  };
}

function getVolumeQuote(
  aggregatedData: IAggregatedData | undefined,
  symbol: string,
) {
  if (!aggregatedData) {
    return {
      "15m": 0,
      "30m": 0,
      "1h": 0,
      "4h": 0,
      "1d": 0,
      "1w": 0,
      "1M": 0,
    } as IAggregatedConstructor["volumeQuote"];
  }

  const usdtCount = symbol.split("/").filter((s) => s === "USDT").length;
  if (usdtCount > 1) {
    return {
      "15m": 0,
      "30m": 0,
      "1h": 0,
      "4h": 0,
      "1d": 0,
      "1w": 0,
      "1M": 0,
    } as IAggregatedConstructor["volumeQuote"];
  }
  return aggregatedData[symbol]?.volumeQuote;
}

function getCryptoDrift(
  cryptoDrift: ICryptoDriftResponse | undefined,
  symbol: string,
) {
  const usdtCount = symbol.split("/").filter((s) => s === "USDT").length;
  if (usdtCount > 1) {
    return { symbol, value: 0 };
  }
  const item = cryptoDrift?.data.find((s) => s.symbol === symbol);
  if (!item) {
    return { symbol, value: 0 };
  }
  return item;
}

function getActivityDetector(
  activityDetector: IActivityDetectorResponse | undefined,
  symbol: string,
) {
  const usdtCount = symbol.split("/").filter((s) => s === "USDT").length;
  if (usdtCount > 1) {
    return { symbol, value: 0 };
  }
  const item = activityDetector?.data.find((s) => s.symbol === symbol);
  if (!item) {
    return { symbol, value: 0 };
  }
  return item;
}

function getMarketPower(marketPower: MarketPower | undefined, symbol: string) {
  // calculate USDT strings in symbol, if more than 1 than return 0
  const usdtCount = symbol.split("/").filter((s) => s === "USDT").length;
  if (usdtCount > 1) {
    return { symbol, value: 0 };
  }

  if (!marketPower || !marketPower.data) {
    return { symbol, value: 0 };
  }

  const item = marketPower?.data.find((s) => s.symbol === symbol);
  if (!item) {
    return { symbol, value: 0 };
  }
  return item;
}

function getRetailPower(retailPower: RetailPower | undefined, symbol: string) {
  const item = retailPower?.data.find((s) => s.symbol === symbol);
  if (!item) {
    return { symbol, value: 0 };
  }
  return item;
}

export function FavoritesInner() {
  const [sorting, setSorting] = useState<SortingState>([]);

  const { favorites, pinned } = useDashboard((state) => ({
    favorites: state.favorites,
    pinned: state.pinned,
  }));

  const uniqueSymbolNames = useMemo(() => {
    return [
      ...new Set(
        [...favorites, ...pinned]
          .map((f) => f.symbols)
          .flat()
          .reduce((acc: string[], e: string) => {
            if (
              e.includes("/") ||
              e.includes("+") ||
              e.includes("-") ||
              e.includes("*")
            ) {
              e.split(/[/+\-*]/).forEach((part) => acc.push(part.trim()));
            } else {
              acc.push(e);
            }
            return acc;
          }, []),
      ),
    ];
  }, [favorites, pinned]);

  const { data: aggregatedData } = useAggregatedConstructor(uniqueSymbolNames);
  const { data: prices } = useBinancePrices();
  const { data: marketRatio } = useMarketRatioAll(uniqueSymbolNames);
  const { data: cryptoDrift } = useCryptoDrift();
  const { data: activityDetector } = useActivityDetector();
  const { data: marketPower } = useMarketPower();
  const { data: retailPower } = useRetailPower();

  const pinnedAggregated = useMemo(
    () =>
      pinned.reduce((acc, e) => {
        e.symbols.forEach((symbol) => acc.add(symbol));
        return acc;
      }, new Set() as Set<string>),
    [pinned],
  );

  const nodeId = usePaneContext();
  const { node } = useGrid(nodeId);

  const data: IFavoritesRow[] = useMemo(() => {
    return [...pinnedAggregated, ...favorites].reduce(
      (acc: IFavoritesRow[], folder) => {
        if (typeof folder === "string") {
          acc.push({
            ...aggregatedData?.[folder],
            volumeQuote: getVolumeQuote(aggregatedData, folder),
            currentPrice: getPrice(prices, folder),
            marketRatio: marketRatio?.[folder],
            name: folder,
            isPinned: true,
            folderId: null,
          });
        } else {
          acc.push({
            ...folder,
            isFolder: true,
            name: folder.name,
          });
          if (folder.expanded) {
            acc.push(
              ...folder.symbols.map((symbol) => {
                return {
                  ...aggregatedData?.[symbol],
                  volumeQuote: getVolumeQuote(aggregatedData, symbol),
                  currentPrice: getPrice(prices, symbol),
                  marketRatio: marketRatio?.[symbol],
                  cryptoDrift: getCryptoDrift(cryptoDrift, symbol),
                  activityDetector: getActivityDetector(
                    activityDetector,
                    symbol,
                  ),
                  marketPower: getMarketPower(marketPower, symbol),
                  retailPower: getRetailPower(retailPower, symbol),
                  ...folder,
                  name: symbol,
                  folderId: folder.id,
                  isPinned: false,
                  folderColor: folder.color,
                };
              }),
            );
          }
        }
        return acc;
      },
      [],
    );
  }, [aggregatedData, favorites, marketRatio, pinnedAggregated, prices]);

  const { favoritesSettings } = useDashboard((state) => ({
    favoritesSettings: state.favoritesSettings,
  }));

  const dynamicColumns = useMemo(() => {
    const columns: ColumnDef<IFavoritesRow>[] = [];
    for (let i = 0; i < favoritesSettings.activeColumns.length; i++) {
      const col = favoritesSettings.activeColumns[i];
      const column = col.split(".")[0];
      const timeFrame = col.split(".")[1];
      const timeFrameTitle =
        timeFrame === "1M" ? "Mo " : timeFrame?.toUpperCase().slice(1) + " ";

      switch (true) {
        case col.includes("priceChg."):
          columns.push({
            accessorFn: (originalRow) =>
              (originalRow as any)[column]?.[timeFrame],
            header: timeFrameTitle + "Pr %Chg",
            size: 60,
            enableSorting: true,
            cell: (info) => (
              <TableRowRenderer>
                <HighlightedValueChange
                  value={info.getValue() as number}
                  decimal={2}
                />
              </TableRowRenderer>
            ),
          });
          break;

        case col.includes("openInterestChg."):
          columns.push({
            accessorFn: (originalRow) =>
              (originalRow as any)[column]?.[timeFrame],
            header: timeFrameTitle + "OI %Chg",
            size: 60,
            enableSorting: true,
            cell: (info) => (
              <TableRowRenderer>
                <HighlightedValueChange
                  value={info.getValue() as number}
                  decimal={2}
                />
              </TableRowRenderer>
            ),
          });
          break;

        case col.includes("volumeChg."):
          columns.push({
            accessorFn: (originalRow) =>
              (originalRow as any)[column]?.[timeFrame],
            header: timeFrameTitle + "V %Chg",
            size: 60,
            enableSorting: true,
            cell: (info) => (
              <TableRowRenderer>
                <HighlightedValueChange
                  value={info.getValue() as number}
                  decimal={2}
                />
              </TableRowRenderer>
            ),
          });
          break;

        case col.includes("manipulationMonitor."):
          columns.push({
            accessorFn: (originalRow) =>
              (originalRow as any)[column]?.[timeFrame],
            header: timeFrame === "corr" ? "Ma P" : "Ma V",
            size: 60,
            enableSorting: true,
            cell: (info) => (
              <TableRowRenderer>
                <HighlightedValueChange
                  value={info.getValue() as number}
                  decimal={2}
                />
              </TableRowRenderer>
            ),
          });
          break;

        case col.includes("spotFuturesSpread."): {
          const isFunding = timeFrame === "funding";
          columns.push({
            accessorFn: (originalRow) =>
              isFunding
                ? originalRow.funding
                : originalRow.spotFutureSpread?.value,
            header: isFunding ? "Funding %" : "Spread",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              return (
                <TableRowRenderer>
                  <HighlightedValueChange
                    highlightPosNeg={false}
                    value={
                      isFunding
                        ? (info.getValue() as number) * 100
                        : (info.getValue() as number)
                    }
                    decimal={isFunding ? 3 : 2}
                  />
                </TableRowRenderer>
              );
            },
          });
          break;
        }

        case col.includes("buySellRatioChg."):
          columns.push({
            accessorFn: (originalRow) =>
              (originalRow as any)[column]?.[timeFrame],
            header: timeFrameTitle + "BvS %Chg",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const val = info.getValue() as number;
              if (!val) {
                return null;
              }
              return (
                <div className="flex justify-end">
                  <Ratio
                    leftValue={val}
                    rightValue={100 - val}
                    height={17}
                    width={60}
                  />
                </div>
              );
            },
          });
          break;
        case col.includes("marketRatio."):
          columns.push({
            accessorFn: (originalRow) => {
              const marketRatioTimeFrames = (
                originalRow[column as keyof typeof originalRow] as IMarketRatio
              )?.data;
              const marketRatio =
                marketRatioTimeFrames?.[
                  timeFrame as keyof typeof marketRatioTimeFrames
                ];
              return marketRatio?.value;
            },
            header: timeFrameTitle.replace("1", "") + "Market R%",
            size: 80,
            enableSorting: true,
            cell: (info) => {
              const marketRatioTimeFrames = (
                info.row.original[
                  column as keyof typeof info.row.original
                ] as IMarketRatio
              )?.data;

              const marketRatio =
                marketRatioTimeFrames?.[
                  timeFrame as keyof typeof marketRatioTimeFrames
                ];

              if (!marketRatio) {
                return null;
              }
              return <MarketRatio data={marketRatio} height={17} width={60} />;
            },
          });
          break;
        case col === "funding":
          columns.push({
            accessorKey: "funding",
            header: "Funding %",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const val = (info.getValue() as number) * 100;
              return (
                <TableRowRenderer>
                  <HighlightedValueChange value={val} decimal={4} />
                </TableRowRenderer>
              );
            },
          });
          break;
        case col === "openInterest":
          columns.push({
            accessorKey: "openInterest",
            header: "OI $",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const val = info.getValue() as number;
              return (
                <TableRowRenderer>
                  <HighlightedValueChange
                    value={val}
                    highlightPosNeg={false}
                    shorten={true}
                  />
                </TableRowRenderer>
              );
            },
          });
          break;
        case col === "cryptoDrift":
          columns.push({
            accessorKey: "cryptoDrift",
            header: "Crypto Drift",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const row = info.row.original.cryptoDrift as ICryptoDrift;
              return (
                <TableRowRenderer>
                  <HighlightedValueChange
                    value={row.value}
                    highlightPosNeg={true}
                    shorten={true}
                  />
                </TableRowRenderer>
              );
            },
          });
          break;
        case col === "activityDetector":
          columns.push({
            accessorKey: "activityDetector",
            header: "Activity Detector",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const row = info.row.original
                .activityDetector as IActivityDetector;
              return (
                <TableRowRenderer>
                  <HighlightedValueChange
                    value={row.value}
                    highlightPosNeg={true}
                    shorten={true}
                  />
                </TableRowRenderer>
              );
            },
          });
          break;
        case col === "marketPower":
          columns.push({
            accessorKey: "marketPower",
            header: "Market Power",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const row = info.row.original.marketPower as MarketPowerItem;
              return (
                <TableRowRenderer>
                  <HighlightedValueChange
                    value={row.value}
                    highlightPosNeg={true}
                    shorten={true}
                  />
                </TableRowRenderer>
              );
            },
          });
          break;
        case col === "retailPower":
          columns.push({
            accessorKey: "retailPower",
            header: "Retail Power",
            size: 60,
            enableSorting: true,
            cell: (info) => {
              const row = info.row.original.retailPower as RetailPowerItem;
              return (
                <TableRowRenderer>
                  <HighlightedValueChange
                    value={row.value}
                    highlightPosNeg={true}
                    shorten={true}
                  />
                </TableRowRenderer>
              );
            },
          });
          break;
      }
    }

    return columns;
  }, [favoritesSettings]);

  const columns: ColumnDef<IFavoritesRow>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Symbol",
        size: 60,
        cell: (info) => (
          <TableRowRenderer
            className={cn(
              "pl-0.5 text-left",
              !info.row.original.folderColor && "text-yellow-300",
            )}
          >
            {info.getValue() as string}
          </TableRowRenderer>
        ),
        sorting: false,
      },
      {
        accessorFn: (originalRow) => originalRow.currentPrice?.price,
        header: "Pr",
        size: 80,
        maxSize: 80,
        minSize: 80,
        cell: (info) => {
          return (
            <TableRowRenderer>
              <HighlightedValueChange
                value={info.getValue() as number}
                highlightPosNeg={false}
              />
            </TableRowRenderer>
          );
        },
        sorting: false,
      },
      {
        accessorFn: (row) => row.volumeQuote?.["1d"] || 0,
        header: "24H Vol$",
        size: 80,
        maxSize: 80,
        minSize: 80,
        cell: (info) => {
          const val = info.getValue() as number;
          return (
            <TableRowRenderer>
              <HighlightedValueChange
                value={val}
                highlightPosNeg={false}
                shorten={true}
              />
            </TableRowRenderer>
          );
        },
        sorting: false,
      },
      ...dynamicColumns,
      {
        id: "actions",
        size: 60,
        maxSize: 60,
        header: () => (
          <div className="flex justify-end">
            <SelectColumns />
          </div>
        ),
        cell: (info) => {
          const row = info.row.original as ISymbolRow;

          return (
            <div className="w-full flex justify-end">
              <div className="flex justify-end max-w-[85px] gap-1">
                <MoveFavorite
                  sourceFolderId={row.folderId}
                  favoriteName={row.name}
                />
                {row.folderId ? (
                  <RemoveFavorite
                    folderId={row.folderId}
                    favoriteName={row.name}
                  />
                ) : (
                  <RemovePinned symbolName={row.name} />
                )}
              </div>
            </div>
          );
        },
        sorting: false,
      },
    ],
    [dynamicColumns],
  );

  const table = useReactTable({
    data,
    columns,
    enableSortingRemoval: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 17,
    overscan: 20,
  });
  const items = virtualizer.getVirtualItems();
  const [before, after] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0];
  const colSpan = 8;

  if (!node) {
    return null;
  }

  const height = node.getRect().height - 122;

  return (
    <div
      ref={parentRef}
      className="overflow-auto w-full"
      style={{
        height: `${height}px`,
      }}
    >
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: cn(
                            "text-xs",
                            header.column.getCanSort()
                              ? "flex cursor-pointer select-none items-center"
                              : "",
                            header.column.columnDef.header === "Symbol"
                              ? "justify-start"
                              : "justify-end",
                            header.column.columnDef.id === "actions"
                              ? "flex justify-end"
                              : "",
                          ),
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <span className="pl-1">
                          {{
                            asc: <HiArrowUp className="text-green-500" />,
                            desc: <HiArrowDown className="text-red-500" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </span>
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {before > 0 && (
            <TableRow>
              <td colSpan={colSpan} style={{ height: before }} />
            </TableRow>
          )}
          {items.map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<IFavoritesRow>;
            if (row.original.isFolder) {
              return (
                <FolderRow
                  key={row.id}
                  row={row as Row<IFolderRow>}
                  size={virtualRow.size}
                />
              );
            } else {
              return (
                <SymbolRow
                  key={row.id}
                  row={row as Row<ISymbolRow>}
                  size={virtualRow.size}
                />
              );
            }
          })}
          {after > 0 && (
            <TableRow>
              <td colSpan={colSpan} style={{ height: after }} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
