import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface ISizeScreener {
  timestamp: number;
  symbol: string;
  side: string;
  price: number;
  prc: number;
}

export function useSizeHighlighter() {
  const handler = useCallback(
    (currentData: ISizeScreener[] = [], newData: ISizeScreener) => {
      const updatedData = [...(currentData || []), newData];
      return updatedData.slice(-50); // Keep only last 50 items
    },
    [],
  );

  return useGenericSocket<ISizeScreener[], ISizeScreener>(
    SocketTopics.sizeScreener,
    handler,
  );
}
