import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import { IClassicResponse, useClassic } from "@/hooks/useClassic";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config";

const columnHelper = createColumnHelper<IClassicResponse>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("value", {
    header: "Value",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
];

export function ClassicScreenerPaneBody({ name }: { name: string }) {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const interval = BINANCE_RESOLUTION_MAP[config.interval];
  const { data: classicData, isLoading } = useClassic(interval, name);

  const preparedData = useMemo(() => {
    if (!classicData) {
      return [];
    }

    // transform to array of {symbol, value}
    let data = Object.entries(classicData).map(([symbol, value]) => ({
      symbol,
      value,
    }));

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IClassicResponse>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, classicData, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
