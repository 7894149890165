import {
  IPowerTrades,
  MarketPower,
  RetailPower,
} from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export function useRetailPower() {
  const handler = useCallback((oldData: RetailPower, newData: RetailPower) => {
    return newData;
  }, []);

  return useGenericSocket<RetailPower, RetailPower>(
    SocketTopics.retailPower,
    handler,
  );
}
