//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  GetPrice,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const zScore = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Z-score",
    metainfo: {
      _metainfoVersion: 51,
      id: "z-score@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Z-score",
      shortDescription: "[SF] Z-score",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "zScore",
          type: StudyPlotType.Line,
        },
        {
          id: "upperStDev",
          type: StudyPlotType.Line,
        },
        {
          id: "lowerStDev",
          type: StudyPlotType.Line,
        },
        {
          id: "zScoreColorer",
          type: StudyPlotType.Colorer,
          target: "zScore",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "Background",
          objAId: "upperStDev",
          objBId: "lowerStDev",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling Z-Score" },
            1: { name: "Growing Z-Score" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          Background: {
            color: "rgba(255, 255, 255, 0.05)",
            visible: true,
            transparency: 5,
          },
        },
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          zScore: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.AreaWithBreaks,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.75)",
          },
          upperStDev: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgba(255, 255, 255, 0.5)",
          },
          lowerStDev: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "close",
          maTypeInput: "SMA",
          length: 240,
          stdDevs: 3.0,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        zScore: {
          title: "Z-Score",
          histogramBase: 0,
        },
        upperStDev: {
          title: "Positive sigma",
          histogramBase: 0,
        },
        lowerStDev: {
          title: "Negative sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "MA type",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "length",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "stdDevs",
          name: "Sigma multiplier",
          defval: 3.0,
          min: 0,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, maTypeInput, lengthInput, stDevNumberInput]: [
          string,
          string,
          number,
          number,
        ] = Array.from({ length: 4 }, (_, i) => this._input(i));

        // Market data parsing
        const source: number = GetPrice(PineJS, sourceInput, this._context);
        const sourceSeries: any = this._context.new_var(source);

        // Calculations
        const ma: number = MovingAverage(
          PineJS,
          sourceSeries,
          maTypeInput,
          lengthInput,
          this._context,
        );

        const zScore: number =
          (source - ma) /
          PineJS.Std.stdev(sourceSeries, lengthInput, this._context);
        const color: number = zScore > 0 ? 1 : 0;

        // Returns
        return [0, zScore, stDevNumberInput, -stDevNumberInput, color];
      };
    },
  };
};
