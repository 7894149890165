//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  GetPrice,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const rsiCannel = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "RSI channel",
    metainfo: {
      _metainfoVersion: 51,
      id: "rsi-channel@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] RSI channel",
      shortDescription: "[SF] RSI channel",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "sourcePlot", type: StudyPlotType.Line },
        { id: "maPlot", type: StudyPlotType.Line },
        { id: "upperBandPlot", type: StudyPlotType.Line },
        { id: "lowerBandPlot", type: StudyPlotType.Line },
        {
          id: "insideBackgroundColorer",
          type: StudyPlotType.Colorer,
          target: "insideBackground",
          palette: "backgroundPalette",
        },
        { id: "outsideBandPlot", type: StudyPlotType.Line },
        {
          id: "outsideBackgroundColorer",
          type: StudyPlotType.Colorer,
          target: "outsideBackground",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "insideBackground",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Inside background",
          type: FilledAreaType.TypePlots,
        },
        {
          id: "outsideBackground",
          objAId: "sourcePlot",
          objBId: "outsideBandPlot",
          title: "Outside background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Uptrend" },
            1: { name: "Downtrend" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          insideBackground: {
            color: "rgba(255, 255, 255, 0.25)",
            visible: true,
            transparency: 25,
          },
          outsideBackground: {
            color: "rgba(255, 255, 255, 0.25)",
            visible: true,
            transparency: 25,
          },
        },
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          sourcePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255, 255, 255, 0)",
          },
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 40,
          },
          upperBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          lowerBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          insideBackgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          outsideBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255, 255, 255, 0)",
          },
          outsideBackgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "close",
          maTypeInput: "SMA",
          length: 240,
          sigmaMltInput: 2.0,
        },
      },
      styles: {
        sourcePlot: {
          histogramBase: 0,
          joinPoints: false,
          title: "Source",
          isHidden: true,
          minHeight: 0,
          showLast: 0,
          zorder: 0,
        },
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
        outsideBandPlot: {
          histogramBase: 0,
          joinPoints: false,
          title: "Outside band",
          isHidden: true,
          minHeight: 0,
          showLast: 0,
          zorder: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "MA type",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "length",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "sigmaMltInput",
          name: "Sigma multiplier",
          defval: 3.0,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, maTypeInput, lengthInput, sigmaMltInput]: [
          string,
          string,
          number,
          number,
        ] = Array.from({ length: 4 }, (_, i) => this._input(i));

        // Market data parsing
        const source: number = GetPrice(PineJS, sourceInput, this._context);
        const sourceSeries: any = this._context.new_var(source);

        // Calculaions
        const ma: number = MovingAverage(
          PineJS,
          sourceSeries,
          maTypeInput,
          lengthInput,
          this._context,
        );

        const sigma: number = PineJS.Std.stdev(
          sourceSeries,
          lengthInput,
          this._context,
        );

        const upperBand: number = ma + sigmaMltInput * sigma;
        const lowerBand: number = ma - sigmaMltInput * sigma;

        const rsi: number = PineJS.Std.stoch(
          sourceSeries,
          sourceSeries,
          sourceSeries,
          lengthInput,
          this._context,
        );

        const insideBackgroundColor: number = rsi > 50 ? 0 : 1;
        let outsideBand: number = NaN;
        switch (true) {
          case source > upperBand:
            outsideBand = upperBand;
            break;
          case source < lowerBand:
            outsideBand = lowerBand;
            break;
          default:
            outsideBand = NaN;
            break;
        }

        let outsideBackgroundColor: number = NaN;
        switch (true) {
          case source > upperBand:
            outsideBackgroundColor = 1;
            break;
          case source < lowerBand:
            outsideBackgroundColor = 0;
            break;
          default:
            outsideBackgroundColor = NaN;
            break;
        }

        // Returns
        return [
          source,
          ma,
          upperBand,
          lowerBand,
          insideBackgroundColor,
          outsideBand,
          outsideBackgroundColor,
        ];
      };
    },
  };
};
