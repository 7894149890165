//eslint-disable-next-line
//@ts-nocheck
import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  ParseSymbol,
  GetMarketDataSource,
  MovingAverage,
  AccessCheck,
} from "@/lib/indicators/indicators-common-functions";

export const accumulationDistribution = (
  PineJS: PineJS,
  purchasedProductIds: string[],
): CustomIndicator => {
  return {
    name: "Accumulation & distribution",
    metainfo: {
      _metainfoVersion: 51,
      id: "accumulation-distribution@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Accumulation & distribution",
      shortDescription: "[SF] Accum & dist",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "accumDistPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "accumDistColorer",
          type: StudyPlotType.Colorer,
          target: "accumDistPlot",
          palette: "linePalette",
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maColorer",
          type: StudyPlotType.Colorer,
          target: "maPlot",
          palette: "linePalette",
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.Colorer,
          target: "backgroundPlot",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "accumDistPlot",
          objBId: "maPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        linePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling accum & dist" },
            1: { name: "Growing accum & dist" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling accum & dist" },
            1: { name: "Growing accum & dist" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0)",
            visible: true,
            transparency: 20,
          },
        },
        palettes: {
          linePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          accumDistPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          accumDistColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          maColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          backgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          sourceInput: "Volume",
          maTypeInput: "SMA",
          lengthInput: 240,
        },
      },
      styles: {
        accumDistPlot: {
          title: "Accum & dist",
          histogramBase: 0,
        },
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: ["Volume", "CVD", "Open interest"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback): void {
        this._context = context;
        this._input = inputCallback;

        const period: any = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);

        const volumeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, period);

        const openInterestSymbol: string = `${baseSymbol}#SF_OPEN_INTEREST`;
        this._context.new_sym(openInterestSymbol, period);
      };

      this.main = function (context, inputCallback): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [sourceInput, maTypeInput, maLengthInput]: [
          string,
          string,
          number,
        ] = [this._input(1), this._input(2), this._input(3)];

        // Market data parsing
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const priceTime: any = this._context.new_var(this._context.symbol.time);
        const highArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const lowArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const closeArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(0);
        const high: number = highArray.adopt(priceTime, mainSymbolTime, 0);
        const low: number = lowArray.adopt(priceTime, mainSymbolTime, 0);
        const close: number = closeArray.adopt(priceTime, mainSymbolTime, 0);

        const dataSource: number = GetMarketDataSource(
          PineJS,
          sourceInput,
          this._context,
        );
        const dataSourceSeries: any = this._context.new_var(dataSource);

        // Calculations
        const priceSource: number =
          (close === high && close === low) || high === low
            ? 0
            : (2 * close - low - high) / (high - low);

        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        const accumDist: number = PineJS.Std.cum(
          priceSource * dataSource,
          this._context,
        );
        const accumDistSeries: any = this._context.new_var(accumDist);
        const movingAverage: number = MovingAverage(
          PineJS,
          accumDistSeries,
          maTypeInput,
          maLengthInput,
          this._context,
        );

        const color: number = accumDist < movingAverage ? 0 : 1;

        const shouldShowData: any = AccessCheck(
          PineJS,
          "Technical",
          "GL5FlPdlqgQhaZ3sayy8",
          purchasedProductIds,
          this._context,
        );

        if (shouldShowData) {
          return [accumDist, color, movingAverage, color, color];
        } else {
          return [NaN, NaN, NaN, NaN, NaN];
        }
      };
    },
  };
};
