import { IPowerTrades } from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export function usePowerTrades() {
  const handler = useCallback(
    (oldData: IPowerTrades = {}, newData: IPowerTrades) => ({
      ...oldData,
      ...newData,
    }),
    [],
  );

  return useGenericSocket<IPowerTrades, IPowerTrades>(
    SocketTopics.bigTrades,
    handler,
  );
}
