import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface ITrendAssessmentData {
  timestamp: number;
  symbol: string;
  threadCum: number;
  smallTrendCum: number;
  mediumTrendCum: number;
  HugeTrendCum: number;
  hugeBackgroundColor?: string;
  mediumBackgroundColor?: string;
  smallBackgroundColor?: string;
  threadBackgroundColor?: string;
}

export interface ITrendAssessmentResponse {
  timestamp: number;
  timeFrame: string;
  data: ITrendAssessmentData[];
}

export function useTrendAssessment() {
  const handler = useCallback(
    (_: ITrendAssessmentResponse, newData: ITrendAssessmentResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<ITrendAssessmentResponse>(
    SocketTopics.trendAssessment,
    handler,
  );
}
