import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export interface IHiLowResponse {
  channel: string;
  timestamp: number;
  data: IHiLow[];
}

export interface IHiLow {
  timestamp: number;
  chanel: "WINDOW" | "LEVEL" | "NEAR_LEVEL";
  symbol: string;
  level: "MIN" | "MAX";
  levelPrice: number;
  newLevelPrice?: number;
  prc: number;
  price: number;
  weight: "YEAR" | "MONTH" | "WEEK" | "DAY";
}

export function useSmartHiLow() {
  const handler = useCallback(
    (oldData: IHiLowResponse, newData: IHiLowResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<IHiLowResponse, IHiLowResponse>(
    SocketTopics.allHiLowScanners,
    handler,
  );
}
