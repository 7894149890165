//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const depthOfMarket = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Depth of market",
    metainfo: {
      _metainfoVersion: 51,
      id: "depth-of-market@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Depth of market",
      shortDescription: "[SF] DOM",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "ask10DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask9DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask8DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask7DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask6DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask5DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask4DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask3DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask2DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask1DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid10DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid9DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid8DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid7DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid6DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid5DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid4DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid3DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid2DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid1DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          ask10DepthPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#211CFF",
            transparency: 1,
          },
          ask9DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#1871FF",
            transparency: 1,
          },
          ask8DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18BAFF",
            transparency: 1,
          },
          ask7DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FFB5",
            transparency: 1,
          },
          ask6DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FF42",
            transparency: 1,
          },
          ask5DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#63FF18",
            transparency: 1,
          },
          ask4DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#D6FF18",
            transparency: 1,
          },
          ask3DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FFCF18",
            transparency: 1,
          },
          ask2DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF7118",
            transparency: 1,
          },
          ask1DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF2821",
            transparency: 1,
          },
          bid10DepthPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#211CFF",
            transparency: 1,
          },
          bid9DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#1871FF",
            transparency: 1,
          },
          bid8DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18BAFF",
            transparency: 1,
          },
          bid7DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FFB5",
            transparency: 1,
          },
          bid6DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FF42",
            transparency: 1,
          },
          bid5DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#63FF18",
            transparency: 1,
          },
          bid4DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#D6FF18",
            transparency: 1,
          },
          bid3DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FFCF18",
            transparency: 1,
          },
          bid2DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF7118",
            transparency: 1,
          },
          bid1DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF2821",
            transparency: 1,
          },
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "USD",
        },
      },
      styles: {
        ask10DepthPlot: {
          title: "Ask depth at 10%",
          histogramBase: 0,
        },
        ask9DepthPlot: {
          title: "Ask depth at 9%",
          histogramBase: 0,
        },
        ask8DepthPlot: {
          title: "Ask depth at 8%",
          histogramBase: 0,
        },
        ask7DepthPlot: {
          title: "Ask depth at 7%",
          histogramBase: 0,
        },
        ask6DepthPlot: {
          title: "Ask depth at 6%",
          histogramBase: 0,
        },
        ask5DepthPlot: {
          title: "Ask depth at 5%",
          histogramBase: 0,
        },
        ask4DepthPlot: {
          title: "Ask depth at 4%",
          histogramBase: 0,
        },
        ask3DepthPlot: {
          title: "Ask depth at 3%",
          histogramBase: 0,
        },
        ask2DepthPlot: {
          title: "Ask depth at 2%",
          histogramBase: 0,
        },
        ask1DepthPlot: {
          title: "Ask depth at 1%",
          histogramBase: 0,
        },
        bid10DepthPlot: {
          title: "Bid depth at 10%",
          histogramBase: 0,
        },
        bid9DepthPlot: {
          title: "Bid depth at 9%",
          histogramBase: 0,
        },
        bid8DepthPlot: {
          title: "Bid depth at 8%",
          histogramBase: 0,
        },
        bid7DepthPlot: {
          title: "Bid depth at 7%",
          histogramBase: 0,
        },
        bid6DepthPlot: {
          title: "Bid depth at 6%",
          histogramBase: 0,
        },
        bid5DepthPlot: {
          title: "Bid depth at 5%",
          histogramBase: 0,
        },
        bid4DepthPlot: {
          title: "Bid depth at 4%",
          histogramBase: 0,
        },
        bid3DepthPlot: {
          title: "Bid depth at 3%",
          histogramBase: 0,
        },
        bid2DepthPlot: {
          title: "Bid depth at 2%",
          histogramBase: 0,
        },
        bid1DepthPlot: {
          title: "Bid depth at 1%",
          histogramBase: 0,
        },
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);

        const symbols: string[] = [
          symbol,
          `${baseSymbol}#ASKORDERBOOKSUM1_5`,
          `${baseSymbol}#ASKORDERBOOKSUM6_10`,
          `${baseSymbol}#BIDORDERBOOKSUM1_5`,
          `${baseSymbol}#BIDORDERBOOKSUM6_10`,
        ];

        symbols.forEach((sym: string) => this._context.new_sym(sym, period));
      };

      this.main = function (context: any, inputCallback: any) {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const measureInput: string = this._input(1);

        // Get market data
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(1);
        const closeTime: any = this._context.new_var(this._context.symbol.time);
        const closeArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(0);
        const close: number = closeArray.adopt(closeTime, mainSymbolTime, 1);

        const GetSymbolData = (symIndex: number): any[] => {
          this._context.select_sym(symIndex);
          const timeArray: any = this._context.new_var(
            this._context.symbol.time,
          );

          const GetData = (fn: (context: any) => number | string): number => {
            const array: any = this._context.new_var(fn(this._context));
            return array.adopt(timeArray, mainSymbolTime, 1);
          };

          return [
            GetData(PineJS.Std.volume), // 10%
            GetData(PineJS.Std.close), // 9%
            GetData(PineJS.Std.low), // 8%
            GetData(PineJS.Std.high), // 7%
            GetData(PineJS.Std.open), // 6%
          ];
        };

        const ask6_10Data: number[] = GetSymbolData(3);
        const ask1_5Data: number[] = GetSymbolData(2);
        const bid6_10Data: number[] = GetSymbolData(5).map((v: number) => -v);
        const bid1_5Data: number[] = GetSymbolData(4).map((v: number) => -v);

        this._context.select_sym(0);

        // Calculations
        const depthCoefficient: number = measureInput === "Coins" ? 1 : close;

        const askDepths: number[] = [...ask6_10Data, ...ask1_5Data].map(
          (depth: number) => depth * depthCoefficient,
        );
        const bidDepths: number[] = [...bid6_10Data, ...bid1_5Data].map(
          (depth: number) => depth * depthCoefficient,
        );

        return [...askDepths, ...bidDepths, 0];
      };
    },
  };
};
