import { useAltusherBB } from "@/hooks/strategies/useAltusherBB";
import { version } from "../../package.json";

export const cfDomain = "cf.spreadfighter.cloud";
export const baseDomain = "fra1.prod.do.spreadfighter.cloud";
export const ycBaseDomain = "ru-central1-b.prod.yc.spreadfighter.cloud";
export const binanceSpotDomain = "www.binance.com";
export const binanceSpotWSDomain = "fstream.binance.com";
export const coreDomain = `core-api-service.${cfDomain}`;
export const depthApiDomain = `b-f-d-api.${cfDomain}`;
export const klineApiV2Domain = `b-f-k-api.${cfDomain}`;
export const bybitKlineApiV2Domain = `bybf-kline-api.${baseDomain}`;
export const oiDomain = `binf-oi-api.${baseDomain}`;
export const fundingRateApiURL = `binf-funding-api.${baseDomain}`;
export const newsDomain = `news.${baseDomain}`;
export const marketcalDomain = `marketcal-proxy.${baseDomain}`;
export const wsKlineDomain = `nats-ws.${baseDomain}`;
export const liquidationDomain = `binf-liquidation.${baseDomain}`;
export const wsDomain = `nats-ws.${baseDomain}`;
export const saveLoadDomain = `save-load.${baseDomain}`;
export const alertsDomain = `alerts.${baseDomain}`;
export const environment = {
  name: "uat",
  version: version,
  encryptionKey: "J9AXvEamvlPX5XXhAeJtLg==",
  sentryDSN:
    "https://ef41f443b4014ec7bb835d1ed0c1f259@o4505028884365312.ingest.sentry.io/4505028885282816",
  firebase: {
    projectId: "spreadfighter",
    appId: "1:1003234821057:web:73eccb8cd510d1ce3c2540",
    storageBucket: "spreadfighter.appspot.com",
    apiKey: "AIzaSyANPTfC-8sGJ97jEieiqu1npXEHi3wnUTw",
    authDomain: "spreadfighter.firebaseapp.com",
    messagingSenderId: "1003234821057",
    measurementId: "G-NW87KTBTFK",
    functionsURL: "https://us-central1-spreadfighter.cloudfunctions.net",
    getNonceToSign: "https://getnoncetosign-mm6nybwt3a-ey.a.run.app",
    verifySignedMessage: "https://verifysignedmessage-mm6nybwt3a-ey.a.run.app",
    updateCustomer: "https://updatecustomer-mm6nybwt3a-ey.a.run.app",
    createCustomer: "https://createcustomer-mm6nybwt3a-ey.a.run.app",
  },
  production: false,
  streamer: false,
  uat: true,
  development: false,
  serviceDomain: `${coreDomain}`,
  alertsApiURL: `https://${alertsDomain}/api/alerts`,
  calendarApiURL: `https://${marketcalDomain}/events`,
  strategyApiURL: `https://${coreDomain}/api/strategies`,
  altusherBBApiURL: `https://${coreDomain}/api/altusherBbs`,
  altusherGapsApiURL: `https://${coreDomain}/api/altusherGaps`,
  cmStrategyV1LastApiURL: `https://${coreDomain}/api/strategy/cmStrategy/1/last`,
  cmStrategyV2LastApiURL: `https://${coreDomain}/api/strategy/cmStrategy/2/last`,
  cmStrategyV1BucketApiURL: `https://${coreDomain}/api/strategy/cmStrategy/1/bucket`,
  cmStrategyV2BucketApiURL: `https://${coreDomain}/api/strategy/cmStrategy/2/bucket`,
  directionalIndexWithBollingerConfirmationV1LastApiURL: `https://${coreDomain}/api/strategy/directionalIndexWithBollingerConfirmation/1/last`,
  directionalIndexWithBollingerConfirmationV1BucketApiURL: `https://${coreDomain}/api/strategy/directionalIndexWithBollingerConfirmation/1/bucket`,
  dualEmaBollingerV1LastApiURL: `https://${coreDomain}/api/strategy/dualEmaBollinger/1/last`,
  dualEmaBollingerV1BucketApiURL: `https://${coreDomain}/api/strategy/dualEmaBollinger/1/bucket`,
  multiPeriodVolatilityFilterV1LastApiURL: `https://${coreDomain}/api/strategy/multiPeriodVolatilityFilter/1/last`,
  multiPeriodVolatilityFilterV1BucketApiURL: `https://${coreDomain}/api/strategy/multiPeriodVolatilityFilter/1/bucket`,
  relativeHighLowOscillatorV1LastApiURL: `https://${coreDomain}/api/strategy/relativeHighLowOscillator/1/last`,
  relativeHighLowOscillatorV1BucketApiURL: `https://${coreDomain}/api/strategy/relativeHighLowOscillator/1/bucket`,
  triplePriceDeviationFilterV1LastApiURL: `https://${coreDomain}/api/strategy/triplePriceDeviationFilter/1/last`,
  triplePriceDeviationFilterV1BucketApiURL: `https://${coreDomain}/api/strategy/triplePriceDeviationFilter/1/bucket`,
  volatilityBasedEmaV1LastApiURL: `https://${coreDomain}/api/strategy/volatilityBasedEma/1/last`,
  volatilityBasedEmaV1BucketApiURL: `https://${coreDomain}/api/strategy/volatilityBasedEma/1/bucket`,
  matrixSpreadApiURL: `https://${coreDomain}/api/matrixSpreads`,
  matrixSpreadCumApiURL: `https://${coreDomain}/api/matrixSpreads/cum`,
  altTimersURL: `https://${coreDomain}/api/altTimers`,
  dogsURL: `https://${coreDomain}/api/dogs`,
  tickIndexesURL: `https://${coreDomain}/api/tickIndexes`,
  layeringApiURL: `https://${coreDomain}/api/layerings`,
  depthsSumApiURL: `https://${depthApiDomain}/api/depthsSum`,
  depthsSpreadApiURL: `https://${depthApiDomain}/api/depthsSpread`,
  depthsHighSizeApiURL: `https://${depthApiDomain}/api/depthsHighSize`,
  depthsChangeApiURL: `https://${depthApiDomain}/api/depthsChange`,
  spotFutureSpreadsApiURL: `https://${coreDomain}/api/spotFutureSpreads`,
  spotFutureSpreadsCumApiURL: `https://${coreDomain}/api/spotFutureSpreads/cum`,
  stopKillerApiURL: `https://${coreDomain}/api/stopKillers/bucket`,
  stopKillerRawApiURL: `https://${coreDomain}/api/stopKillers`,
  fundingRateApiURL: `https://${fundingRateApiURL}/api/fundings`,
  liquidationsURL: `https://${liquidationDomain}/api/liquidations/bucket`,
  activityDetectorApiURL: `https://${coreDomain}/api/activityDetectors/bucket`,
  cryptoDriftApiURL: `https://${coreDomain}/api/cryptoDrifts/bucket`,
  liquidationsRawURL: `https://${liquidationDomain}/api/liquidations`,
  manipulationApiURL: `https://${coreDomain}/api/manipulationMonitors`,
  PredictLiquidationsApiURL: `https://${coreDomain}/api/PredictLiquidations/last`,
  hftActivityURL: `https://${coreDomain}/api/hftActivities`,
  powerTradesURL: `https://${coreDomain}/api/powerTrades`,
  openInterestApiURL: `https://${oiDomain}/api/openInterests`,
  openInterestSpikeURL: `https://${coreDomain}/api/openInterestSpikes`,
  openInterestNetSpikeURL: `https://${coreDomain}/api/openInterestNetSpikes`,
  netOpenInterestDeltaURL: `https://${coreDomain}/api/netOpenInterestDelta`,
  assessmentTrendsCumApiURL: `https://${coreDomain}/api/assessmentTrends/cum`,
  binanceFuturesKlineURL: `https://${klineApiV2Domain}/fapi/v1/klines`,
  binanceFuturesDepthURL: `https://${depthApiDomain}/api/depths`,
  pickUpsURL: `https://${coreDomain}/api/pickUps`,
  newsApiURL: `https://${newsDomain}/api/cryptoPanicNews`,
  premiumIndexAPI: `https://${binanceSpotDomain}/fapi/v1/marketKlines`,
  premiumIndexWS: `wss://${binanceSpotWSDomain}/stream`,
  retailPowerApiURL: `https://${coreDomain}/api/retailPowers`,
  marketPowerApiURL: `https://${coreDomain}/api/marketPowers`,
  pumpTrendApiURL: `https://${coreDomain}/api/pumpTrends`,
  ideasApiURL: `https://${coreDomain}/api/ideas`,
  powerDeltaApiURL: `https://${coreDomain}/api/powerCvds`,
  santimentsApiURL: `https://santiment-api.${baseDomain}/api/santiments/timeSeriesData`,
  santimentsSlugApiURL: `https://${coreDomain}/api/getSlug`,
  santimentsGraphQLURL: "https://spreadfighter.stellate.sh",
  dictPricesMapWsURL: `wss://${coreDomain}/ws-uat/dict24Prices`,
  binanceWS: "wss://fstream.binance.com",
  sfNewsApiURL: `https://${coreDomain}/news/api/messages`,
  sfNewsImagesApiURL: `https://${coreDomain}/news/api/getFileLink/`,
  sizeScreenerAPi: `https://${coreDomain}/api/spoofLayers/toggle`,
  spoofLayersAPI: `https://${coreDomain}/api/spoofLayers`,
  saveLoadURL: `https://${saveLoadDomain}`,
  unlockSubgraphURL:
    "https://api.thegraph.com/subgraphs/name/unlock-protocol/polygon-v2",
  quickNodeURL:
    "https://fabled-sleek-liquid.matic.discover.quiknode.pro/7ff49b385c096091ebaabe810672816eb5863bce/",
  infura: {
    nftURL: "https://nft.api.infura.io",
    accessKey: "ecdeb3d91ae2435c8f458747f66a8071",
    secretKey: "b1fff038da704ac7a2699cc4f35a0801",
  },
  switchere: {
    apiKey: "UAQTLI2TZXCIDOUQ",
  },
  alchemy: {
    key: "rk8Th7btaR-CfZX_Hlg84gRvSDM4sK5z",
  },
  shortLink: {
    url: "https://api.short.cm/links/public",
    publicKey: "pk_x0zodlAsX3Bijpns",
    locks: {
      "0x622e617fe917d040714dab2816410ed7cfbad8e1": {
        name: "Basic Plan",
        network: 137,
      },
      "0x6bc2cbd1c8322c73f8881a789542aaf436afc6b1": {
        name: "Pro Plan",
        network: 137,
      },
    },
    domain: "spreadfighter.link",
  },
  socketUrl: `https://socket-gateway.${baseDomain}`,
  ycSocketUrl: `https://socket-gateway.${ycBaseDomain}`,
  stripeCustomerPortalUrl:
    "https://p-a.spreadfighter.com/p/login/28ocPvbTX5yB2Fq6oo",
  partnerWallets: ["0xe1252b39b2e7865ffe2d6ded4fa5d3e62a287fb0"],
  contractAddress: {
    pro: {
      alpha: "0x45009830025140dd71cc34a7df93313545ed0598",
      beta: "0xd3ef2892575f86022d94c1454c2cc6e6ea710223", // Partners
      eat: "0xb6db2c1923dad7411dad2c0c16c9997e5a481fe8",
      marketing14: "0xaf5c99dd10f7a85fafbd1d4e0e7dee31572808e4",
      marketing7: "0x8235cc458ebd2fe8ec1319e45a916b320888863e",
      ciCap: "0x28225492ae17954463e3417dab982a7bc3578ad8",
      nickAlgoGroup: "0xc4dbe4b2080082b3873bf6f4be89a2adb01a9941", // Nick Trial 7 Days contract
      ciCap7days: "0x69e97fb1df325e663954eaca27ccd79c179611a6", // CI CAP Trial 7 Days contract
      noCap7days: "0xee8c1a964a77cf060497f7a67755014b5f3f1943", // NO CAP Trial 7 Days contract
      fatCamel7Days: "0x928011e944d214325c517c8fd4f6404694919c75", // Fat Camel Trial 7 Days contract
      cryptoMentors: "0xfb0211b14b9160321cd43c7b33e2e415b9e6b6d3", // Crypto Mentors Trial 7 Days contract
      shadowCrypto: "0x47dc09dd19a4853f97666e3531b070730f782f23", // Shadow Crypto Trial 7 Days contract
      profitMen: "0x708ec8393003e71144223e159c5a8dc418f93e32", // Profitmen Trial 7 Days contract
      akimspace: "0x4d5405b9b5c90d8d07817844e765bce789c2885a", // Akimspace Trial 7 Days contract
      anton: "0xf458015c23a9fb11fb76bc99e9123adea0264889", // Anton Trial 7 Days contract
      honeybadger: "0x0e80e84840f82a7bb9504ed502078862eff5276b", // Honeybadger Trial 7 Days contract
      cryptodroog: "0xe1073ff502c29c1e74753e05a78f8a3ddf6cb888", // Cryptodroog Trial 7 Days contract
      "0therfox": "0x04eb60db3fd5820ff2c22de66d070d5bab74221a", // 0therfox Trial 7 Days contract
      finclub: "0xbac4323f8695d86208bb47eb5b8b84f9bbba308b", // finclub trial 7 days contract
      topCryptoKungFu: "0x5076FBE4B3193232436ce71213a7DF68b523b2A8", // topcryptokungfu trial 7 days contract
      sfPro: "0x6bc2cbd1c8322c73f8881a789542aaf436afc6b1",
      spFtPro: "0x23af91d21d545b8522763a363d9ee6ed1e90e2dc",
      sfTrader: "0x04e67c2444e734912791f21aa26f03dfff0e87cf", // todo: remove temporary fix
    },
    trader: {
      sfTrader: "0x04e67c2444e734912791f21aa26f03dfff0e87cf",
      spFtTrader: "0xccc55ea136d71b55bce1a58d16cfcda55889e279",
    },
    basic: {
      sfBasic: "0x622e617fe917d040714dab2816410ed7cfbad8e1",
      spFtBasic: "0x5ac58d3da76416b8c40d4dc5e7f0851d39f6c260",
    },
  },
};
