import {
  ChartingLibraryWidgetOptions,
  ResolutionString,
  widget as Widget,
} from "@tradingView/charting_library";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { merge } from "lodash";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { SfChartBase } from "@/components/core/charts/sfChartBase.tsx";

export interface ISfChartProps {
  symbol?: string;
  interval?: ResolutionString;
  options?: ChartingLibraryWidgetOptions;
  onWidgetReady?: (widget: typeof Widget) => void;
}

export const SfChartWithoutInterface = memo(function SfChartWithoutInterface({
  symbol,
  interval,
  options,
  onWidgetReady,
}: ISfChartProps) {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const indicatorTitle = getNodeConfig().indicatorTitle;
  const simpleChart = true;
  const initialOptions: Partial<ChartingLibraryWidgetOptions> = useMemo(
    () => ({
      disabled_features: [
        "use_localstorage_for_settings",
        /** Disabling this feature hides "Favorite this item" icon for Drawings and Intervals @default true */
        "items_favoriting",
        /** Can be disabled to forbid storing chart properties to the localstorage while allowing to save other properties. The other properties are favorites in the Advanced Charts and Watchlist symbols and some panels states in the Trading Platform @default true @default true */
        "save_chart_properties_to_local_storage",
        /** Add the volume indicator upon initialisation of the chart @default true */
        "create_volume_indicator_by_default",
        /** Prevent the volume indicator from being recreated when an instrument or a resolution is switched @default true */
        "create_volume_indicator_by_default_once",
        /** Places Volume indicator on the same pane with the main series @default true */
        "volume_force_overlay",
        /** Determines the behavior of Zoom feature: bar under the mouse cursor stays in the same place if this feature is disabled @default true */
        "right_bar_stays_on_scroll",
        /** Keeps the dialogs within the chart @default true */
        "constraint_dialogs_movement",
        /** Enables logs @default false */
        "charting_library_debug_mode",
        /** This enables Drawings Toolbar in the fullscreen mode @default false */
        "side_toolbar_in_fullscreen_mode",
        /** Enables header widget DOM element in the fullscreen mode @default false */
        "header_in_fullscreen_mode",
        /** Shows bar time exactly as provided by the data feed with no adjustments @default false */
        "disable_resolution_rebuild",
        /** Allows chart scrolling @default true */
        "chart_scroll",
        /** Allows chart zooming @default true */
        "chart_zoom",
        /** If enabled, the chart handles horizontal pointer movements on touch screens. In this case the webpage is not scrolled. If disabled, the webpage is scrolled instead. Keep in mind that if the user starts scrolling the chart vertically or horizontally, scrolling is continued in any direction until the user releases the finger @default true */
        "horz_touch_drag_scroll",
        /** If enabled, the chart handles vertical pointer movements on touch screens. In this case the webpage is not scrolled. If disabled, the webpage is scrolled instead. Keep in mind that if the user starts scrolling the chart vertically or horizontally, scrolling is continued in any direction until the user releases the finger @default true */
        "vert_touch_drag_scroll",
        /** If enabled, chart scrolling with horizontal mouse wheel is enabled @default true */
        "mouse_wheel_scroll",
        /** If enabled, chart scrolling with left mouse button pressed is allowed @default true */
        "pressed_mouse_move_scroll",
        /** If enabled, series scaling with a mouse wheel is enabled @default true */
        "mouse_wheel_scale",
        /** If enabled, series scaling with pinch/zoom gestures (this option is supported on touch devices) is enabled @default true */
        "pinch_scale",
        /** If enabled, axis scaling with left mouse button pressed is allowed @default true */
        "axis_pressed_mouse_move_scale",
        /** Allows zooming in to show up to one bar in the viewport @default false */
        "low_density_bars",
        /** Disabling this feature allows a user to enter case-sensitive symbols @default true */
        "uppercase_instrument_names",
        /** Disables minimum chart width limitation @default false */
        "no_min_chart_width",
        /** Prevents scrolling to the left of the first historical bar @default false */
        "fix_left_edge",
        /** Prevents changing visible time area on chart resizing @default false */
        "lock_visible_time_range_on_resize",
        /** If disabled, adding a new bar zooms out the chart preserving the first visible point. Otherwise the chart is scrolled one point to the left when a new bar comes @default true */
        "shift_visible_range_on_new_bar",
        /** If enabled, there is a possibility to add custom resolutions @default false */
        "custom_resolutions",
        /** Toggles the timeline marks to display the bar's end time @default false */
        "end_of_period_timescale_marks",
        /** If disabled, partially visible price labels on price axis will be hidden @default true */
        "cropped_tick_marks",
        /** Applies symbol display mode (ticker/description) to overlay/compare studies in the status line @default false */
        "study_overlay_compare_legend_option",
        /** Applies symbol display mode (ticker/description) to indicator inputs in the status line @default false */
        "study_symbol_ticker_description",
        /** Displays Symbol Name Label when comparing Symbols @default true */
        "auto_enable_symbol_labels",
        /** Enables insert indicator dialog shortcut (/) @default true */
        "insert_indicator_dialog_shortcut",
        /** Display at most two characters in bar marks. The default behavior is to only display one character @default false */
        "two_character_bar_marks_labels",
        /** By default many chart layouts can be saved with the same name. If this feature is enabled then the library will prompt to confirm overwriting chart layouts with the same name when saving, renaming, or cloning ("Save as") @default false */
        "confirm_overwrite_if_chart_layout_with_name_exists",
        /** By default the chart will request a small (fixed) number of bars for the initial data request when the chart is first created. If this feature is enabled then the library will rather calculate the request size based on the amount of bars which will be visible on the chart @default false */
        "determine_first_data_request_size_using_visible_range",
        /** Places the logo on the main series pane instead of the bottom pane @default false */
        "move_logo_to_main_pane",
        /** Enables a button in the header to load/save `Indicator template` @default false */
        "study_templates",
        /** Enables copying of drawings and studies @default true */
        "datasource_copypaste",
        /** Enables the support of resolutions that start from 1 second @default false */
        "seconds_resolution",
        /** Enables the support of tick resolution @default false */
        "tick_resolution",
        /** Enables a feature to allow an additional series to extend the time scale @default false */
        "secondary_series_extend_time_scale",
        /** Removes the header from the chart @default true */
        "header_widget",
        /** Hides the symbol search button from the header @default true */
        "header_symbol_search",
        /** Symbol search by pressing any key @default true */
        "symbol_search_hot_key",
        /** Hides the resolution button from the header @default true */
        "header_resolutions",
        /** Displays a `Change interval` dialog to specify another resolution @default true */
        "show_interval_dialog_on_key_press",
        /** Hides the chart type button from the header @default true */
        "header_chart_type",
        /** Relates to Chart Properties button @default true */
        "header_settings",
        /** Hides the indicators button from the header @default true */
        "header_indicators",
        /** Hides the compare button from the header @default true */
        "header_compare",
        /** Hides the undo/redo button from the header @default true */
        "header_undo_redo",
        /** Hides the screenshot button from the header @default true */
        "header_screenshot",
        /** Hides the fullscreen button from the header @default true */
        "header_fullscreen_button",
        /** Adds a 2px padding to the chart @default true */
        "border_around_the_chart",
        /** Hides save/load buttons (the feature is not part of `header_widget` featureset) @default true */
        "header_saveload",
        /** Hides the left toolbar aka drawings toolbar @default true */
        "left_toolbar",
        /** Relates to the navigation buttons at the bottom of the chart @default true */
        "control_bar",
        /** Hide the timeframe toolbar at the bottom of the chart @default true */
        "timeframes_toolbar",
        /** Disabling this feature hides the legend widget @default true */
        "legend_widget",
        /** Display legend on all diagrams regardless of crosshair synchronization @default false */
        "display_legend_on_all_charts",
        /** Display object tree button in the legend at a small width @default true */
        "object_tree_legend_mode",
        /** Removes all buttons from the legend except the ellipsis menu @default true */
        "edit_buttons_in_legend",
        /** Removes the eye button that hides/shows a chart @default true */
        "show_hide_button_in_legend",
        /** Removes the cog icon for accessing chart/indicator's settings @default true */
        "format_button_in_legend",
        /** Removes the delete button @default true */
        "delete_button_in_legend",
        /** Doesn't display any context menu when right clicking anywhere in the UI @default true */
        "context_menus",
        /** Doesn't display the context menu when right clicking on the chart @default true */
        "pane_context_menu",
        /** Doesn't display the context menu when right clicking on either price scale or timescale @default true */
        "scales_context_menu",
        /** Doesn't display the context menu when right clicking on the legend @default true */
        "legend_context_menu",
        /** Displays the settings button in the bottom right corner of the chart @default true */
        "main_series_scale_menu",
        /** Hides the market status from the legend @default true */
        "display_market_status",
        /** Sets the border style to 0px & padding to 1px @default true */
        "remove_library_container_border",
        /** Disables all property pages @default true */
        "property_pages",
        /** Turning this feature off disables Properties @default true */
        "show_chart_property_page",
        /** Allows overrides for the price scale @default true */
        "chart_property_page_scales",
        /** This feature is for the Trading Platform only @default true */
        "chart_property_page_trading",
        /** Shows the right margin editor in the setting dialog @default true */
        "chart_property_page_right_margin_editor",
        /** Displays a countdown label on a price scale @default true */
        "countdown",
        /** Hides true/false study arguments @default false */
        "dont_show_boolean_study_arguments",
        /** Hides last n/a study output data @default false */
        "hide_last_na_study_output",
        /** Enables the symbol info dialog @default true */
        "symbol_info",
        /** Disables timezone context menu @default true */
        "timezone_menu",
        /** Includes orders/positions/executions in the screenshot @default false */
        "snapshot_trading_drawings",
        /** Disables selection markers for series and indicators @default true */
        "source_selection_markers",
        /** Allows you to jump to a particular bar using 'Go to' dialog @default true */
        "go_to_date",
        /** Allows you to hide 'charts by TradingView' text on small-screen devices @default true */
        "adaptive_logo",
        /** Show DOM panel when a user opens the Chart for the first time @default false */
        "show_dom_first_time",
        /** Hides left toolbar when a user opens the Chart for the first time @default false */
        "hide_left_toolbar_by_default",
        /** Adds High-low option to chart style controls @default true */
        "chart_style_hilo",
        /** Enables last price line and price axis label on High-low chart style @default false */
        "chart_style_hilo_last_price",
        /** Displays the currency in which the instrument is traded on the price axes @default false */
        "pricescale_currency",
        /** Displays the unit in which the instrument is traded on the price axes @default false */
        "pricescale_unit",
        /** Displays Date Format selector in Chart Settings @default true */
        "scales_date_format",
        /** Displays popup hints about possible mouse/shortcuts/UI actions @default true */
        "popup_hints",
        /** Enables the save shortcut @default true */
        "save_shortcut",
        /** Opens right widget toolbar on first launch @default true */
        "show_right_widgets_panel_by_default",
        /** Shows the object tree button in the left or right panel depending on the product and configuration @default true */
        "show_object_tree",
        /** Shows the spread operators in the Symbol Search dialog @default false */
        "show_spread_operators",
        /** Hide exponentiation spread operator (^) in the Symbol Search dialog @default false */
        "hide_exponentiation_spread_operator",
        /** Hide reciprocal spread operator (1/x) in the Symbol Search dialog @default false */
        "hide_reciprocal_spread_operator",
        /** Shows the spread operators in the Compare Search dialog - needs to be used in conjunction to show_spread_operators @default false */
        "compare_symbol_search_spread_operators",
        /** Shows the spread operators for Studies - needs to be used in conjunction to show_spread_operators @default false */
        "studies_symbol_search_spread_operators",
        /** Hide the interval (D, 2D, W, M, etc.) in the chart legend and the data window @default false */
        "hide_resolution_in_legend",
        /** Hide unresolved symbols in the chart legend and the data window @default false */
        "hide_unresolved_symbols_in_legend",
        /** On touch device show the zoom and move buttons at the bottom of the chart @default false */
        "show_zoom_and_move_buttons_on_touch",
        /** Hide the optional symbol input value from the indicator's legend if 'Main chart symbol' option is selected @default true */
        "hide_main_series_symbol_from_indicator_legend",
        /** Hide the global last price label on price scale if last bar is outside of visible range @default false */
        "hide_price_scale_global_last_bar_value",
        /** Hide the visibility settings of the label and the average close price line @default false */
        "show_average_close_price_line_and_label",
        /** Hide image shown to illustrate symbol is invalid @default false */
        "hide_image_invalid_symbol",
        /** Show/Hide the exchange label from the displayed label @default false */
        "hide_object_tree_and_price_scale_exchange_label",
        /** Displays Time Format selector in Chart Settings @default true */
        "scales_time_hours_format",
        /** Show a literal "n/a" for not available values instead of "∅" @default false */
        "use_na_string_for_not_available_values",
        /** Enable pre and post market session support @default false */
        "pre_post_market_sessions",
        /** Show the option to specify the default right margin in percentage within chart settings dialog @default false */
        "show_percent_option_for_right_margin",
      ],
      enabled_features: [
        "study_templates",
        "hide_left_toolbar_by_default",
        "show_spread_operators",
        "hide_main_series_symbol_from_indicator_legend",
        "hide_price_scale_global_last_bar_value",
        "study_overlay_compare_legend_option",
        "use_localstorage_for_settings",
        "two_character_bar_marks_labels",
        "studies_extend_time_scale",
      ],

      overrides: {
        "paneProperties.background": "#050305",
        "mainSeriesProperties.candleStyle.drawBody": true,
        "paneProperties.horzGridProperties.color": "rgba(0,0,0,0)",
        "paneProperties.vertGridProperties.color": "rgba(0,0,0,0)",
        "paneProperties.backgroundType": "solid",
        "timeScale.rightOffset": 0,
        "scalesProperties.fontSize": 10,
        // "mainSeriesProperties.style": 1,
        "mainSeriesProperties.areaStyle.color1": "rgba(37,255,0,0.6)",
        "mainSeriesProperties.areaStyle.color2": "rgba(0,0,0,.5)",
        "mainSeriesProperties.areaStyle.linecolor": "#3CA64B",
        "mainSeriesProperties.areaStyle.linewidth": "1",

        "mainSeriesProperties.candleStyle.upColor": "#52ED6A",
        "mainSeriesProperties.candleStyle.wickUpColor": "#52ED6A",
        "mainSeriesProperties.candleStyle.downColor": "#F91A36",
        "mainSeriesProperties.candleStyle.wickDownColor": "#F91A36",
        "mainSeriesProperties.candleStyle.borderUpColor": "#52ED6A",
        "mainSeriesProperties.candleStyle.borderDownColor": "#F91A36",
      },
    }),
    [],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SfChartBase
        symbol={symbol}
        interval={interval}
        options={merge({}, initialOptions, options)}
        syncTabConfigWithChart={false}
        indicatorTitle={indicatorTitle}
        containerRef={containerRef}
      />
      <div className="absolute top-0 left-0 w-full h-full" ref={containerRef} />
    </>
  );
});
