//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

import { AdjustPrice } from "@/lib/indicators/indicators-common-functions";

enum PlotSymbolSize {
  Auto = "auto",
  Tiny = "tiny",
  Small = "small",
  Normal = "normal",
  Large = "large",
  Huge = "huge",
}

enum MarkLocation {
  AboveBar = "AboveBar",
  BelowBar = "BelowBar",
  Top = "Top",
  Bottom = "Bottom",
  Right = "Right",
  Left = "Left",
  Absolute = "Absolute",
  AbsoluteUp = "AbsoluteUp",
  AbsoluteDown = "AbsoluteDown",
}

export const openInterestSpike = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Open interest spike",
    metainfo: {
      _metainfoVersion: 51,
      id: "open-interest-spike@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Open interest spike",
      shortDescription: "[SF] OI spike",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "tinyShapePlot", type: StudyPlotType.Shapes },
        {
          id: "tinyShapeColorer",
          type: StudyPlotType.Colorer,
          target: "tinyShapePlot",
          palette: "tinyShapePalette",
        },
        { id: "smallShapePlot", type: StudyPlotType.Shapes },
        {
          id: "smallShapeColorer",
          type: StudyPlotType.Colorer,
          target: "smallShapePlot",
          palette: "smallShapePalette",
        },
        { id: "normalShapePlot", type: StudyPlotType.Shapes },
        {
          id: "normalShapeColorer",
          type: StudyPlotType.Colorer,
          target: "normalShapePlot",
          palette: "normalShapePalette",
        },
        { id: "largeShapePlot", type: StudyPlotType.Shapes },
        {
          id: "largeShapeColorer",
          type: StudyPlotType.Colorer,
          target: "largeShapePlot",
          palette: "largeShapePalette",
        },
        { id: "hugeShapePlot", type: StudyPlotType.Shapes },
        {
          id: "hugeShapeColorer",
          type: StudyPlotType.Colorer,
          target: "hugeShapePlot",
          palette: "hugeShapePalette",
        },
      ],
      filledAreas: [],
      palettes: {
        tinyShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Outflow" },
            1: { name: "Inflow" },
          },
        },
        smallShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Outflow" },
            1: { name: "Inflow" },
          },
        },
        normalShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Outflow" },
            1: { name: "Inflow" },
          },
        },
        largeShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Outflow" },
            1: { name: "Inflow" },
          },
        },
        hugeShapePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Outflow" },
            1: { name: "Inflow" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {},
        palettes: {
          tinyShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          smallShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          normalShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          largeShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
          hugeShapePalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.75)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          tinyShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_diamond",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          smallShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_diamond",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          normalShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_diamond",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          largeShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_diamond",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
          hugeShapePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_diamond",
            textColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          flowFilter: 1,
        },
      },
      styles: {
        tinyShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Tiny,
          title: "Tiny flow",
        },
        smallShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Small,
          title: "Small flow",
        },
        normalShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Normal,
          title: "Normal flow",
        },
        largeShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Large,
          title: "Large flow",
        },
        hugeShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Huge,
          title: "Huge flow",
        },
      },
      inputs: [
        {
          id: "flowFilter",
          name: "Flow filter",
          defval: 1,
          max: 5,
          min: 1,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const spikeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#OISPIKE`;
        this._context.new_sym(spikeSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const filterInput: number = this._input(0);

        // Market data parsing
        this._context.select_sym(1);
        const spikeTime: any = this._context.new_var(this._context.symbol.time);
        const spikePriceArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const spikeWeightArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const spikeSideArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        let spikePrice: number = spikePriceArray.adopt(
          spikeTime,
          mainSymbolTimeSeries,
          1,
        );
        const spikeWeight: number = spikeWeightArray.adopt(
          spikeTime,
          mainSymbolTimeSeries,
          1,
        );
        const spikeSide: number = spikeSideArray.adopt(
          spikeTime,
          mainSymbolTimeSeries,
          1,
        );

        // Adaptation to the price of synthetics
        spikePrice = AdjustPrice(PineJS, this._context, spikePrice);

        // Calculations
        let tinyShapePrice: number,
          smallShapePrice: number,
          normalShapePrice: number,
          largeShapePrice: number,
          hugeShapePrice: number;

        if (spikeWeight >= filterInput) {
          switch (true) {
            case spikeWeight === 0:
              tinyShapePrice = spikePrice;
              break;
            case spikeWeight === 1:
              smallShapePrice = spikePrice;
              break;
            case spikeWeight === 2:
              normalShapePrice = spikePrice;
              break;
            case spikeWeight === 3:
              largeShapePrice = spikePrice;
              break;
            case spikeWeight === 4:
              hugeShapePrice = spikePrice;
              break;
          }
        }

        // Returns
        return [
          tinyShapePrice,
          spikeSide,
          smallShapePrice,
          spikeSide,
          normalShapePrice,
          spikeSide,
          largeShapePrice,
          spikeSide,
          hugeShapePrice,
          spikeSide,
        ];
      };
    },
  };
};
