// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  ChannelBounds,
} from "@/lib/indicators/indicators-common-functions";

export const openInterest = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Open interest",
    metainfo: {
      _metainfoVersion: 51,
      id: "open-interest@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Open interest",
      shortDescription: "[SF] OI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "openInterestOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "oiMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "openInterestUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "openInterestLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling open interest" },
            1: { name: "Growing open interest" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling open interest" },
            1: { name: "Growing open interest" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling open interest" },
            1: { name: "Growing open interest" },
          },
        },
      },
      ohlcPlots: {
        openInterestOhlcPlotcandle: {
          title: "Open interest plot candle",
        },
      },
      defaults: {
        styles: {
          oiMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          openInterestUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          openInterestLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          openInterestOhlcPlotcandle: {
            borderColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "rgb(149, 152, 161)",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          depthInput: 0,
          metricsBoolInput: false,
        },
      },
      styles: {
        oiMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        openInterestUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        openInterestLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Extremum channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: true,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      const CONSTANTS = {
        SF_OI_SUFFIX: "#SF_OPEN_INTEREST",
        DEFAULT_COLOR: 0,
        GROWING_COLOR: 1,
        DEFAULT_CHANNEL_TYPE: "Bollinger channel",
        MEASURE_TYPES: {
          COINS: "Coins",
          USD: "USD",
        },
      } as const;

      // CalculateBands - function to calculate upper and lower bands
      const CalculateBands = (
        type: string,
        ma: number,
        highSeries: any,
        lowSeries: any,
        closeSeries: any,
        length: number,
        context: any,
      ): [number, number] => {
        if (type === "Extremum channel") {
          const upper: number =
            PineJS.Std.highest(highSeries, length, context) || NaN;
          const lower: number =
            PineJS.Std.lowest(lowSeries, length, context) || NaN;
          return [upper, lower];
        }
        const stdev: number = PineJS.Std.stdev(closeSeries, length, context);
        return [ma + stdev, ma - stdev];
      };

      // NormalizeValue - function to normalize value
      const NormalizeValue = (value: number): number =>
        value === 0 ? NaN : value;

      // CalculateOIValues - function to calculate OI values
      const CalculateOIValues = (
        measureInput: string,
        oiData: {
          high: number;
          low: number;
          close: number;
        },
        price: number,
      ): { oiHigh: number; oiLow: number; oiClose: number } => {
        if (measureInput === CONSTANTS.MEASURE_TYPES.USD && price) {
          return {
            oiHigh: oiData.high * price,
            oiLow: oiData.low * price,
            oiClose: oiData.close * price,
          };
        }
        return {
          oiHigh: oiData.high,
          oiLow: oiData.low,
          oiClose: oiData.close,
        };
      };

      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const oiSymbol: string = `${ParseSymbol(symbol)}${CONSTANTS.SF_OI_SUFFIX}`;
        this._context.new_sym(oiSymbol, period);
      };

      this.main = function (context: any, inputCallback: any) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [
          measureInput,
          maTypeInput,
          lengthInput,
          channelTypeInput,
          metricsBoolInput,
        ]: [string, string, number, string, boolean] = Array.from(
          { length: 5 },
          (_, i) => this._input(i + 1),
        );

        // Market data parsing
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Get price data
        this._context.select_sym(1);
        const priceTime: any = this._context.new_var(this._context.symbol.time);
        const priceArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        // Get OI data
        this._context.select_sym(2);
        const oiTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const oiHighArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const oiLowArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const oiCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(0);

        // Calculate OI values
        const price: number = priceArray.adopt(priceTime, mainSymbolTime, 0);
        const rawOiData = {
          high: oiHighArray.adopt(oiTimeArray, mainSymbolTime, 0),
          low: oiLowArray.adopt(oiTimeArray, mainSymbolTime, 0),
          close: oiCloseArray.adopt(oiTimeArray, mainSymbolTime, 0),
        };

        const {
          oiHigh,
          oiLow,
          oiClose,
        }: {
          oiHigh: number;
          oiLow: number;
          oiClose: number;
        } = CalculateOIValues(measureInput, rawOiData, price);

        const oiCloseSeries: any = this._context.new_var(oiClose);
        const oiOpen: number = NormalizeValue(oiCloseSeries.get(1));
        const oiHighSeries: any = this._context.new_var(oiHigh);
        const oiLowSeries: any = this._context.new_var(oiLow);
        const oiColor: number =
          oiClose > oiOpen ? CONSTANTS.GROWING_COLOR : CONSTANTS.DEFAULT_COLOR;

        // Calculate metrics
        const [oiMa, oiUpperBand, oiLowerBand]: [number, number, number] =
          metricsBoolInput
            ? ChannelBounds(
                PineJS,
                oiLowSeries,
                oiHighSeries,
                oiCloseSeries,
                channelTypeInput,
                maTypeInput,
                lengthInput,
                this._context,
              )
            : [NaN, NaN, NaN];

        return [
          NormalizeValue(oiOpen),
          NormalizeValue(oiHigh),
          NormalizeValue(oiLow),
          NormalizeValue(oiClose),
          oiColor,
          oiColor,
          oiColor,
          oiMa,
          oiUpperBand,
          oiLowerBand,
        ];
      };
    },
  };
};
