import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ESFTableType } from "@/domain/enums/dashboard.enum.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import { usePowerTrades } from "@/hooks/usePowerTrades";
import { IPowerTrade } from "@/domain/interfaces/general.interface";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config";

const columnHelper = createColumnHelper<IPowerTrade>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: true,
  }),
  columnHelper.accessor("weight", {
    header: "Weight",
    size: 100,
  }),
  columnHelper.accessor("price", {
    header: "Price",
    size: 100,
  }),
  columnHelper.accessor("volumeQuote", {
    header: "V. Quote",
    size: 100,
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange shorten={true} decimal={2} value={value} />
      );
    },
  }),
  columnHelper.accessor("deltaQuote", {
    header: "D. Quote",
    size: 100,
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange shorten={true} decimal={2} value={value} />
      );
    },
  }),
];

const oldValues: IRow[] = [];
const highlightTimestamps: number[] = [];

function rowClassCb(
  row: ITechDesk,
  idx: number,
  table: Table<IRow>,
  sortingState: SortingState,
) {
  if (idx > 4) {
    return "";
  }

  const meta = table.options.meta as { tableType: ESFTableType };
  const isHighlightable =
    meta?.tableType.includes(ESFTableType.unpinned) &&
    !meta?.tableType.includes(ESFTableType.chart);

  if (!isHighlightable) {
    return "";
  }

  const oldRow = oldValues[idx];
  const isPositionChanged = oldRow?.symbol !== row.symbol;
  const now = Date.now();
  const isSortedDesc = sortingState.some((sort) => sort.desc);
  const color = isSortedDesc ? "bg-green-600/20" : "bg-red-600/20";

  if (isPositionChanged) {
    oldValues[idx] = row;
    highlightTimestamps[idx] = now;
    return cn(color, "animate-pulse");
  } else if (
    highlightTimestamps[idx] &&
    now - highlightTimestamps[idx] < 1000
  ) {
    return cn(color, "animate-pulse");
  } else {
    return "";
  }
}

export function PowerTradesPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: powerTradesData, isLoading } = usePowerTrades();
  const interval = BINANCE_RESOLUTION_MAP[config.interval];
  const preparedData = useMemo(() => {
    if (!powerTradesData) {
      return [];
    }

    let data = powerTradesData[interval];

    if (blacklist.length > 0) {
      data = powerTradesData[interval].filter(
        (item) => !blacklist.includes(item.symbol),
      );
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IPowerTrade>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, powerTradesData, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={(...args) => rowClassCb(...args, config.sortingState)}
      />
    </>
  );
}
