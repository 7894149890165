//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import { GetPriceSeries } from "@/lib/indicators/indicators-common-functions";

export const percentagePriceOscillator = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Percentage price oscillator",
    metainfo: {
      _metainfoVersion: 51,
      id: "percentage-price-oscillator@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Percentage price oscillator",
      shortDescription: "[SF] PPO",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "histogramPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "histogramColorer",
          type: StudyPlotType.Colorer,
          target: "histogramPlot",
          palette: "histogramPalette",
        },
        {
          id: "ppoPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "signalPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        histogramPalette: {
          colors: {
            0: { name: "Strong negative" },
            1: { name: "Weak negative" },
            2: { name: "Weak positive" },
            3: { name: "Strong positive" },
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
          },
        },
      },
      defaults: {
        palettes: {
          histogramPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 1.0)", width: 1, style: 0 },
              1: { color: "rgba(178, 24, 44, 0.5)", width: 1, style: 0 },
              2: { color: "rgba(60, 166, 75, 0.5)", width: 1, style: 0 },
              3: { color: "rgba(60, 166, 75, 1.0)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          histogramPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            color: "rgba(255, 255, 255, 0.5)",
          },
          histogramColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            color: "rgba(255, 255, 255, 0.5)",
          },
          ppoPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(13, 157, 219)",
          },
          signalPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(255, 160, 40)",
          },
        },
        inputs: {
          fastLength: 12,
          slowLength: 26,
          signalLength: 9,
          source: "close",
        },
      },
      styles: {
        histogramPlot: {
          title: "Histogram",
          histogramBase: 0,
        },
        ppoPlot: {
          title: "PPO",
          histogramBase: 0,
        },
        signalPlot: {
          title: "Signal",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "fastLength",
          name: "Fast length",
          defval: 12,
          type: StudyInputType.Integer,
          min: 1,
        },
        {
          id: "slowLength",
          name: "Slow length",
          defval: 26,
          type: StudyInputType.Integer,
          min: 1,
        },
        {
          id: "signalLength",
          name: "Signal length",
          defval: 9,
          type: StudyInputType.Integer,
          min: 1,
        },
        {
          id: "source",
          name: "Source",
          defval: "close",
          type: StudyInputType.Text,
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number, number, number] {
        this._context = context;
        this._input = inputCallback;

        // Get inputs
        const [fastLength, slowLength, signalLength, sourceInput]: [
          number,
          number,
          number,
          string,
        ] = Array.from({ length: 4 }, (_, i) => this._input(i));

        // Get price series
        const sourceSeries: any = GetPriceSeries(
          PineJS,
          sourceInput,
          this._context,
        );

        // Calculate PPO
        const fastEma: number = PineJS.Std.ema(
          sourceSeries,
          fastLength,
          this._context,
        );
        const slowEma: number = PineJS.Std.ema(
          sourceSeries,
          slowLength,
          this._context,
        );

        const ppo: number = (100 * (fastEma - slowEma)) / slowEma;
        const ppoSeries: any = this._context.new_var(ppo);

        const signal: number = PineJS.Std.ema(
          ppoSeries,
          signalLength,
          this._context,
        );

        const histogram: number = ppo - signal;
        const histogramSeries: any = this._context.new_var(histogram);
        const histogramSeriesPrev: number = histogramSeries.get(1) || 0;

        let histogramColorer: number = NaN;
        switch (true) {
          case histogram < 0 && histogram < histogramSeriesPrev:
            histogramColorer = 0;
            break;
          case histogram < 0 && histogram > histogramSeriesPrev:
            histogramColorer = 1;
            break;
          case histogram > 0 && histogram < histogramSeriesPrev:
            histogramColorer = 2;
            break;
          case histogram > 0 && histogram > histogramSeriesPrev:
            histogramColorer = 3;
            break;
          default:
            histogramColorer = NaN;
            break;
        }

        // Return values
        return [histogram, histogramColorer, ppo, signal];
      };
    },
  };
};
