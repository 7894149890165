import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import { IFormation, useFormations } from "@/hooks/useFormations";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config";

const columnHelper = createColumnHelper<IFormation>();

const getBgClass = (side: string) => {
  return side === "UP" ? "bg-green-800" : "bg-red-800";
};

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("duration", {
    header: "Duration",
    cell: (row) => {
      const value = row.getValue();
      return <div className="font-semibold">{value}</div>;
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("type", {
    header: "Type",
    cell: (info) => {
      const value = info.getValue();
      return <div className="font-semibold">{value}</div>;
    },
    size: 120,
    minSize: 120,
  }),
];

export function FormationsPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: formations, isLoading } = useFormations();

  const preparedData = useMemo(() => {
    if (!formations) {
      return [];
    }

    let data =
      formations[BINANCE_RESOLUTION_MAP[config.interval]] ||
      formations[config?.interval];

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IFormation>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, formations, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
