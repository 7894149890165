import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

// {"timestamp":1734201380131,"timeFrame":"1m","data":[{"symbol":"1000000MOGUSDT","adf":-1.787,"crit":-2.573}]
export interface IADFResponse {
  timestamp: number;
  timeFrame: string;
  data: IADF[];
}

export interface IADF {
  symbol: string;
  adf: number;
  crit: number;
}

export function useADF(interval: string) {
  const handler = useCallback((_: IADFResponse, newData: IADFResponse) => {
    return newData;
  }, []);

  const topic =
    `sf.core.scripts.screener.augmentedDickeyFullerTest.${interval}.all.arr` as SocketTopics;

  return useGenericSocket<IADFResponse>(topic, handler);
}
