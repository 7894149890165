//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import { GetPriceSeries } from "@/lib/indicators/indicators-common-functions";

export const localVolalility = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Local volalility",
    metainfo: {
      _metainfoVersion: 51,
      id: "local-volalility@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Local volalility",
      shortDescription: "[SF] LV",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          lengthInput: 10,
          measureInput: "Percent",
        },
      },
      styles: {
        metricPlot: {
          title: "LV",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Percent",
          options: ["USD", "Percent"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [sourceInput, measureInput, lengthInput]: [
          string,
          string,
          number,
        ] = Array.from({ length: 3 }, (_, i) => this._input(i));

        // Market data parsing
        const sourceSeries: any = GetPriceSeries(
          PineJS,
          sourceInput,
          this._context,
        );

        // Calculation
        const sourceDelta: number = PineJS.Std.change(sourceSeries);
        const sourceDeltaPow: number = PineJS.Std.pow(sourceDelta, 2.0);
        const sourceDeltaPowSeries = this._context.new_var(sourceDeltaPow);
        const sourceDeltaPowAvg: number = PineJS.Std.sum(
          sourceDeltaPowSeries,
          lengthInput,
          this._context,
        );
        let localVol: number = PineJS.Std.sqrt(sourceDeltaPowAvg);
        if (measureInput === "Percent") {
          const hlc3: number = PineJS.Std.hlc3(this._context);
          localVol /= hlc3 / 100;
        }

        // Return
        return [localVol];
      };
    },
  };
};
