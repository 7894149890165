//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const openInterestDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Open interest delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "open-interest-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Open interest delta",
      shortDescription: "[SF] OI delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaColorer",
          type: StudyPlotType.Colorer,
          target: "oiDeltaPlot",
          palette: "oiDeltaPalette",
        },
        {
          id: "negOiDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "posOiDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        oiDeltaPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          oiDeltaPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          oiDeltaPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          oiDeltaColorer: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          negOiDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          posOiDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          oiDeltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        oiDeltaPlot: {
          title: "OI delta",
          histogramBase: 0,
        },
        negOiDeltaMaPlot: {
          title: "Negative OI delta MA",
          histogramBase: 0,
        },
        posOiDeltaMaPlot: {
          title: "Positive OI delta MA",
          histogramBase: 0,
        },
        oiDeltaStDevPlot: {
          title: "OI delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const oiSymbol: string = `${ParseSymbol(symbol)}${"#SF_OPEN_INTEREST"}`;
        this._context.new_sym(oiSymbol, period);
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number, number, number, number, number] {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [measureInput, maTypeInput, lengthInput, metricsBoolInput]: [
          string,
          string,
          number,
          boolean,
        ] = [this._input(1), this._input(2), this._input(3), this._input(4)];

        // Market data parsing
        this._context.select_sym(1);
        const priceData: { time: any; close: any } = {
          time: this._context.new_var(this._context.symbol.time),
          close: this._context.new_var(PineJS.Std.close(this._context)),
        };

        this._context.select_sym(2);
        const oiData: { time: any; close: any } = {
          time: this._context.new_var(this._context.symbol.time),
          close: this._context.new_var(PineJS.Std.close(this._context)),
        };

        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        const oi: number =
          oiData.close.adopt(oiData.time, mainSymbolTime, 0) || NaN;
        const oiSeries: any = this._context.new_var(oi);
        const price: number = priceData.close.adopt(
          priceData.time,
          mainSymbolTime,
          0,
        );

        // Calculations
        const oiDelta: number =
          measureInput === "USD"
            ? (oi - oiSeries.get(1)) * price
            : oi - oiSeries.get(1);

        let oiDeltaStdev: number, posOiDeltaMa: number, negOiDeltaMa: number;
        if (metricsBoolInput) {
          const oiDeltaSeries: any = this._context.new_var(oiDelta);
          const posOiDeltaSeries: any = this._context.new_var(
            oiDelta > 0 ? oiDelta : 0,
          );
          const negOiDeltaSeries: any = this._context.new_var(
            oiDelta < 0 ? oiDelta : 0,
          );

          posOiDeltaMa = MovingAverage(
            PineJS,
            posOiDeltaSeries,
            maTypeInput,
            lengthInput,
            this._context,
          );
          negOiDeltaMa = MovingAverage(
            PineJS,
            negOiDeltaSeries,
            maTypeInput,
            lengthInput,
            this._context,
          );
          oiDeltaStdev = PineJS.Std.stdev(
            oiDeltaSeries,
            lengthInput,
            this._context,
          );
        }

        // Return values
        return [
          0,
          oiDelta,
          Number(oiDelta > 0),
          negOiDeltaMa,
          posOiDeltaMa,
          oiDeltaStdev,
        ];
      };
    },
  };
};
