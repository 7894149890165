import { ISpoofLayerResponse } from "@/domain/interfaces/general.interface";
import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export function useSpoofLayer() {
  const handler = useCallback(
    (_: ISpoofLayerResponse, newData: ISpoofLayerResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<ISpoofLayerResponse>(
    SocketTopics.sizesSnapshot,
    handler,
  );
}
