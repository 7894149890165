export enum EAvailableWidgetOptions {
  timeFrame,
}

export enum EWidgetType { //  TODO move to widgets config constant, to keep everything in one place
  chart = "chart",
  manipulationMonitor = "manipulationMonitor",
  spotFuturesSpread = "spotFuturesSpread",
  controlPanel = "controlPanel",
  strategies = "strategies",
  stopKiller = "stopKiller",
  priceScreener = "priceScreener",
  oiScreener = "oiScreener",
  volumeScreener = "volumeScreener",
  deltaScreener = "deltaScreener",
  liquidationsScanner = "liquidationsScanner",
  pairsScanner = "pairsScanner",
  predictLiquidation = "predictLiquidation",
  cascadeLevels = "cascadeLevels",
  adtvScreener = "adtvScreener",
  activityDetectorScreener = "activityDetectorScreener",
  activityDetectorTdScreener = "activityDetectorTdScreener",
  cryptoDriftScreener = "cryptoDriftScreener",
  marketRatioScreener = "marketRatioScreener",
  spreadDominationScreener = "spreadDominationScreener",
  powerTradesScreener = "powerTradesScreener",
  htfActivityScreener = "htfActivityScreener",
  trendAssessmentScreener = "trendAssessmentScreener",
  sizeHighlighter = "sizeHighlighter",
  spoofLayer = "spoofLayer",
  formations = "formations",
  patternPredict = "patternPredict",
  candlePattern = "candlePattern",
  marketPower = "marketPower",
  retailPower = "retailPower",
  smartHiLow = "smartHiLow",
  classicSma = "classicSma",
  classicRsi = "classicRsi",
  classicAdx = "classicAdx",
  classicPpo = "classicPpo",
  classicNatr = "classicNatr",
  classicCmo = "classicCmo",
  classicNmi = "classicNmi",
  classicMacd = "classicMacd",
  classicCci = "classicCci",
  classicMfi = "classicMfi",
  classicRoc = "classicRoc",
  classicKco = "classicKco",
  classicWilliamsPrcR = "classicWilliamsPrcR",
  classicBollingerPrc = "classicBollingerPrc",
  classicStochastic = "classicStochastic",
  adf = "adf",
}

export enum EFilterType {
  text = "text",
  select = "select",
  number = "number",
  range = "range",
  multiSelect = "multiSelect",
  object = "object",
}

export enum ENumberComparisonType {
  equal = "equal",
  greater = "greater",
  less = "less",
  mixed = "mixed",
}

export enum ESFTableType {
  chart = "chart",
  pinned = "pinned",
  unpinned = "unpinned",
}
