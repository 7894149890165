import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface IActivityDetectorTdResponse {
  data: IActivityDetectorTd[];
  timestamp: number;
}

export interface IActivityDetectorTd {
  symbol: string;
  value: number;
  valueCum: number;
}

export function useActivityDetectorTd(timeframe: string) {
  const handler = useCallback(
    (_: IActivityDetectorTdResponse, newData: IActivityDetectorTdResponse) => {
      return newData;
    },
    [],
  );

  // "sf.core.scripts.screener.activityDetectorTd.1m.all.arr"
  const topic =
    `sf.core.scripts.screener.activityDetectorTd.${timeframe}.all.arr` as SocketTopics;

  return useGenericSocket<IActivityDetectorTdResponse>(topic, handler);
}
