//eslint-disable-next-line
//@ts-nocheck

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const averageDayTradeSpeed = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Average day trade speed",
    metainfo: {
      _metainfoVersion: 51,
      id: "average-day-trade-speed@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Average day trade speed",
      shortDescription: "[SF] ADTS",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "adtsPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          adtsPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
        },
      },
      styles: {
        adtsPlot: {
          title: "ADTS",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const volumeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sideInput: string = this._input(1);

        //Market data parsing
        this._context.select_sym(1);
        const tapeTime: any = this._context.new_var(this._context.symbol.time);
        const tapeArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        const length: number = 1440 / Number(PineJS.Std.period(this._context));
        const tape: number = tapeArray.adopt(tapeTime, mainSymbolTime, 0);
        const tapeSeries: any = this._context.new_var(tape);

        const adts: number =
          PineJS.Std.sum(tapeSeries, length, this._context) / 1440;

        //Returns
        return [adts];
      };
    },
  };
};
