//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const bestAskAndBidSize = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Best ask & bid size",
    metainfo: {
      _metainfoVersion: 51,
      id: "best-ask-and-bid-size@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Best ask & bid size",
      shortDescription: "[SF] Best ask & bid size",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "bestAskPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bestBidPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          bestAskPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          bestBidPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
            transparency: 0,
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "USD",
        },
      },
      styles: {
        bestAskPlot: {
          title: "Ask",
          histogramBase: 0,
        },
        bestBidPlot: {
          title: "Bid",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: any = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;

        const baseSymbol: string = ParseSymbol(symbol);
        this._context.new_sym(symbol, period);

        const orderbookSymbol: string = `${baseSymbol}#BIDASKQUOTEDSPREAD`;
        this._context.new_sym(orderbookSymbol, period);
      };

      this.main = function (
        context: any,
        inputCallback: any,
      ): [number, number] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const measureInput: string = this._input(1);

        // Market data parsing
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        this._context.select_sym(2);
        const orderbookData: {
          time: any;
          askSize: any;
          bidSize: any;
        } = {
          time: this._context.new_var(this._context.symbol.time),
          askSize: this._context.new_var(PineJS.Std.high(this._context)),
          bidSize: this._context.new_var(PineJS.Std.close(this._context)),
        };

        this._context.select_sym(0);
        if (measureInput !== "USD") {
          return [
            orderbookData.askSize.adopt(
              orderbookData.time,
              mainSymbolTimeSeries,
              0,
            ),
            -orderbookData.bidSize.adopt(
              orderbookData.time,
              mainSymbolTimeSeries,
              0,
            ),
          ];
        }

        this._context.select_sym(1);
        const priceData: {
          time: any;
          close: any;
        } = {
          time: this._context.new_var(this._context.symbol.time),
          close: this._context.new_var(PineJS.Std.close(this._context)),
        };

        this._context.select_sym(0);
        const closePrice: number = priceData.close.adopt(
          priceData.time,
          mainSymbolTimeSeries,
          0,
        );

        // Return values
        return [
          orderbookData.askSize.adopt(
            orderbookData.time,
            mainSymbolTimeSeries,
            0,
          ) * closePrice,
          -orderbookData.bidSize.adopt(
            orderbookData.time,
            mainSymbolTimeSeries,
            0,
          ) * closePrice,
        ];
      };
    },
  };
};
