//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import { ParseSymbol } from "@/lib/indicators/indicators-common-functions";

export const trendAssessment = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Trend assessment",
    metainfo: {
      _metainfoVersion: 51,
      id: "trend-assessment@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Trend assessment",
      shortDescription: "[SF] Trend assessment",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "threadPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "smallPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "mediumPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "hugePlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          threadPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(178, 24, 44, 0.15)",
          },
          smallPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(60, 166, 75, 0.05)",
          },
          mediumPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(60, 166, 75, 0.1)",
          },
          hugePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(60, 166, 75, 0.15)",
          },
        },
        precision: 4,
        inputs: {
          symbolInput: "",
        },
      },
      styles: {
        threadPlot: {
          title: "Thread",
          histogramBase: 0,
        },
        smallPlot: {
          title: "Small",
          histogramBase: 0,
        },
        mediumPlot: {
          title: "Medium",
          histogramBase: 0,
        },
        hugePlot: {
          title: "Huge",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const taSymbol: string = `${baseSymbol}#TREND_ASSESSMENT`;
        const period: number = PineJS.Std.period(this._context);
        this._context.new_sym(taSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        this._context.select_sym(1);
        const taSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const taThreadSeries: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const taSmallSeries: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const taMediumSeries: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const taHugeSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        const thread: number = taThreadSeries.adopt(
          taSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        const small: number = taSmallSeries.adopt(
          taSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        const medium: number = taMediumSeries.adopt(
          taSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        const huge: number = taHugeSeries.adopt(
          taSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );

        return [thread, small, medium, huge];
      };
    },
  };
};
