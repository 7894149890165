import { usePaneContext } from "@/components/pane/paneContext.ts";
import { FavoriteStar } from "@/components/shared/favoriteStar.tsx";
import { SortingArrow } from "@/components/shared/sortingArrow.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";

import { ESFTableType, EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { IRow, ISfTable } from "@/domain/interfaces/sfTable.interface.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useDashboard } from "@/lib/store.ts";
import { cn } from "@/lib/utils.ts";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { merge } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

export function SfTable({
  // todo add virtualization
  data,
  columns,
  withHeader = true,
  rowClassCb,
  onRowClick,
  className,
  tableType,
}: ISfTable) {
  const nodeId = usePaneContext();
  const { node, updateTabConfig, getNodeConfig } = useGrid(nodeId);
  const widgetType = node?.getComponent() as EWidgetType;
  const { blacklist, addSymbolToBlacklist, removeSymbolFromBlacklist } =
    useDashboard((state) => ({
      blacklist: state.blacklist,
      addSymbolToBlacklist: state.addSymbolToBlacklist,
      removeSymbolFromBlacklist: state.removeSymbolFromBlacklist,
    }));
  const color = getNodeConfig().color;
  const {
    colorsToSymbolMap,
    updatePinned,
    pinned: pinState,
  } = useDashboard((state) => ({
    updatePinned: state.updatePinned,
    pinned: state.pinned.find((e) => e.widgetType === widgetType),
    colorsToSymbolMap: state.colorsToSymbolMap,
  }));

  const nodeSymbol = colorsToSymbolMap[color];

  const togglePin = useCallback(
    (symbol: string) => {
      updatePinned((state) => {
        const widget = state.find((e) => e.widgetType === widgetType);
        if (widget) {
          if (widget.symbols.includes(symbol)) {
            widget.symbols = widget.symbols.filter((s) => s !== symbol);
          } else {
            widget.symbols.push(symbol);
          }
        } else {
          state.push({
            widgetType,
            symbols: [symbol],
          });
        }
      });
    },
    [widgetType, updatePinned],
  );

  const toggleBlacklist = useCallback(
    (symbol: string) => {
      if (blacklist.includes(symbol)) {
        removeSymbolFromBlacklist(symbol);
      } else {
        addSymbolToBlacklist(symbol);
      }
    },
    [blacklist, addSymbolToBlacklist, removeSymbolFromBlacklist],
  );

  const [sortingState, setSortingState] = useState<SortingState>(
    getNodeConfig()?.sortingState ?? [],
  );

  const updatedColumns = useMemo(
    () => [
      ...[...columns].map((column) => ({
        enableResizing: false,
        ...column,
      })),
      {
        id: "pin",
        enableResizing: false,
        cell: ({ row }: { row: Row<IRow> }) => (
          <div className="w-full flex justify-between items-center gap-1">
            <FavoriteStar symbolName={row.original.symbol} />
            <div
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                togglePin(row.original.symbol as string);
              }}
            >
              <i
                className={cn(
                  pinState?.symbols?.includes(row.original.symbol)
                    ? "fa-solid fa-thumbtack"
                    : "fa-regular fa-thumbtack text-gray-400 transform rotate-45",
                )}
              />
            </div>
            <div
              className="pl-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                toggleBlacklist(row.original.symbol as string);
              }}
            >
              <i className="fa-regular text-red-400 fa-flag" />
            </div>
          </div>
        ),
        size: 50,
        maxSize: 50,
      },
    ],
    [togglePin, toggleBlacklist, blacklist, columns, pinState],
  );

  const staticColIds = ["blacklist", "favorite", "pin"];

  const changeSorting = (columnId: string) => {
    setSortingState((old) => {
      const exist = old.find((s) => s.id === columnId);
      let newSortingState: SortingState;
      if (!exist) {
        newSortingState = [{ id: columnId, desc: false }];
      } else if (!exist.desc) {
        newSortingState = [{ id: columnId, desc: true }];
      } else {
        newSortingState = [{ id: columnId, desc: false }];
      }
      updateTabConfig({ sortingState: newSortingState });

      return newSortingState;
    });
  };

  function getSortDirection(headerId: string, sortingState: SortingState) {
    const sortingObject = sortingState.find((s) => s.id === headerId);
    return sortingObject ? (sortingObject.desc ? "desc" : "asc") : undefined;
  }

  const tableConfig = useMemo(
    () =>
      ({
        columns: updatedColumns,
        onSortingChange: setSortingState,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: "onChange",
        initialState: { pagination: { pageSize: 50, pageIndex: 0 } },
        defaultColumn: {
          size: 10,
          minSize: 10,
          maxSize: 200,
        },
        debugTable: false,
      }) as Omit<TableOptions<IRow>, "data">,
    [updatedColumns, setSortingState],
  );

  const pinned = useReactTable({
    ...tableConfig,
    data: data.pinned,
    enableSortingRemoval: false,
    meta: {
      tableType: Array.from(new Set([...tableType, ESFTableType.pinned])),
    },
  });

  const unPinned = useReactTable({
    ...tableConfig,
    data: data.unpinned,
    enableSortingRemoval: false,
    meta: {
      tableType: Array.from(new Set([...tableType, ESFTableType.unpinned])),
    },
  });

  useEffect(() => {
    const newColumnSizes = merge(
      {},
      getNodeConfig().columnSizes,
      unPinned.getState().columnSizing,
    );
    updateTabConfig({
      columnSizes: newColumnSizes,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinState, unPinned.getState().columnSizing]);

  const columnSizeVars = useMemo(() => {
    const headers = unPinned.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unPinned.getState().columnSizingInfo]);

  return (
    <Table
      style={{
        ...columnSizeVars,
      }}
      className={cn(className)}
    >
      {
        <TableHeader className={cn(!withHeader && "collapse")}>
          {unPinned.getHeaderGroups().map((headerGroup) => (
            <TableRow className="bg-opacity-20" key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  className="relative"
                  style={{
                    width:
                      header.column.columnDef.size !== undefined &&
                      header.column.columnDef.size !== 10
                        ? `calc(var(--header-${header.id}-size) * 1px)`
                        : "auto",
                    maxWidth: header.column.columnDef.maxSize,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={cn(
                        "flex items-center",
                        i !== 0 && "justify-end",
                        header.column.getCanSort() &&
                          "cursor-pointer select-none gap-2",
                      )}
                      onClick={() =>
                        header.column.getCanSort() && changeSorting(header.id)
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() &&
                        (getSortDirection(header.id, sortingState) ? (
                          <SortingArrow
                            direction={getSortDirection(
                              header.id,
                              sortingState,
                            )}
                          />
                        ) : (
                          <SortingArrow direction="desc" isPlaceholder={true} />
                        ))}
                    </div>
                  )}
                  {header.column.getCanResize() && (
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          header.column.getIsResizing() ? "isResizing" : ""
                        }`,
                      }}
                    />
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
      }
      <TableBody>
        {pinned.getRowModel().rows.map((row, i) => (
          <TableRow
            key={row.id}
            className={cn(
              i === data.pinned.length - 1 &&
                "h-4 border-b-white border-t-white border-t-[1px] border-b-[1px]",
              rowClassCb?.(row.original, i, pinned),
              nodeSymbol === row.original.symbol && "bg-[mediumblue] active",
            )}
            onClick={() => onRowClick?.(row.original)}
          >
            {row.getVisibleCells().map((cell, cellIdx) => (
              <TableCell
                key={cell.id}
                className={cn(
                  cellIdx !== 0 && "text-right",
                  cell.column.getIsSorted() && "font-bold",
                  cell.column.id === "symbol" && "text-yellow-300",
                )}
                style={{
                  width:
                    cell.column.columnDef.size !== undefined &&
                    cell.column.columnDef.size !== 10
                      ? `calc(var(--cell-${cell?.id}-size) * 1px)`
                      : "auto",
                  maxWidth: cell.column.columnDef.maxSize,
                }}
              >
                {!staticColIds.includes(cell.column.id) &&
                !cell.getValue() &&
                !cell.getContext() ? (
                  <>-</>
                ) : (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {unPinned.getRowModel().rows.map((row, rowIdx) => (
          <TableRow
            key={row.id}
            className={cn(
              "h-4 border-t-neutral-700 border-b-neutral-700 border-t border-b",
              rowClassCb?.(row.original, rowIdx, unPinned),
              nodeSymbol === row.original.symbol && "bg-[mediumblue] active",
            )}
            onClick={() => onRowClick?.(row.original)}
          >
            {row.getVisibleCells().map((cell, cellIdx) => (
              <TableCell
                key={cell.id}
                className={cn(
                  cellIdx === 0 && "pl-2",
                  cellIdx !== 0 && "text-right",
                  cell.column.getIsSorted() && "font-bold",
                )}
                style={{
                  width:
                    cell.column.columnDef.size !== undefined &&
                    cell.column.columnDef.size !== 10
                      ? `calc(var(--cell-${cell?.id}-size) * 1px)`
                      : "auto",
                  maxWidth: cell.column.columnDef.maxSize,
                }}
              >
                {!staticColIds.includes(cell.column.id) &&
                !cell.getValue() &&
                !cell.getContext() ? (
                  <>-</>
                ) : (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
