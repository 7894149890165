//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const bidAskDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Bid & ask delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "bid-ask-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Bid & ask delta",
      shortDescription: "[SF] Bid & ask delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaColorer",
          type: StudyPlotType.Colorer,
          target: "deltaPlot",
          palette: "deltaPalette",
        },
        {
          id: "negDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "posDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        deltaPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          deltaPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          deltaPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          negDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          posDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          deltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          sourceInput: "DOM by 1%",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 60,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        deltaPlot: {
          title: "Delta",
          histogramBase: 0,
        },
        negDeltaMaPlot: {
          title: "Negative delta MA",
          histogramBase: 0,
        },
        posDeltaMaPlot: {
          title: "Positive delta MA",
          histogramBase: 0,
        },
        deltaStDevPlot: {
          title: "Delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "sourceInput",
          name: "Source",
          defval: "DOM by 1%",
          options: [
            "Filled orders",
            "Best bid & ask size",
            "DOM by 1%",
            "DOM by 2%",
            "DOM by 3%",
            "DOM by 4%",
            "DOM by 5%",
            "DOM by 6%",
            "DOM by 7%",
            "DOM by 8%",
            "DOM by 9%",
            "DOM by 10%",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD", "Percent"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      const InitializeSymbol = (symbolName: string, period: any): void => {
        this._context.new_sym(symbolName, period);
      };

      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const period: any = PineJS.Std.period(this._context);

        // Инициализируем основной символ
        InitializeSymbol(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);

        // Массив суффиксов для создания символов
        const symbolSuffixes: string[] = [
          "#SF_VOLUME",
          "#BIDASKQUOTEDSPREAD",
          "#ASKORDERBOOKSUM1_5",
          "#BIDORDERBOOKSUM1_5",
          "#ASKORDERBOOKSUM6_10",
          "#BIDORDERBOOKSUM6_10",
        ];

        symbolSuffixes.forEach((suffix: string) => {
          InitializeSymbol(`${baseSymbol}${suffix}`, period);
        });
      };

      const GetSymbolData = (
        symIndex: number,
        mainSymbolTimeSeries: any,
      ): { ask: number; bid: number } => {
        this._context.select_sym(symIndex);
        const timeArray: any = this._context.new_var(this._context.symbol.time);

        let ask: number, bid: number;

        if (symIndex === 1) {
          // Filled orders
          const volumeArray: any = this._context.new_var(
            PineJS.Std.volume(this._context),
          );
          this._context.select_sym(2);
          const askArray: any = this._context.new_var(
            PineJS.Std.low(this._context),
          );
          this._context.select_sym(0);

          const volume: number = volumeArray.adopt(
            timeArray,
            mainSymbolTimeSeries,
            0,
          );
          ask = askArray.adopt(timeArray, mainSymbolTimeSeries, 0);
          bid = volume - ask;
        } else if (symIndex === 3) {
          // Best bid & ask size
          const askArray: any = this._context.new_var(
            PineJS.Std.high(this._context),
          );
          const bidArray: any = this._context.new_var(
            PineJS.Std.close(this._context),
          );
          this._context.select_sym(0);

          ask = askArray.adopt(timeArray, mainSymbolTimeSeries, 0);
          bid = bidArray.adopt(timeArray, mainSymbolTimeSeries, 0);
        } else {
          // DOM cases
          const domData = GetDOMData(symIndex, this._input(1));
          ask = domData.askArray.adopt(timeArray, mainSymbolTimeSeries, 0);
          bid = domData.bidArray.adopt(timeArray, mainSymbolTimeSeries, 0);
        }

        return { ask, bid };
      };

      // GetDOMData - function for getting order book data from different levels
      const GetDOMData = (symIndex: number, sourceInput: string): any => {
        const domLevel: number = parseInt(sourceInput.match(/\d+/)[0]);

        const GetDataByLevel = (context: any): any => {
          const levelMap: { [key: number]: string } = {
            1: "open",
            2: "high",
            3: "low",
            4: "close",
            5: "volume",
          };
          const dataType: string = levelMap[domLevel % 5 || 5];
          return PineJS.Std[dataType](context);
        };

        const timeArray: any = this._context.new_var(this._context.symbol.time);
        const askArray: any = this._context.new_var(
          GetDataByLevel(this._context),
        );

        this._context.select_sym(symIndex + 1);
        const bidArray: any = this._context.new_var(
          GetDataByLevel(this._context),
        );

        return { askArray, bidArray };
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const [sourceInput, measureType, maType, maLength, showMetrics]: [
          string,
          string,
          string,
          number,
          boolean,
        ] = Array.from({ length: 5 }, (_, i) => this._input(i + 1));

        // Market data parsing
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const symIndex: number = (() => {
          if (sourceInput === "Filled orders") {
            return 1;
          }
          if (sourceInput === "Best bid & ask size") {
            return 3;
          }
          return parseInt(sourceInput.match(/\d+/)[0]) <= 5 ? 4 : 6;
        })();

        const { ask, bid } = GetSymbolData(symIndex, mainSymbolTimeSeries);

        // Calculations
        let bidAskDelta: number = bid - ask;

        if (measureType === "USD") {
          this._context.select_sym(1);
          const closeTime: any = this._context.new_var(
            this._context.symbol.time,
          );
          const closeArray: any = this._context.new_var(
            PineJS.Std.close(this._context),
          );
          this._context.select_sym(0);
          bidAskDelta *= closeArray.adopt(closeTime, mainSymbolTimeSeries, 0);
        } else if (measureType === "Percent") {
          this._context.select_sym(0);
          bidAskDelta /= (bid + ask) * 0.01;
        } else {
          this._context.select_sym(0);
        }

        // Инициализация метрик
        const defaultMetrics: {
          posDeltaMa: number;
          negDeltaMa: number;
          deltaStdev: number;
        } = {
          posDeltaMa: NaN,
          negDeltaMa: NaN,
          deltaStdev: NaN,
        };

        // Расчет метрик если включено
        const metrics = showMetrics
          ? CalculateMetrics(bidAskDelta, maType, maLength)
          : defaultMetrics;

        return [
          0, // Zero line
          bidAskDelta,
          bidAskDelta > 0 ? 1 : 0, // Color indicator
          metrics.negDeltaMa,
          metrics.posDeltaMa,
          metrics.deltaStdev,
        ];
      };

      // CalculateMetrics - function for calculating metrics
      const CalculateMetrics = (
        bidAskDelta: number,
        maType: string,
        length: number,
      ): { posDeltaMa: number; negDeltaMa: number; deltaStdev: number } => {
        const posDelta: number = Math.max(bidAskDelta, 0);
        const negDelta: number = Math.min(bidAskDelta, 0);

        const series = {
          bidAskDelta: this._context.new_var(bidAskDelta),
          posDelta: this._context.new_var(posDelta),
          negDelta: this._context.new_var(negDelta),
        };

        return {
          posDeltaMa: MovingAverage(
            PineJS,
            series.posDelta,
            maType,
            length,
            this._context,
          ),
          negDeltaMa: MovingAverage(
            PineJS,
            series.negDelta,
            maType,
            length,
            this._context,
          ),
          deltaStdev: PineJS.Std.stdev(
            series.bidAskDelta,
            length,
            this._context,
          ),
        };
      };
    },
  };
};
