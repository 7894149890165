//eslint-disable-next-line
//@ts-nocheck
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  MovingAverage,
} from "@/lib/indicators/indicators-common-functions";

export const averageTradesSize = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Average trade size",
    metainfo: {
      _metainfoVersion: 51,
      id: "average-trade-size@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Average trade size",
      shortDescription: "[SF] Average trade size",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 0,
      },
      plots: [
        {
          id: "averageTradesSizePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "stDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          averageTradesSizePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          maPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Line,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.66)",
          },
          stDevPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.33)",
          },
        },
        precision: 0,
        inputs: {
          symbolInput: "",
          displayModeInput: "Total",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 20,
          metricsBoolInput: false,
        },
      },
      styles: {
        averageTradesSizePlot: {
          title: "Average trade size",
          histogramBase: 0,
        },
        maPlot: {
          title: "Average trade size MA",
          histogramBase: 0,
        },
        stDevPlot: {
          title: "Average trade size sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "displayModeInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context: any, inputCallback: any): void {
        this._context = context;
        this._input = inputCallback;

        const period: any = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);
        const tapeSymbol: string = `${baseSymbol}#SF_VOLUME`;
        this._context.new_sym(tapeSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const inputs: { [key: string]: any } = {
          displayMode: this._input(1),
          measure: this._input(2),
          maType: this._input(3),
          length: this._input(4),
          metricsEnabled: this._input(5),
        };

        // Market data parsing
        const marketData: { [key: string]: any } = this._getMarketData();

        // Calculations
        const baseMetrics: { [key: string]: number } = {
          sellVolume: marketData.volume - marketData.buyVolume,
          measureCoeff: inputs.measure === "USD" ? marketData.hlc3 : 1,
        };

        const averageTradesSize: number = this._calculateAverageTradeSize(
          inputs.displayMode,
          marketData,
          baseMetrics,
        );

        if (!inputs.metricsEnabled) {
          return [averageTradesSize, NaN, NaN];
        }

        const derivativeMetrics: number[] = this._calculateDerivativeMetrics(
          averageTradesSize,
          inputs.maType,
          inputs.length,
        );

        // Return values
        return [averageTradesSize, ...derivativeMetrics];
      };

      // Выносим получение данных рынка в отдельный метод
      this._getMarketData = function (): { [key: string]: any } {
        this._context.select_sym(1);
        const volumeTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const volumeArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );
        const hlc3Array: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );

        this._context.select_sym(2);
        const tapeTime: any = this._context.new_var(this._context.symbol.time);
        const tapeArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const buyVolumeArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        return {
          hlc3: hlc3Array.adopt(tapeTime, mainSymbolTime, 0),
          volume: volumeArray.adopt(volumeTime, mainSymbolTime, 0),
          buyVolume: buyVolumeArray.adopt(tapeTime, mainSymbolTime, 0),
          tradeNumber: tapeArray.adopt(tapeTime, mainSymbolTime, 0),
        };
      };

      this._calculateAverageTradeSize = function (
        displayMode: string,
        marketData: { [key: string]: number },
        baseMetrics: { [key: string]: number },
      ): number {
        const calculations: { [key: string]: () => number } = {
          Total: () =>
            (marketData.volume / marketData.tradeNumber) *
            baseMetrics.measureCoeff,
          Buy: () =>
            (((marketData.buyVolume / marketData.tradeNumber) *
              marketData.buyVolume) /
              marketData.volume) *
            baseMetrics.measureCoeff,
          Sell: () =>
            (((baseMetrics.sellVolume / marketData.tradeNumber) *
              baseMetrics.sellVolume) /
              marketData.volume) *
            baseMetrics.measureCoeff,
          Delta: () =>
            ((((marketData.buyVolume - baseMetrics.sellVolume) /
              marketData.tradeNumber) *
              baseMetrics.sellVolume) /
              marketData.volume) *
            baseMetrics.measureCoeff,
        };

        return (calculations[displayMode] || calculations.Total)();
      };

      this._calculateDerivativeMetrics = function (
        averageTradesSize: number,
        maType: string,
        length: number,
      ): number[] {
        const series: any = this._context.new_var(averageTradesSize);

        const maCalculations: { [key: string]: () => number } = {
          SMA: () => PineJS.Std.sma(series, length, this._context),
          EMA: () => PineJS.Std.ema(series, length, this._context),
          WMA: () => PineJS.Std.wma(series, length, this._context),
          VWMA: () => PineJS.Std.vwma(series, length, this._context),
          LSMA: () => PineJS.Std.linreg(series, length, 0),
        };

        const ma: number = (maCalculations[maType] || maCalculations.SMA)();
        const stdev: number = PineJS.Std.stdev(series, length, this._context);

        return [ma, stdev];
      };
    },
  };
};
