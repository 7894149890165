import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

import { ISpoofLayer } from "@/domain/interfaces/general.interface";
import { useSpoofLayer } from "@/hooks/useSpoofLayer";
import numeral from "numeral";

const columnHelper = createColumnHelper<ISpoofLayer>();

const getBgClass = (side: string) => {
  return side === "UP" ? "bg-green-800" : "bg-red-800";
};

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("price", {
    header: "Price",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("size", {
    header: "Size",
    cell: (info) => {
      const value = info.getValue();
      const side = info.row.original.side;
      const bgClass = getBgClass(side);

      return (
        <div className={cn(bgClass, "font-bold")}>
          {numeral(value).format("0,0.00a").toUpperCase()}
        </div>
        // <HighlightedValueChange
        //   value={value}
        //   highlightNewTextColor={false}
        //   highlightPosNeg={false}
        //   className={cn(bgClass, "w-full font-bold")}
        //   decimal={2}
        // />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("prc", {
    header: "Prc",
    cell: (info) => {
      const value = info.getValue();

      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn("font-bold")}
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
];

export function SpoofLayerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: spoofLayer, isLoading } = useSpoofLayer();

  const preparedData = useMemo(() => {
    if (!spoofLayer?.data) {
      return [];
    }

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        spoofLayer.data = spoofLayer.data.filter(
          (item) => item.symbol !== symbol,
        );
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ISpoofLayer>(
        spoofLayer.data,
        config.filters,
        config.filterValues,
      );
    }

    return spoofLayer.data;
  }, [config, spoofLayer, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
