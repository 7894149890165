import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";
import {
  ITrendAssessmentData,
  ITrendAssessmentResponse,
  useTrendAssessment,
} from "@/hooks/useTrendAssessment";

const columnHelper = createColumnHelper<ITrendAssessmentData>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("HugeTrendCum", {
    header: "Huge",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("mediumTrendCum", {
    header: "Medium",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("smallTrendCum", {
    header: "Small",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
  columnHelper.accessor("threadCum", {
    header: "Thread",
    cell: (row) => {
      const value = row.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className="font-bold"
          decimal={2}
        />
      );
    },
    size: 120,
    minSize: 120,
  }),
];

export function TrendAssessmentPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: trendAssessment, isLoading } = useTrendAssessment();

  const preparedData = useMemo(() => {
    if (!trendAssessment?.data) {
      return [];
    }

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        trendAssessment.data = trendAssessment.data.filter(
          (item) => item.symbol !== symbol,
        );
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ITrendAssessmentData>(
        trendAssessment.data,
        config.filters,
        config.filterValues,
      );
    }

    return trendAssessment.data;
  }, [config, trendAssessment?.data, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
