//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  ChannelBounds,
} from "@/lib/indicators/indicators-common-functions";

export const cumulativeStopKillerDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Cumulative stop killer delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "cumulative-stop-killer-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Cumulative stop killer delta",
      shortDescription: "[SF] CSKD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "cskdOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cskdUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cskdLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CSKD" },
            1: { name: "Growing CSKD" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CSKD" },
            1: { name: "Growing CSKD" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CSKD" },
            1: { name: "Growing CSKD" },
          },
        },
      },
      ohlcPlots: {
        cskdOhlcPlotcandle: {
          title: "CSKD plot candle",
        },
      },
      defaults: {
        styles: {
          cskdMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cskdUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cskdLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          cskdOhlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
        },
      },
      styles: {
        cskdMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        cskdUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        cskdLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        this._context.new_sym(symbol, period);

        const baseSymbol: string = ParseSymbol(symbol);
        const stopkillerSymbol: string = `${baseSymbol}#SF_STOPKILLER`;
        this._context.new_sym(stopkillerSymbol, period);
      };

      this.main = function (context: any, inputCallback: any): number[] {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [measureInput, maType, length, channelType, metricsBoolInput]: [
          string,
          string,
          number,
          string,
          boolean,
        ] = Array.from({ length: 5 }, (_, i) => this._input(i + 1));

        // Market data parsing
        this._context.select_sym(2);
        const stopkillerTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const significantShortArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const significantLongArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const criticalShortArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const criticalLongArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        const significantShort: number =
          significantShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const significantLong: number =
          significantLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalShort: number =
          criticalShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalLong: number =
          criticalLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;

        // Calculations
        let buyStoploss: number = significantShort + criticalShort;
        let sellStoploss: number = significantLong + criticalLong;
        if (measureInput === "Coins") {
          const hlc3: number = PineJS.Std.hlc3(this._context);
          buyStoploss /= hlc3;
          sellStoploss /= hlc3;
        }
        const stoplossDelta: number = buyStoploss + sellStoploss || 0;
        const cskdClose: number = PineJS.Std.cum(stoplossDelta, this._context);

        const cskdCloseSeries: any = this._context.new_var(cskdClose);
        const cskdOpen: number = cskdCloseSeries.get(1);
        const cskdOpenSeries: any = this._context.new_var(cskdOpen);
        const cskdHigh: number = PineJS.Std.max(cskdOpen, cskdClose);
        const cskdHighSeries: any = this._context.new_var(cskdHigh);
        const cskdLow: number = PineJS.Std.min(cskdOpen, cskdClose);
        const cskdLowSeries: any = this._context.new_var(cskdLow);
        const cskdColor: number = cskdClose > cskdOpen ? 1 : 0;

        // Derivative metrics
        const [cskdMa, cskdUpperBand, cskdLowerBand]: [number, number, number] =
          metricsBoolInput
            ? ChannelBounds(
                PineJS,
                cskdLowSeries,
                cskdHighSeries,
                cskdCloseSeries,
                channelType,
                maType,
                length,
                this._context,
              )
            : [NaN, NaN, NaN];

        // Returns
        return [
          cskdOpen,
          cskdHigh,
          cskdLow,
          cskdClose,
          cskdColor,
          cskdColor,
          cskdColor,
          cskdMa,
          cskdUpperBand,
          cskdLowerBand,
        ];
      };
    },
  };
};
