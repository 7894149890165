//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import { GetPrice } from "@/lib/indicators/indicators-common-functions";

export const garchmVolatilityEstimation = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "GARCH-M volatility estimation",
    metainfo: {
      _metainfoVersion: 51,
      id: "garch-m-volatility-estimation@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] GARCH-M volatility estimation",
      shortDescription: "[SF] GARCH-M vol",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          alphaInput: 0.1,
          betaInput: 0.8,
          lengthInput: 10,
          measureInput: "Percent",
        },
      },
      styles: {
        metricPlot: {
          title: "GARCH-M vol",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "alphaInput",
          name: "Alpha",
          defval: 0.1,
          min: 0.1,
          max: 100,
          step: 0.1,
          type: StudyInputType.Float,
        },
        {
          id: "betaInput",
          name: "Beta",
          defval: 0.8,
          min: 0.1,
          max: 100,
          step: 0.1,
          type: StudyInputType.Float,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Percent",
          options: ["USD", "Percent"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const [sourceInput, alphaInput, betaInput, lengthInput, measureInput]: [
          string,
          number,
          number,
          number,
          string,
        ] = Array.from({ length: 5 }, (_, i) => this._input(i));

        // Market data parsing
        const source: number = GetPrice(PineJS, sourceInput, this._context);
        const sourceSeries: any = this._context.new_var(source);

        // Risk-free rate calculations
        const time: number = PineJS.Std.time(this._context);
        const timeSeries: any = this._context.new_var(time);
        const timePeriod: number =
          (time - timeSeries.get(lengthInput)) / 31536000000;
        const riskFreeRate: number = (timePeriod * 10.95) / 100;

        // Calculation
        const ema: number = PineJS.Std.ema(
          sourceSeries,
          lengthInput,
          this._context,
        );
        const returns: number = source - ema;
        const returnsSeries: any = this._context.new_var(returns);
        const returnsStdev: number = PineJS.Std.stdev(
          returnsSeries,
          lengthInput,
          this._context,
        );

        // Упрощенное вычисление дисперсии без итераций
        const prevVariance: any = this._context.new_var(0);
        const variance: number =
          alphaInput * Math.pow(source - ema, 2) +
          betaInput *
            PineJS.Std.nz(prevVariance.get(1), Math.pow(returnsStdev, 2));

        // Расчет волатильности с учетом риск-премии (GARCH-M специфика)
        let volatility: number =
          ((100 + riskFreeRate) / 100) * PineJS.Std.sqrt(variance);
        if (measureInput === "Percent") {
          volatility = (volatility / ema) * 100;
        }

        return [volatility];
      };
    },
  };
};
