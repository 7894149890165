//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

import {
  ParseSymbol,
  ChannelBounds,
} from "@/lib/indicators/indicators-common-functions";

export const cumulativeLiquidationsDelta = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Cumulative liquidations delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "cumulative-liquidations-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Cumulative liquidations delta",
      shortDescription: "[SF] CLD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "cldOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "cldOhlcPlotcandle",
        },
        {
          id: "cldMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cldUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cldLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CLD" },
            1: { name: "Growing CLD" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CLD" },
            1: { name: "Growing CLD" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CLD" },
            1: { name: "Growing CLD" },
          },
        },
      },
      ohlcPlots: {
        cldOhlcPlotcandle: {
          title: "CLD plot candle",
        },
      },
      defaults: {
        styles: {
          cldMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cldUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cldLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          cldOhlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
        },
      },
      styles: {
        cldMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        cldUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        cldLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const period: number = PineJS.Std.period(this._context);

        const symbol: string = this._input(0) || this._context.symbol.info.name;
        const baseSymbol: string = ParseSymbol(symbol);
        const liquidationsSymbol: string = `${baseSymbol}${"#SF_LIQUIDATIONS"}`;
        this._context.new_sym(liquidationsSymbol, period);
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [measureInput, maType, length, chanelType, metricsBoolInput]: [
          string,
          string,
          number,
          string,
          boolean,
        ] = Array.from({ length: 6 }, (_, i) => this._input(i + 1));

        // Market data parsing
        let buyLiquidationsArray: any = this._context.new_var(0.0);
        let sellLiquidationsArray: any = this._context.new_var(0.0);
        this._context.select_sym(1);
        const liquidationsTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        if (measureInput === "Coin") {
          buyLiquidationsArray = this._context.new_var(
            PineJS.Std.open(this._context),
          );
          sellLiquidationsArray = this._context.new_var(
            PineJS.Std.high(this._context),
          );
        } else {
          buyLiquidationsArray = this._context.new_var(
            PineJS.Std.low(this._context),
          );
          sellLiquidationsArray = this._context.new_var(
            PineJS.Std.close(this._context),
          );
        }
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const buyLiquidations: number =
          buyLiquidationsArray.adopt(liquidationsTime, mainSymbolTime, 1) || 0;
        const sellLiquidations: number =
          -sellLiquidationsArray.adopt(liquidationsTime, mainSymbolTime, 1) ||
          0;

        // Calculations
        const liquidationsDelta: number = buyLiquidations + sellLiquidations;
        const cldClose: number = PineJS.Std.cum(
          liquidationsDelta,
          this._context,
        );
        const cldCloseSeries: any = this._context.new_var(cldClose);
        const cldOpen: number = cldCloseSeries.get(1);
        const cldHigh: number = PineJS.Std.max(cldOpen, cldClose);
        const cldHighSeries = this._context.new_var(cldHigh);
        const cldLow: number = PineJS.Std.min(cldOpen, cldClose);
        const cldLowSeries = this._context.new_var(cldLow);
        const cldColor: number = cldClose > cldOpen ? 1 : 0;

        // Derivative metrics
        const [cldMa, cldUpperBand, cldLowerBand]: [number, number, number] =
          metricsBoolInput
            ? ChannelBounds(
                PineJS,
                cldLowSeries,
                cldHighSeries,
                cldCloseSeries,
                chanelType,
                maType,
                length,
                this._context,
              )
            : [NaN, NaN, NaN];

        //Returns
        return [
          cldOpen,
          cldHigh,
          cldLow,
          cldClose,
          cldColor,
          cldColor,
          cldColor,
          cldMa,
          cldUpperBand,
          cldLowerBand,
        ];
      };
    },
  };
};
