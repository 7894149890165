import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config";
import {
  ICandlePattern,
  ICandlePatternRow,
  ICandlePatterns,
  useCandlePatterns,
} from "@/hooks/useCandlePatterns";
import { transform } from "lodash";

const columnHelper = createColumnHelper<ICandlePatternRow>();

const getBgClass = (pattern: string) => {
  return pattern.includes("BULLISH") ? "bg-red-800" : "bg-green-800";
};

const CANDLE_PATTERNS_MAP: Record<string, string> = {
  DOJI_STAR: "Doji Star",
  DOJI_GRAVESTONE: "Gravestone Doji",
  DOJI_DRAGONFLY: "Dragonfly Doji",
  DOJI_RICKSHAW: "Rickshaw Doji",
  DOJI_FOUR_PRICE: "Four Price Doji",
  DOJI_STAR_BULLISH: "Doji Star",
  DOJI_STAR_BEARISH: "Doji Star",
  PIN_BAR_BULLISH: "Pin Bar",
  PIN_BAR_BEARISH: "Pin Bar",
  HAMMER_BULLISH: "Hammer",
  HANGING_MAN_BEARISH: "Hanging Man",
  INVERTED_HAMMER_BEARISH: "Inverted Hammer",
  SHOOTING_STAR_BEARISH: "Shooting Star",
  MARUBOZU_BULLISH: "Marubozu",
  MARUBOZU_BEARISH: "Marubozu",
  ENGULFING_BULLISH: "Engulfing",
  ENGULFING_BEARISH: "Engulfing",
  HARAMI_BULLISH: "Harami",
  HARAMI_BEARISH: "Harami",
  ENGULFING_FULL_SIZE_BULLISH: "Full Size Engulfing",
  ENGULFING_FULL_SIZE_BEARISH: "Full Size Engulfing",
  HARAMI_FULL_SIZE_BULLISH: "Full Size Harami",
  HARAMI_FULL_SIZE_BEARISH: "Full Size Harami",
  THREE_WHITE_SOLDIERS_BULLISH: "Three White Soldiers",
  THREE_BLACK_CROWS_BEARISH: "Three Black Crows",
  MORNING_STAR_BULLISH: "Morning Star",
  DARK_CLOUD_COVER_BEARISH: "Dark Cloud Cover",
  THREE_RISING_METHOD_BULLISH: "Three Rising Method",
  THREE_FALLING_METHOD_BEARISH: "Three Falling Method",
  MAT_HOLD_BULLISH: "Mat Hold",
  CLEARANCE_IN_THE_CLOUDS_BULLISH: "Clearance in the Clouds",
  ABANDONED_BABY_BULLISH: "Abandoned Baby",
  ABANDONED_BABY_BEARISH: "Abandoned Baby",
  TWEEZER_TOP_BULLISH: "Tweezer Top",
  TWEEZER_BOTTOM_BEARISH: "Tweezer Bottom",
  TASUKI_GAP_UPSIDE_BULLISH: "Tasuki Gap Upside",
  TASUKI_GAP_DOWNSIDE_BULLISH: "Tasuki Gap Downside",
};

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("pattern", {
    header: "Pattern",
    cell: (row) => {
      const value = row.getValue();
      const bgClass = getBgClass(value);
      return (
        <div className={bgClass + " font-semibold"}>
          {CANDLE_PATTERNS_MAP[value] || value}
        </div>
      );
    },
    size: 200,
    minSize: 200,
  }),
];

export function CandlePatternPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: candlePatterns, isLoading } = useCandlePatterns();

  const preparedData = useMemo(() => {
    if (!candlePatterns) {
      return [];
    }

    const interval = BINANCE_RESOLUTION_MAP[config.interval];
    let data = transformCandlePatterns(candlePatterns[interval]);

    if (blacklist.length > 0) {
      data = data.filter((item) => !blacklist.includes(item.symbol));
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ICandlePatternRow>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, candlePatterns, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}

function transformCandlePatterns(candlePattern: ICandlePattern) {
  // make an array [symbol, pattern] of candle patterns from Object ICandlePattern
  // {"12h":{"timestamp":1733753993461,"timeFrame":"12h","data":{"1INCHUSDT":["HARAMI_BEARISH","HARAMI_FULL_SIZE_BEARISH"],"ACTUSDT":["THREE_BLACK_CROWS_BEARISH"],"ADAUSDT":["THREE_BLACK_CROWS_BEARISH"],"AEVOUSDT":["ENGULFING_BEARISH"],"ALPACAUSDT":["ENGULFING_BEARISH"],"APEUSDT":["ENGULFING_BEARISH","ENGULFING_FULL_SIZE_BEARISH"],"ARKUSDT":["ENGULFING_BEARISH","ENGULFING_FULL_SIZE_BEARISH"],

  return Object.entries(candlePattern.data).flatMap(([symbol, patterns]) =>
    patterns.map((pattern) => {
      return {
        symbol,
        pattern,
      };
    }),
  );
}
