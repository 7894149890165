import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { useBinanceSymbols } from "@/hooks/useBinanceSymbols";
import { useDashboard } from "@/lib/store";
import { FaAnglesRight, FaBroom, FaFolderPlus } from "react-icons/fa6";

export function FillAll({ folderId }: { folderId: string }) {
  const { data: symbols } = useBinanceSymbols();

  const { addFavorite, folder, removeFavorite } = useDashboard((state) => ({
    addFavorite: state.addSymbolsToFavorites,
    removeFavorite: state.removeSymbolFromFavorites,
    folder: state.favorites.find((f) => f.id === folderId),
  }));

  const handleAddFavorites = () => {
    symbols?.forEach((symbol) => {
      if (!folder?.symbols?.includes(symbol.symbol)) {
        addFavorite(folderId, [symbol.symbol]);
      }
    });
    toast({
      variant: "default",
      title: "All favorites added",
    });
  };

  const handleRemoveAllFavorites = () => {
    folder?.symbols?.forEach((symbol) => {
      removeFavorite(folderId, symbol);
    });
    toast({
      variant: "destructive",
      title: "All favorites removed",
    });
  };
  return (
    <>
      <Button
        size="xs"
        variant="ghost"
        className="w-4 h-4"
        onClick={handleAddFavorites}
      >
        <FaAnglesRight />
      </Button>
      <Button
        size="xs"
        variant="ghost"
        className="w-4 h-4"
        onClick={handleRemoveAllFavorites}
      >
        <FaBroom />
      </Button>
    </>
  );
}
