//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const sharpeRatio = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Sharpe ratio",
    metainfo: {
      _metainfoVersion: 51,
      id: "sharpe-ratio@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Sharpe ratio",
      shortDescription: "[SF] Sharpe ratio",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "metricPlot", type: StudyPlotType.Line },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
        },
        inputs: {
          benchmarkTuckerInput: "BINANCE:BTCUSDT",
          riskFreeRateInput: 10.95,
          lengthInput: 10,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Sharpe",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "riskFreeRateInput",
          name: "Risk free rate",
          defval: 10.95,
          min: 0,
          type: StudyInputType.Float,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const [riskFreeRateInput, lengthInput]: [string, string, number] = [
          this._input(0),
          this._input(1),
        ];

        // Market data parsing
        const hlc3: number = PineJS.Std.hlc3(this._context);
        const hlc3Series: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );

        // Risk-free rate calculations
        const time: number = PineJS.Std.time(this._context);
        const timeSeries: any = this._context.new_var(time);
        const timePeriod: number =
          (time - timeSeries.get(lengthInput)) / 31536000000;
        const riskFreeRate: number = (timePeriod * riskFreeRateInput) / 100;

        // Risk premium calculation
        const portfolioReturn: number =
          (hlc3 - hlc3Series.get(lengthInput)) / hlc3Series.get(lengthInput);
        const stdev: number =
          PineJS.Std.stdev(hlc3Series, lengthInput, this._context) / hlc3;
        const sharpe: number = (portfolioReturn - riskFreeRate) / stdev;
        const sharpeColor: number = sharpe >= 0 ? 1 : 0;

        // Returns
        return [0, sharpe, sharpeColor];
      };
    },
  };
};
