//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const marketRatio = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market ratio",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-ratio@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Market ratio",
      shortDescription: "[SF] Market ratio",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "mainPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol1Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol2Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol3Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol4Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol5Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol6Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol7Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol8Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol9Plot",
          type: StudyPlotType.Line,
        },
        {
          id: "symbol10Plot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          mainPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: false,
            color: "#ffffff",
            transparency: 0,
          },
          symbol1Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#ffeb3b",
            transparency: 50,
          },
          symbol2Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#311b92",
            transparency: 50,
          },
          symbol3Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#808000",
            transparency: 50,
          },
          symbol4Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#9c27b0",
            transparency: 50,
          },
          symbol5Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#4caf50",
            transparency: 50,
          },
          symbol6Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#00bcd4",
            transparency: 50,
          },
          symbol7Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#ff9800",
            transparency: 50,
          },
          symbol8Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#2196f3",
            transparency: 50,
          },
          symbol9Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#ff5252",
            transparency: 50,
          },
          symbol10Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#880e4f",
            transparency: 50,
          },
        },
        inputs: {
          symbol1Input: "BTCUSDT",
          symbol2Input: "ETHUSDT",
          symbol3Input: "BNBUSDT",
          symbol4Input: "SOLUSDT",
          symbol5Input: "XRPUSDT",
          symbol6Input: "ADAUSDT",
          symbol7Input: "AVAXUSDT",
          symbol8Input: "LINKUSDT",
          symbol9Input: "DOGEUSDT",
          symbol10Input: "TRXUSDT",
        },
        palettes: {},
      },
      palettes: {},
      styles: {
        mainPlot: {
          isHidden: false,
          title: "Chart symbol",
          histogramBase: 0,
        },
        symbol1Plot: {
          isHidden: false,
          title: "Symbol #1",
          histogramBase: 0,
        },
        symbol2Plot: {
          isHidden: false,
          title: "Symbol #2",
          histogramBase: 0,
        },
        symbol3Plot: {
          isHidden: false,
          title: "Symbol #3",
          histogramBase: 0,
        },
        symbol4Plot: {
          isHidden: false,
          title: "Symbol #4",
          histogramBase: 0,
        },
        symbol5Plot: {
          isHidden: false,
          title: "Symbol #5",
          histogramBase: 0,
        },
        symbol6Plot: {
          isHidden: false,
          title: "Symbol #6",
          histogramBase: 0,
        },
        symbol7Plot: {
          isHidden: false,
          title: "Symbol #7",
          histogramBase: 0,
        },
        symbol8Plot: {
          isHidden: false,
          title: "Symbol #8",
          histogramBase: 0,
        },
        symbol9Plot: {
          isHidden: false,
          title: "Symbol #9",
          histogramBase: 0,
        },
        symbol10Plot: {
          isHidden: false,
          title: "Symbol #10",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbol1Input",
          name: "Symbol #1",
          defval: "BTCUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol2Input",
          name: "Symbol #2",
          defval: "ETHUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol3Input",
          name: "Symbol #3",
          defval: "BNBUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol4Input",
          name: "Symbol #4",
          defval: "SOLUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol5Input",
          name: "Symbol #5",
          defval: "XRPUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol6Input",
          name: "Symbol #6",
          defval: "ADAUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol7Input",
          name: "Symbol #7",
          defval: "AVAXUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol8Input",
          name: "Symbol #8",
          defval: "LINKUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol9Input",
          name: "Symbol #9",
          defval: "DOGEUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "symbol10Input",
          name: "Symbol #10",
          defval: "TRXUSDT",
          type: StudyInputType.Symbol,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      // InitSymbol - function for initializing symbols
      const InitSymbol = (input: string): void => {
        this._context.new_sym(input, PineJS.Std.period(this._context));
      };

      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        for (let i = 0; i < 10; i++) {
          const symbolInput: string = this._input(i);
          InitSymbol(symbolInput);
        }
      };

      // SymbolData interface for better typing
      interface SymbolData {
        time: any;
        closeArray: any;
        close?: number;
        closeSeries?: any;
        avg?: number;
        percent?: number;
      }

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbolsData: SymbolData[] = [];
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Getting data for all symbols
        for (let i = 1; i <= 10; i++) {
          this._context.select_sym(i);
          symbolsData[i - 1] = {
            time: this._context.new_var(this._context.symbol.time),
            closeArray: this._context.new_var(PineJS.Std.close(this._context)),
          };
        }

        this._context.select_sym(0);

        // Base calculations
        const close: number = PineJS.Std.close(this._context);
        const olhc4Series: any = this._context.new_var(
          PineJS.Std.ohlc4(this._context),
        );
        const length: number = 1440 / Number(PineJS.Std.period(this._context));

        // Processing symbols data
        symbolsData.forEach((data: SymbolData, index: number) => {
          if (!data) {
            return;
          }

          data.close = data.closeArray.adopt(data.time, mainSymbolTime, 0);
          data.closeSeries = this._context.new_var(data.close);
          data.avg =
            PineJS.Std.sum(data.closeSeries, length, this._context) / length;
          data.percent = ((data.close - data.avg) / data.avg) * 100;
        });

        // Calculation for the main symbol
        const avg: number =
          PineJS.Std.sum(olhc4Series, length, this._context) / length;
        const mainPercent: number = ((close - avg) / avg) * 100;

        // Return values
        return [mainPercent, ...symbolsData.map((data) => data?.percent || 0)];
      };
    },
  };
};
